import React, { useEffect, useRef, useState } from 'react';
import { CommunicationHistoryInfo, SupportRequestDetailsInfo } from '../type';
import MYOBSupportIcon from '../../assets/images/MYOBSupportIcon.svg';
import { Avatar, DownChevronIcon, UpChevronIcon } from '@myob/myob-widgets';
import moment from 'moment-timezone';

type ConversationProps = {
  businessName: SupportRequestDetailsInfo['businessName'];
  conversation: CommunicationHistoryInfo;
};

export const Conversation: React.FC<ConversationProps> = ({ businessName, conversation }) => {
  const [isLinkDisplay, setIsLinkDisplay] = useState(true);
  const ref = useRef(null);

  useEffect(() => {
    const handleLinkDisplay = () => {
      const contentHeight = ref.current.scrollHeight;
      contentHeight > 144 ? setIsLinkDisplay(true) : setIsLinkDisplay(false);
    };
    handleLinkDisplay();

    window.addEventListener('resize', handleLinkDisplay);
    return () => window.removeEventListener('resize', handleLinkDisplay);
  }, []);

  const [isFullContentDisplay, setIsFullContentDisplay] = useState(false);

  const timeTransformer = (time) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment.utc(time).tz(timezone).format('DD/MM/YYYY HH:mm:ss');
  };

  const showMoreAndLess = () => {
    isFullContentDisplay ? setIsFullContentDisplay(false) : setIsFullContentDisplay(true);
  };

  const toggleClassName = () => (isFullContentDisplay ? 'text-container-unfolded' : 'text-container');

  const toggleLinkContent = () => (isFullContentDisplay ? 'Show less' : 'Show more');

  return (
    <div className="conversation">
      {conversation.origin === 'myob' ? (
        <div className="avatar-name">
          <img src={MYOBSupportIcon} alt="MYOBSupport" />
          <span>MYOB Support</span>
        </div>
      ) : (
        <div className="avatar-name">
          <Avatar type="business" name={businessName} color="regal" />
          <span>{businessName}</span>
        </div>
      )}
      <h5>Date: {timeTransformer(conversation.createdAt)}</h5>
      <p className={toggleClassName()} ref={ref} id="content">
        {conversation.content}
      </p>
      {isLinkDisplay && (
        <a onClick={showMoreAndLess} onKeyDown={showMoreAndLess}>
          {toggleLinkContent()}
          {isFullContentDisplay ? <UpChevronIcon color="brand" /> : <DownChevronIcon color="brand" />}
        </a>
      )}
    </div>
  );
};
