import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { Route, Switch } from 'react-router-dom';
import { AccountSelectWrapper } from '@my-account/account';
import React from 'react';
import { SupportRequests } from '../modules/SupportRequests';
import { SupportDetails } from '../modules/SupportDetails';
import { ContactSupport } from '../modules/ContactSupport';
import { usePageView } from 'hooks/usePageView';
import { PremiumSupportBooking } from '../modules/PremiumSupportBooking';
import { PremiumSupportBookingDetails } from '../modules/PremiumSupportBookingDetails';

export enum ERoutePath {
  ContactSupport = '/account/support/contact-support',
  Cancellation = '/account/support/contact-support/cancel',
  ViewSupportRequests = '/account/support/support-requests',
  SupportDetails = '/account/support/support-requests/:id',
  PremiumSupportBooking = '/account/support/priority-support-booking',
  PremiumSupportBookingDetails = '/account/support/priority-support-booking/:id',
}

export const ERoutePathAndPageNameMap: Map<string, string> = new Map([
  [ERoutePath.ContactSupport, 'Contact support'],
  [ERoutePath.Cancellation, 'Cancellation'],
  [ERoutePath.ViewSupportRequests, 'View support requests'],
  [ERoutePath.SupportDetails, 'Support Details'],
  [ERoutePath.PremiumSupportBooking, 'Priority support booking'],
  [ERoutePath.PremiumSupportBookingDetails, 'Priority support booking detail'],
]);

const Routes: React.FC = () => {
  usePageView();
  return (
    <CacheProvider
      value={createCache({
        key: 'my-account-digital-case',
      })}
    >
      <Switch>
        <Route exact path={[ERoutePath.ContactSupport, ERoutePath.Cancellation]}>
          <AccountSelectWrapper>
            <ContactSupport />
          </AccountSelectWrapper>
        </Route>
        <Route exact path={ERoutePath.ViewSupportRequests}>
          <AccountSelectWrapper>
            <SupportRequests />
          </AccountSelectWrapper>
        </Route>
        <Route exact path={ERoutePath.SupportDetails}>
          <SupportDetails />
        </Route>
        <Route exact path={ERoutePath.PremiumSupportBooking}>
          <AccountSelectWrapper>
            <PremiumSupportBooking />
          </AccountSelectWrapper>
        </Route>
        <Route exact path={ERoutePath.PremiumSupportBookingDetails}>
          <AccountSelectWrapper>
            <PremiumSupportBookingDetails />
          </AccountSelectWrapper>
        </Route>
      </Switch>
    </CacheProvider>
  );
};

export default Routes;
