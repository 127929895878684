import { Account } from '@my-account/account';

export type UserProfileInfo = {
  firstName?: string;
  lastName?: string;
  email?: string;
};

export interface SupportTicketInfo {
  serialNumber?: string;
  subject?: string;
  category: string;
  subCategory: string;
  cancellationReason?: string;
  description: string;
}

export interface RequestBody extends SupportTicketInfo {
  clientId: string;
}

export interface SupportRequestInfo {
  id: string;
  caseNumber: string;
  status: string;
  subject: string;
  created: string;
  lastUpdated: string;
  requesterFirstName: string;
  requesterLastName: string;
  supportType?: SupportType;
  fullName?: string;
}

export interface CommunicationHistoryInfo {
  content: string;
  origin: string;
  createdAt: string;
}

export interface SupportRequestDetailsInfo extends SupportRequestInfo {
  description: string;
  businessName?: string;
  communicationHistory?: CommunicationHistoryInfo[];
}

export interface ReplyBody {
  caseId: string;
  reply: string;
}

export interface AsyncArgs {
  id: Account['clientId'];
  isClosed: boolean;
}

export interface FetchAvailableTimeArgs {
  region: string;
  clientId: string;
}

export interface SaveBookingArgs {
  bookingData: BookingRequestBody;
  region: string;
}

export enum Region {
  AU = 'AU',
  NZ = 'NZ',
}

export enum Tab {
  Open = 'open',
  Closed = 'closed',
}

export interface PremiumSupportBookingInfo {
  date: string;
  availableTime: string;
  name: string;
  emailAddress: string;
  contactPhoneNumber: string;
  serialNumber?: string;
  description: string;
  region?: string;
  timeZoneId?: string;
}

export interface DatesAndTimeSlots {
  date: string;
  availableSlots: string[];
}

export interface AvailableSlotsInfo {
  datesAndTimeSlots: DatesAndTimeSlots[];
}

export interface BookingRequestBody {
  scheduledTime: string;
  clientId: string;
  fullName: string;
  phoneNumber: string;
  email: string;
  serialNumber?: string;
  issueDescription: string;
  status?: string;
  timeZoneId?: string;
  region?: string;
}

export interface BookingDetailsInfo {
  booking: BookingRequestBody;
}
export interface BookingInfo {
  date: string;
  availableTime: string;
  name: string;
  emailAddress: string;
  contactPhoneNumber: string;
  serialNumber?: string;
  description: string;
  status?: string;
  timeZoneId?: string;
}

export enum SupportType {
  BookingRequest = 'Booking Request',
  SupportRequest = 'Support Request',
}

export enum BookingStatus {
  New = 'New',
  Closed = 'Closed',
}
