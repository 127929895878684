import React, { useState } from 'react';
import { BaseTemplate, Button, PageState } from '@myob/myob-widgets';
import failed from '../../assets/images/failed.svg';
import { DescriptionWrapper } from '../styles';

type FailedProps = {
  onClick: () => void;
};

export const Failed: React.FC<FailedProps> = ({ onClick }) => {
  const phoneNumber = {
    show: '1300 555 123',
    hide: '1300 55....',
  };
  const [isPhoneNumberShow, setIsPhoneNumberShow] = useState(false);

  const handleOnClick = () => {
    setIsPhoneNumberShow(true);
  };
  return (
    <>
      <BaseTemplate>
        <PageState
          title="We've run into an issue."
          actions={[
            <Button
              key={'try-again-button'}
              style={{ background: '#7519B5', 'border-color': '#7519B5' }}
              onClick={onClick}
            >
              Try Again
            </Button>,
          ]}
          description={
            <DescriptionWrapper>
              <div className="description_failed_title">Return to My Account and try again. </div>
              <div>If this happens again, please call our support team on</div>
              <Button type="link" onClick={handleOnClick} test-id="phone_number">
                {isPhoneNumberShow ? phoneNumber.show : phoneNumber.hide}
              </Button>
            </DescriptionWrapper>
          }
          image={<img src={failed} alt="failed" />}
        />
      </BaseTemplate>
    </>
  );
};
