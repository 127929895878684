import { SupportRequestDetailsInfo } from '../type';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchSupportRequestDetailsById } from 'helpers/api';
import { ResponseError } from 'helpers/request';

export type SupportDetailsState = {
  fetch: {
    isLoading: boolean;
    isError: boolean;
    data: SupportRequestDetailsInfo;
    error: ResponseError;
  };
};

export const initialState: SupportDetailsState = {
  fetch: {
    isLoading: false,
    isError: false,
    data: null,
    error: {
      status: null,
      message: null,
    },
  },
};

export const fetchSupportRequestDetailsAsync = createAsyncThunk<
  SupportRequestDetailsInfo,
  string,
  { rejectValue: ResponseError }
>('account/fetchSupportRequestDetailsById', async (caseId, thunkApi) => {
  try {
    const data = await fetchSupportRequestDetailsById(caseId);
    return data;
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

const slice = createSlice({
  name: 'digital-case-details',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSupportRequestDetailsAsync.pending, (state) => {
      state.fetch.isLoading = true;
      state.fetch.isError = false;
      state.fetch.data = null;
      state.fetch.error = { status: null, message: null };
    });
    builder.addCase(fetchSupportRequestDetailsAsync.fulfilled, (state, action) => {
      state.fetch.isLoading = false;
      state.fetch.isError = false;
      state.fetch.data = action.payload;
      state.fetch.error = { status: null, message: null };
    });
    builder.addCase(fetchSupportRequestDetailsAsync.rejected, (state, action) => {
      state.fetch.isLoading = false;
      state.fetch.isError = true;
      state.fetch.data = null;
      state.fetch.error = action.payload;
    });
  },
});
export const supportDetailsReducer = slice.reducer;
