import { Button, Card, HelpIcon, PageHead, PageState } from '@myob/myob-widgets';
import ForbiddenImg from 'assets/images/noAccess.svg';
import { openInNewTab } from 'helpers/tools';
import { helper } from '@my-account/tools';
import { auConfig, nzConfig } from 'config';
import { Region } from 'modules/type';
import { ForbiddenWrapper } from './style';

export type Props = {
  pageTitle: string;
  title: string;
  description: string;
  size?: string;
  showAction?: boolean;
};

export const Forbidden: React.FC<Props> = ({ pageTitle, title, description, showAction = true, size = '' }) => {
  const helpLink = helper.getRegionFromTimezone() === Region.NZ ? nzConfig.HELP_LINK : auConfig.HELP_LINK;
  const handleClick = () => {
    openInNewTab(helpLink);
  };
  const actions = (): JSX.Element[] => {
    if (showAction) {
      return [
        <Button key={1} type="link" icon={<HelpIcon />} onClick={handleClick}>
          Contact our support team
        </Button>,
      ];
    } else {
      return [];
    }
  };
  return (
    <ForbiddenWrapper data-testid="forbiddenComponent" className="forbidden-component" data-size={size}>
      <PageHead title={pageTitle} />
      <Card>
        <PageState
          title={title}
          description={[description]}
          actions={actions()}
          image={<img src={ForbiddenImg} alt="Forbidden" />}
        />
      </Card>
    </ForbiddenWrapper>
  );
};
