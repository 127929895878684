import { useContext, useEffect, useState } from 'react';
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account';
import { Region } from '@my-account/tools';

export default (defaultRegion?: Region) => {
  const [region, setRegion] = useState<Region>(defaultRegion);

  const { selected: selectedAccount } = useContext<AccountSelectContextValue>(AccountSelectContext);

  useEffect(() => {
    if (selectedAccount) {
      setRegion(selectedAccount.region);
    }
  }, [selectedAccount]);

  return region;
};
