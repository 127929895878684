import { AnyAction } from 'redux';
import { configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { supportTicketReducer } from 'modules/reducers/sendSupportTicket';
import { requestListReducer } from '../modules/reducers/getRequestList';
import { supportDetailsReducer } from '../modules/reducers/getRequestDetails';
import { replyRequestReducer } from '../modules/reducers/replyRequest';
import { notificationReducer } from '../modules/reducers/notification';
import { availableSlotsReducer } from '../modules/reducers/getAvailableSlots';
import { bookingReducer } from '../modules/reducers/sendBooking';
import { bookingDetailsReducer } from '../modules/reducers/getBookingDetails';
import { cancelBookingReducer } from '../modules/reducers/cancelBooking';
import { noActiveSubscriptionReducer } from '../modules/reducers/getIsNoActiveSubscription';
export type RootState = ReturnType<typeof store.getState>;

const store = configureStore({
  reducer: {
    supportTicket: supportTicketReducer,
    requestList: requestListReducer,
    supportDetails: supportDetailsReducer,
    replyRequest: replyRequestReducer,
    notification: notificationReducer,
    availableSlots: availableSlotsReducer,
    booking: bookingReducer,
    bookingDetails: bookingDetailsReducer,
    cancelBooking: cancelBookingReducer,
    noActiveSubscription: noActiveSubscriptionReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type AppThunkDispatch = ThunkDispatch<RootState, Record<string, unknown>, AnyAction>;

export default store;
