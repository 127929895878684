import { telemetry } from '@my-account/tools';
import { computePageNameFromPath, getMyobVisitorId } from 'helpers/tools';

export const trackSelectedOption = (
  eventName: string,
  category: string,
  subCategory?: string,
  cancellationReason?: string,
): void => {
  telemetry.segmentTrack(eventName, {
    category,
    subCategory,
    cancellationReason,
  });
};

export const trackPage = (path: string): void => {
  const pageName = computePageNameFromPath(path);

  telemetry.page(`My Account ${pageName} Page`, {
    title: pageName,
    myobVisitorId: getMyobVisitorId(),
  });
};
