import { Card, PageState } from '@myob/myob-widgets';
import noResultsFound from 'assets/images/NoResultsFound.svg';

export type Props = {
  pageTitle: string;
};

export const NoData: React.FC<Props> = ({ pageTitle }) => {
  return (
    <div className="no-results-found">
      <Card>
        <PageState title={pageTitle} image={<img src={noResultsFound} alt="No results found" />} />
      </Card>
    </div>
  );
};
