import { Table, Label } from '@myob/myob-widgets';
import React, { useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { SupportRequestDetailsInfo } from '../type';
import { STATUS, tableColumns } from './constant';
import LoadingWrapper from '../../components/LoadingWrapper';
import { ResponseError } from 'helpers/request';

type SupportRequestTableProps = {
  error: ResponseError;
  supportRequestDetails: SupportRequestDetailsInfo;
  isLoading: boolean;
};

export const SupportRequestDetailsTable: React.FC<SupportRequestTableProps> = ({
  error,
  supportRequestDetails,
  isLoading,
}) => {
  const [columns] = useState(tableColumns.slice(0, -1));
  const timeTransformer = (time) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment.utc(time).tz(timezone).format('DD/MM/YYYY');
  };
  const statusLabel = (label) => {
    return (
      <Label type="boxed" tone={STATUS[label]}>
        {label}
      </Label>
    );
  };

  const renderRow = (request: SupportRequestDetailsInfo) => (
    <>
      <Table.Row key={request.id} rowData={request}>
        <Table.RowItem columnName="Subject" width="flex-2">
          {request.subject}
        </Table.RowItem>
        <Table.RowItem columnName="Number">{request.caseNumber}</Table.RowItem>
        <Table.RowItem columnName="Date created">{timeTransformer(request.created)}</Table.RowItem>
        <Table.RowItem columnName="Last updated">{timeTransformer(request.lastUpdated)}</Table.RowItem>
        <Table.RowItem columnName="Status">{statusLabel(request.status)}</Table.RowItem>
        <Table.RowItem columnName="Requester" width="flex-2">
          {request.requesterFirstName + '\xa0' + request.requesterLastName}
        </Table.RowItem>
      </Table.Row>
      <Table.Header className="support-request-description-header">
        <Table.HeaderItem>Description</Table.HeaderItem>
      </Table.Header>
      <Table.Row className="support-request-description">
        <Table.RowItem columnName="Description" width="flex-6">
          <div className="description-container">{request.description}</div>
        </Table.RowItem>
      </Table.Row>
    </>
  );

  const content = useMemo(() => {
    return supportRequestDetails && <Table.Body>{renderRow(supportRequestDetails)}</Table.Body>;
  }, [supportRequestDetails, error]);

  return (
    <>
      <LoadingWrapper isLoading={isLoading} spinnerSize="medium">
        <Table variant="card">
          <Table.Header>
            {columns.map((column: Record<string, unknown>) => (
              <Table.HeaderItem key={column.key} width={column.width}>
                {column.description}
              </Table.HeaderItem>
            ))}
          </Table.Header>
          {content}
        </Table>
      </LoadingWrapper>
    </>
  );
};
