import React from 'react';
import { Alert } from '@myob/myob-widgets';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores';
import { ENotificationType, NotificationState } from '../../modules/reducers/notification/type';

type Props = {
  onDismiss?: () => void;
};

export const Notification: React.FC<Props> = ({ onDismiss }) => {
  const dispatch = useDispatch();
  const { type, primaryMessage, dismissAfter } = useSelector<RootState, NotificationState>(
    (state) => state.notification,
  );
  if (type === ENotificationType.Clear) {
    return null;
  }
  const handleDismiss = () => {
    onDismiss && onDismiss();
    dispatch({ type: ENotificationType.Clear });
  };

  const alertType = type === ENotificationType.Success ? 'success' : 'danger';
  return (
    <Alert type={alertType} onDismiss={onDismiss ? handleDismiss : undefined} dismissAfter={dismissAfter}>
      <div dangerouslySetInnerHTML={{ __html: primaryMessage }} />
    </Alert>
  );
};
