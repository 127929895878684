import { AppConfig, RegionConfig } from './type';

const config: AppConfig = {
  API_BASE_URL: 'https://digital-case.sit.myaccount.myob.com',
};

export const auConfig: RegionConfig = {
  HELP_LINK: 'https://www.myob.com/au/support/contact-us',
  LOGIN_2FA_HELP_LINK: 'https://www.myob.com/au/support/login-and-2fa',
  MANAGE_USER_MYOB_BIZ_HELP_LINK: 'https://help.myob.com/wiki/display/myob/Users',
  MANAGE_USER_AR_HELP_LINK: 'https://help.myob.com/wiki/display/ar/Manage+users',
  PROCESS_PAYROLL_MYOB_BIZ_HELP_LINK: 'https://help.myob.com/wiki/display/myob/Do+a+pay+run',
  PROCESS_PAYROLL_AR_HELP_LINK: 'https://help.myob.com/wiki/x/eYBW',
  STP_MYOB_BIZ_HELP_LINK: 'https://help.myob.com/wiki/x/G6dqAg',
  STP_AR_HELP_LINK: 'https://help.myob.com/wiki/x/Vw6EAQ',
  SUPERANNUATION_MYOB_BIZ_HELP_LINK: 'https://help.myob.com/wiki/x/Z6xqAg',
  SUPERANNUATION_AR_HELP_LINK: 'https://help.myob.com/wiki/x/G4Fs',
  TIMESHEETS_MYOB_BIZ_HELP_LINK: 'https://help.myob.com/wiki/x/MwSvAg',
  TIMESHEETS_AR_HELP_LINK: 'https://help.myob.com/wiki/x/gIBW',
  MANAGE_SUBSCRIPTION_HELP_LINK:
    'https://www.myob.com/support/billing-and-account/managing-your-myob-subscription-in-my-account',
  SECURITY_LINK: 'https://www.myob.com/au/support/security',
  CONTACT_PREMIUM_SUPPORT_LINK: 'https://help.myob.com/prioritysupport',
  TWOFA_NOT_GET_OR_NOT_WORK_HELP_LINK:
    'https://www.myob.com/support/login-and-2fa/getting-help-with-two-factor-authentication',
  ACCOUNT_SECURITY_LINK: 'https://myaccount.myob.com/account/security',
  TWOFA_RESET_HELP_LINK:
    'https://www.myob.com/support/login-and-2fa/setting-up-two-factor-authentication-on-a-new-phone',
  TWOFA_SETTING_OR_CHANGING_HELP_LINK:
    'https://www.myob.com/support/login-and-2fa/setting-up-two-factor-authentication',
  API_DEVELOPER_BUSINESS_API_DEVELOPER_APP_HELP_LINK:
    'https://apisupport.myob.com/hc/en-us/requests/new?ticket_form_id=6175906535311',
  API_DEVELOPER_BUSINESS_API_SUPPORT_HELP_LINK:
    'https://apisupport.myob.com/hc/en-us/articles/4407275101967-Introducing-the-MYOB-Business-API',
  API_DEVELOPER_EXO_API_DEVELOPER_APP_HELP_LINK:
    'https://apisupport.myob.com/hc/en-us/requests/new?ticket_form_id=6175906535311',
  API_DEVELOPER_EXO_API_SUPPORT_HELP_LINK: 'https://apisupport.myob.com/hc/en-us',
  ACCESS_OR_SETUP_ARL_BACKUP_REQUEST_HELP_LINK:
    'https://www.myob.com/support/myob-business/product-account/myob-data-file-repair-services',
  ACCESS_OR_SETUP_ACTIVATE_FILE_HELP_LINK:
    'https://www.myob.com/support/myob-business/product-account/activate-a-company-file/activation-and-confirmation-errors',
  ACCESS_OR_SETUP_CAN_NOT_ACCESS_HELP_LINK:
    'https://www.myob.com/support/myob-business/business-settings/changing-and-resetting-user-passwords/if-you-cant-sign-in',
  ACCESS_OR_SETUP_DATA_HELP_LINK:
    'https://www.myob.com/support/myob-business/import-export/importing-and-exporting-data',
  ACCESS_OR_SETUP_INVITE_ADVISOR_BROWSER_HELP_LINK:
    'https://www.myob.com/support/myob-business/business-settings/manage-users/invite-your-accountant-or-bookkeeper',
  ACCESS_OR_SETUP_INVITE_ADVISOR_DESKTOP_HELP_LINK:
    'https://www.myob.com/support/myob-business/get-started/setting-up/set-up-user-access/add-an-accountant-advisor',
  ACCESS_OR_SETUP_INVITE_USER_BROWSER_HELP_LINK:
    'https://www.myob.com/support/myob-business/business-settings/manage-users/inviting-a-user-to-your-myob-business',
  ACCESS_OR_SETUP_INVITE_USER_DESKTOP_HELP_LINK:
    'https://www.myob.com/support/myob-business/get-started/setting-up/set-up-user-access/invite-a-user-to-an-online-file',
  MANAGE_USER_HELP_LINK: 'https://www.myob.com/support/myob-business/business-settings/manage-users',
  ACCESS_OR_SETUP_INVOICE_TEMPLATES_HELP_LINK:
    'https://www.myob.com/support/myob-business/sales/personalising-invoices',
  ACCESS_OR_SETUP_LINKED_ACCOUNTS_HELP_LINK:
    'https://www.myob.com/support/myob-business/accounting/financial-control/accounts-list/managing-linked-accounts?productview=Browser',
  ACCESS_OR_SETUP_LOCAL_INSTALL_HELP_LINK:
    'https://www.myob.com/support/myob-business/product-account/install-accountright',
  ACCESS_OR_SETUP_LOCAL_INSTALL_NEW_COMPUTER_HELP_LINK:
    'https://www.myob.com/support/myob-business/product-account/install-accountright/setting-up-accountright-on-a-new-computer',
  ACCOUNT_AND_SUBSCRIPTION_ADD_OR_REMOVE_USER_BROWSER_HELP_LINK:
    'https://www.myob.com/support/myob-business/business-settings/manage-users/inviting-a-user-to-your-myob-business',
  ACCOUNT_AND_SUBSCRIPTION_ADD_OR_REMOVE_USER_DESKTOP_HELP_LINK:
    'https://www.myob.com/support/myob-business/get-started/setting-up/set-up-user-access/invite-a-user-to-an-online-file',
  MANAGE_MY_PRODUCTS_HELP_LINK: 'https://myaccount.myob.com/account/products',
  ACCOUNT_AND_SUBSCRIPTION_CANCEL_HELP_LINK:
    'https://www.myob.com/support/myob-business/product-account/myob-subscription-invoices-and-payment-details/cancelling-your-myob-subscription',
  ACCOUNT_DETAILS_HELP_LINK: 'https://myaccount.myob.com/account/manage-account/account-details',
  CONTACT_DETAILS_HELP_LINK: 'https://myaccount.myob.com/account/contact-details',
  ACCOUNT_AND_SUBSCRIPTION_CHANGE_ACCOUNT_OR_CONTACT_DETAIL_HELP_LINK:
    'https://www.myob.com/support/billing-and-account/managing-your-myob-subscription-in-my-account',
  ACCOUNT_AND_SUBSCRIPTION_CHANGE_HELP_LINK:
    'https://www.myob.com/support/billing-and-account/managing-your-myob-subscription-in-my-account',
  ACCOUNT_AND_SUBSCRIPTION_CLOSE_OR_SELLING_AU_HELP_LINK:
    'https://info.myob.com/hubfs/Support/change-of-details-AU-form.pdf',
  ACCOUNT_AND_SUBSCRIPTION_CLOSE_OR_SELLING_NZ_HELP_LINK:
    'https://info.myob.com/hubfs/Support/NZ%20Change%20of%20Details.pdf',
  ACCOUNT_AND_SUBSCRIPTION_CLOSE_OR_SELLING_HELP_LINK:
    'https://www.myob.com/support/myob-business/product-account/managing-your-myob-account',
  ACCOUNT_AND_SUBSCRIPTION_QUESTION_HELP_LINK:
    'https://www.myob.com/support/billing-and-account/understanding-and-paying-your-myob-bill',
  BANK_FEEDS_APPLICATION_ENQUIRY_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/bank-feeds/managing-your-bank-feeds',
  BANK_FEEDS_APPLICATION_ENQUIRY_STATUS_HELP_LINK:
    ' https://www.myob.com/support/myob-business/banking/bank-feeds/setting-up-bank-feeds/tracking-the-status-of-your-bank-feed-application',
  BANK_FEEDS_BLOCK_MISSING_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/bank-feeds/managing-your-bank-feeds/missing-bank-feed-transactions',
  STATUS_HELP_LINK: 'https://status.myob.com/',
  BANK_FEEDS_DUPLICATE_FEED_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/bank-statements/deleting-imported-bank-statements',
  BANK_FEEDS_MOVING_BANK_FEEDS_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/bank-feeds/managing-your-bank-feeds',
  BANKING_BANK_RULES_HELP_LINK: 'https://www.myob.com/support/myob-business/banking/bank-feed-rules',
  BANKING_BUSINESS_SETTING_HELP_LINK: 'https://www.myob.com/support/myob-business/business-settings/business-settings',
  BANKING_CATEGORISING_TRANSACTION_UNDO_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/reconciling-your-bank-accounts/undoing-a-bank-reconciliation',
  BANKING_CATEGORISING_TRANSACTION_MORE_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/working-with-bank-transactions',
  BANKING_GENERAL_JOURNALS_HELP_LINK: 'https://www.myob.com/support/myob-business/accounting/general-journals',
  BANKING_ELECTRONIC_PAYMENTS_SETUP_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/electronic-payments/making-electronic-payments',
  BANKING_ELECTRONIC_PAYMENTS_LOOKING_HELP_LINK:
    'https://www.myob.com/support/myob-business/sales/receiving-payments/online-invoice-payments',
  BANKING_GETTING_ERROR_HELP_LINK:
    'https://www.myob.com/support/myob-business/get-help-support/myob-business-errors-and-known-issues',
  BANKING_MISSING_TRANSACTION_TRY_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/bank-feeds/managing-your-bank-feeds/missing-bank-feed-transactions',
  BANKING_MISSING_TRANSACTION_STATUS_HELP_LINK: 'https://status.myob.com/',
  BANKING_RECONCILE_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/reconciling-your-bank-accounts/fixing-out-of-balance-bank-reconciliations',
  BANKING_RECORD_SPEND_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/spending-and-receiving-money/spend-money',
  BANKING_RECORD_RECEIVE_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/spending-and-receiving-money/receive-money',
  BANKING_RECURRING_HELP_LINK:
    'https://www.myob.com/support/myob-business/accounting/transactions/recurring-transactions',
  BILLING_FINANCIAL_HARDSHIP_HELP_LINK: 'https://www.myob.com/legal/financial-hardship-policy-terms',
  BILLING_UNDERSTAND_HELP_LINK:
    'https://www.myob.com/support/billing-and-account/understanding-and-paying-your-myob-bill',
  BILLING_WRONG_BILL_CURRENT_HELP_LINK: 'https://myaccount.myob.com/account/products',
  BILLING_WRONG_BILL_EXPLANATION_HELP_LINK:
    'https://www.myob.com/support/billing-and-account/understanding-and-paying-your-myob-bill',
  BILLING_FAILED_PAYMENT_BILL_PAGE_HELP_LINK: 'https://myaccount.myob.com/account/billing/invoices',
  BILLING_FAILED_PAYMENT_DETAIL_HELP_LINK: 'https://myaccount.myob.com/account/billing/payment-details',
  BILLING_SUSPENDED_CANCELED_HELP_LINK: 'https://myaccount.myob.com/account/products',
  FINANCIAL_SERVICE_LOAN_INVOICE_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/sales/getting-business-loans-and-finance',
  FINANCIAL_SERVICE_OIP_REPORT_HELP_LINK:
    'https://www.myob.com/support/myob-business/reporting/online-invoice-payment-reports',
  FINANCIAL_SERVICE_OIP_MORE_HELP_LINK:
    'https://www.myob.com/support/myob-business/sales/receiving-payments/online-invoice-payments',
  SALE_PURCHASES_AUTOMOTIVE_INVOICING_HELP_LINK: 'https://www.myob.com/support/myob-business/sales/einvoicing',
  SALE_PURCHASES_GET_INVOICING_HELP_LINK: 'https://www.myob.com/support/myob-business/sales',
  SALE_PURCHASES_UPLOAD_WAYS_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/purchases/working-with-in-tray-documents/emailing-documents-to-your-in-tray',
  SALE_PURCHASES_UPLOAD_MORE_HELP_LINK:
    'https://www.myob.com/support/myob-business/purchases/working-with-in-tray-documents/working-with-in-tray-documents',
  SALE_PURCHASES_GETTING_ERROR_HELP_LINK:
    'https://www.myob.com/support/myob-business/get-help-support/myob-business-errors-and-known-issues',
  SALE_PURCHASES_PAYMENT_SALE_REMINDER_SETTING_HELP_LINK:
    'https://www.myob.com/support/myob-business/sales/sales-settings/set-up-your-payment-details',
  SALE_PURCHASES_PAYMENT_SALE_REMINDER_INVOICE_HELP_LINK:
    'https://www.myob.com/support/myob-business/sales/online-invoicing/setting-up-invoice-reminders',
  SALE_PURCHASES_SUPPLY_ENTERING_HELP_LINK: 'https://www.myob.com/support/myob-business/purchases/entering-purchases',
  SALE_PURCHASES_SUPPLY_EDIT_HELP_LINK:
    'https://www.myob.com/support/myob-business/purchases/changing-a-bill-quote-or-order',
  SALE_PURCHASES_SUPPLY_RETURN_INVOICE_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/purchases/supplier-returns-debits-and-refunds',
  SALE_PURCHASES_SUPPLY_MORE_HELP_LINK: 'https://www.myob.com/support/myob-business/purchases',
  SALE_PURCHASES_CUSTOMER_OVERPAID_HELP_LINK:
    'https://www.myob.com/support/myob-business/sales/receiving-payments/customer-overpayments',
  SALE_PURCHASES_CUSTOMER_RETURN_HELP_LINK:
    'https://www.myob.com/support/myob-business/sales/customer-returns-credits-and-refunds',
  SALE_PURCHASES_CUSTOMER_REFUND_HELP_LINK:
    'https://www.myob.com/support/myob-business/sales/customer-returns-credits-and-refunds/settling-customer-credits',
  SALE_PURCHASES_CUSTOMER_CREDIT_HELP_LINK:
    'https://www.myob.com/support/myob-business/sales/customer-returns-credits-and-refunds/creating-customer-returns',
  SALE_PURCHASES_CUSTOMER_MORE_HELP_LINK: 'https://www.myob.com/au/support/myob-business/sales',
  REPORTING_BAS_GST_SET_UP_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/end-of-period/month-end-procedures/prepare-your-activity-statement-online/get-ready-to-lodge-activity-statements',
  REPORTING_BAS_GST_RETURN_HELP_LINK:
    'https://www.myob.com/support/myob-business/end-of-period/end-of-period-tasks/completing-your-activity-statement-gst-return',
  REPORTING_BANKING_RECONCILIATION_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/reconciling-your-bank-accounts/fixing-out-of-balance-bank-reconciliations',
  REPORTING_BANKING_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/reporting/banking-reports',
  REPORTING_BILL_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/reporting/purchases-reports',
  REPORTING_BUSINESS_REPORT_PROFIT_LOSS_HELP_LINK:
    'https://www.myob.com/support/myob-business/reporting/business-reports/analyse-your-profit-or-loss',
  REPORTING_BUSINESS_REPORT_BUDGET_MANAGEMENT_HELP_LINK:
    'https://www.myob.com/support/myob-business/accounting/financial-control/budgets',
  REPORTING_BUSINESS_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/reporting/business-reports',
  REPORTING_CUSTOMER_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/reporting/sales-reports',
  REPORTING_END_FINANCIAL_CHANGE_YEAR_HELP_LINK:
    'https://www.myob.com/support/myob-business/business-settings/changing-the-last-month-of-your-financial-year',
  REPORTING_END_FINANCIAL_TASK_YEAR_HELP_LINK:
    'https://www.myob.com/support/myob-business/end-of-period/end-of-financial-year-tasks',
  REPORTING_EXCEPTIONS_DASHBOARD_HELP_LINK:
    'https://www.myob.com/support/myob-business/accounting/financial-control/auditing-your-records',
  REPORTING_FOREIGN_CURRENCY_HELP_LINK:
    'https://www.myob.com/support/myob-business/accounting/working-with-multiple-currencies',
  REPORTING_INVENTORY_RESTOCK_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/inventory/reordering-items',
  REPORTING_INVENTORY_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/reporting/inventory-reports',
  REPORTING_ERROR_MESSAGE_HELP_LINK: 'https://www.myob.com/au/support/myob-business/reporting',
  REPORTING_JOB_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/reporting/jobs-reports',
  REPORTING_OIP_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/reporting/online-invoice-payment-reports',
  REPORTING_PAYROLL_STP_REPORT_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/single-touch-payroll-reporting/view-single-touch-payroll-reports',
  REPORTING_PAYROLL_FIX_REPORT_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/single-touch-payroll-reporting/fix-rejected-reports-in-single-touch-payroll',
  REPORTING_PAYROLL_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/reporting/payroll-reports',
  REPORTING_SALE_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/reporting/sales-reports',
  PAYROLL_ANNUAL_LEAVE_CALCULATIONS_HELP_LINK:
    'https://www.myob.com/support/myob-business/payroll/leave-and-entitlements/managing-your-employees-leave',
  PAYROLL_CHANGE_MY_PAY_SUPER_AUTHORISER_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/superannuation/set-up-pay-super/add-a-pay-super-payment-authoriser',
  PAYROLL_CHANGE_MY_PAY_SUPER_DETAIL_HELP_LINK:
    'https://help.myob.com.au/files/myobbusiness/forms/PaySuper+Change+of+Details+form.pdf',
  PAYROLL_CHANGE_OR_INCREASE_MY_SUPER_LIMIT_OR_AUTHORISER_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/superannuation/set-up-pay-super/changing-your-pay-super-payment-details',
  PAYROLL_CREATING_EMPLOYEES_CHECK_DETAIL_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/add-an-employee/getting-employees-to-check-their-details',
  PAYROLL_CREATING_EMPLOYEES_HELP_LINK: 'https://www.myob.com/support/myob-business/payroll/add-an-employee',
  PAYROLL_ERROR_MESSAGE_STP_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/single-touch-payroll-reporting/fix-rejected-reports-in-single-touch-payroll',
  PAYROLL_ERROR_MESSAGE_HELP_LINK:
    'https://www.myob.com/nz/support/myob-business/payroll/setting-up-payday-filing/errors-in-payday-filing',
  PAYROLL_PAYITEMS_HELP_LINK: 'https://www.myob.com/au/support/myob-business/payroll/setting-up-pay-items-categories',
  PAYROLL_REPORTING_AND_STP_STP_REPORT_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/single-touch-payroll-reporting',
  PAYROLL_REPORTING_AND_STP_PAYDAY_REPORT_HELP_LINK:
    'https://www.myob.com/nz/support/myob-business/payroll/setting-up-payday-filing',
  PAYROLL_REPORTING_AND_STP_STP_FIX_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/single-touch-payroll-reporting/fix-rejected-reports-in-single-touch-payroll',
  PAYROLL_REPORTING_AND_STP_PAYDAY_FIX_HELP_LINK:
    'https://www.myob.com/nz/support/myob-business/payroll/setting-up-payday-filing/errors-in-payday-filing',
  PAYROLL_PAYSLIPS_REPRINT_RESEND_HELP_LINK:
    'https://www.myob.com/support/myob-business/payroll/processing-your-payroll/reprinting-or-resending-pay-slips',
  PAYROLL_PAYSLIPS_ADD_LOGO_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/adding-your-business-logo-to-pay-slips',
  PAYROLL_PAYSLIPS_CUSTOMISE_HELP_LINK: 'https://www.myob.com/au/support/myob-business/payroll/personalising-pay-slips',
  PAYROLL_PROCESSING_HELP_LINK: 'https://www.myob.com/support/myob-business/payroll/processing-your-payroll',
  PAYROLL_PROCESSING_FIX_AU_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/processing-your-payroll/fixing-a-pay',
  PAYROLL_PROCESSING_FIX_NZ_HELP_LINK:
    'https://www.myob.com/nz/support/myob-business/payroll/processing-your-payroll/fixing-a-pay/deleting-a-pay',
  PAYROLL_SETTING_UP_FLARE_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/add-an-employee/employee-self-onboarding',
  PAYROLL_SETTING_UP_FLARE_BENEFIT_HELP_LINK: 'https://www.myob.com/au/support/myob-business/payroll/employee-benefits',
  PAYROLL_SUPERANNUATION_HELP_LINK: 'https://www.myob.com/au/support/myob-business/payroll/superannuation',
  PAYROLL_KIWISAVER_HELP_LINK: 'https://www.myob.com/nz/support/myob-business/payroll/kiwisaver',
  PAYROLL_TERMINATION_PAYMENTS_HELP_LINK: 'https://www.myob.com/support/myob-business/payroll/processing-a-final-pay',
  PAYROLL_TIMESHEETS_HELP_LINK: 'https://www.myob.com/support/myob-business/payroll/timesheets',
  PAYROLL_TIMESHEETS_SUBMIT_HELP_LINK:
    'https://www.myob.com/support/myob-business/payroll/getting-started-with-myob-team',
  INVENTORY_INVENTORY_ADJUSTMENT_HELP_LINK:
    'https://www.myob.com/support/myob-business/inventory/making-inventory-adjustments',
  INVENTORY_ITEM_SETUP_HELP_LINK: 'https://www.myob.com/support/myob-business/inventory/creating-items',
  INVENTORY_ITEM_SETUP_UPDATE_HELP_LINK:
    'https://www.myob.com/support/myob-business/inventory/making-inventory-adjustments',
  INVENTORY_ERROR_MESSAGE_HELP_LINK:
    'https://www.myob.com/support/myob-business/import-export/importing-data/importing-items',
  INVENTORY_REORDER_LOW_STOCK_ITEMS_HELP_LINK: 'https://www.myob.com/support/myob-business/inventory/reordering-items',
  INVENTORY_REORDER_LOW_STOCK_ITEMS_TO_DO_LIST_HELP_LINK:
    'https://www.myob.com/support/myob-business/accounting/financial-control/see-whats-on-your-to-do-list',
  SERIAL_NUMBER_HELP_LINK: 'https://www.myob.com/support/myob-business/product-account/your-myob-serial-number',
};

export const nzConfig: RegionConfig = {
  HELP_LINK: 'https://www.myob.com/nz/support/contact-us',
  LOGIN_2FA_HELP_LINK: 'https://www.myob.com/nz/support/login-and-2fa',
  MANAGE_USER_MYOB_BIZ_HELP_LINK: 'https://help.myob.com/wiki/display/myob/Users',
  MANAGE_USER_AR_HELP_LINK: 'https://help.myob.com/wiki/display/ar/Manage+users',
  PROCESS_PAYROLL_MYOB_BIZ_HELP_LINK: 'https://help.myob.com/wiki/display/myob/Do+a+pay+run',
  PROCESS_PAYROLL_AR_HELP_LINK: 'https://help.myob.com/wiki/x/eYBW',
  STP_MYOB_BIZ_HELP_LINK: 'https://help.myob.com/wiki/x/G6dqAg',
  STP_AR_HELP_LINK: 'https://help.myob.com/wiki/x/Vw6EAQ',
  SUPERANNUATION_MYOB_BIZ_HELP_LINK: 'https://help.myob.com/wiki/x/Z6xqAg',
  SUPERANNUATION_AR_HELP_LINK: 'https://help.myob.com/wiki/x/G4Fs',
  TIMESHEETS_MYOB_BIZ_HELP_LINK: 'https://help.myob.com/wiki/x/MwSvAg',
  TIMESHEETS_AR_HELP_LINK: 'https://help.myob.com/wiki/x/gIBW',
  MANAGE_SUBSCRIPTION_HELP_LINK:
    'https://www.myob.com/support/billing-and-account/managing-your-myob-subscription-in-my-account',
  SECURITY_LINK: 'https://www.myob.com/nz/support/security',
  CONTACT_PREMIUM_SUPPORT_LINK: 'https://help.myob.com/prioritysupport',
  TWOFA_NOT_GET_OR_NOT_WORK_HELP_LINK:
    'https://www.myob.com/support/login-and-2fa/getting-help-with-two-factor-authentication',
  ACCOUNT_SECURITY_LINK: 'https://myaccount.myob.com/account/security',
  TWOFA_RESET_HELP_LINK:
    'https://www.myob.com/support/login-and-2fa/setting-up-two-factor-authentication-on-a-new-phone',
  TWOFA_SETTING_OR_CHANGING_HELP_LINK:
    'https://www.myob.com/support/login-and-2fa/setting-up-two-factor-authentication',
  API_DEVELOPER_BUSINESS_API_DEVELOPER_APP_HELP_LINK:
    'https://apisupport.myob.com/hc/en-us/requests/new?ticket_form_id=6175906535311',
  API_DEVELOPER_BUSINESS_API_SUPPORT_HELP_LINK:
    'https://apisupport.myob.com/hc/en-us/articles/4407275101967-Introducing-the-MYOB-Business-API',
  API_DEVELOPER_EXO_API_DEVELOPER_APP_HELP_LINK:
    'https://apisupport.myob.com/hc/en-us/requests/new?ticket_form_id=6175906535311',
  API_DEVELOPER_EXO_API_SUPPORT_HELP_LINK: 'https://apisupport.myob.com/hc/en-us',
  ACCESS_OR_SETUP_ARL_BACKUP_REQUEST_HELP_LINK:
    'https://www.myob.com/support/myob-business/product-account/myob-data-file-repair-services',
  ACCESS_OR_SETUP_ACTIVATE_FILE_HELP_LINK:
    'https://www.myob.com/support/myob-business/product-account/activate-a-company-file/activation-and-confirmation-errors',
  ACCESS_OR_SETUP_CAN_NOT_ACCESS_HELP_LINK:
    'https://www.myob.com/support/myob-business/business-settings/changing-and-resetting-user-passwords/if-you-cant-sign-in',
  ACCESS_OR_SETUP_DATA_HELP_LINK:
    'https://www.myob.com/support/myob-business/import-export/importing-and-exporting-data',
  ACCESS_OR_SETUP_INVITE_ADVISOR_BROWSER_HELP_LINK:
    'https://www.myob.com/support/myob-business/business-settings/manage-users/invite-your-accountant-or-bookkeeper',
  ACCESS_OR_SETUP_INVITE_ADVISOR_DESKTOP_HELP_LINK:
    'https://www.myob.com/support/myob-business/get-started/setting-up/set-up-user-access/add-an-accountant-advisor',
  ACCESS_OR_SETUP_INVITE_USER_BROWSER_HELP_LINK:
    ' https://www.myob.com/support/myob-business/business-settings/manage-users/inviting-a-user-to-your-myob-business',
  ACCESS_OR_SETUP_INVITE_USER_DESKTOP_HELP_LINK:
    'https://www.myob.com/support/myob-business/get-started/setting-up/set-up-user-access/invite-a-user-to-an-online-file',
  MANAGE_USER_HELP_LINK: 'https://www.myob.com/support/myob-business/business-settings/manage-users',
  ACCESS_OR_SETUP_INVOICE_TEMPLATES_HELP_LINK:
    'https://www.myob.com/support/myob-business/sales/personalising-invoices',
  ACCESS_OR_SETUP_LINKED_ACCOUNTS_HELP_LINK:
    'https://www.myob.com/support/myob-business/accounting/financial-control/accounts-list/managing-linked-accounts?productview=Browser',
  ACCESS_OR_SETUP_LOCAL_INSTALL_HELP_LINK:
    'https://www.myob.com/support/myob-business/product-account/install-accountright',
  ACCESS_OR_SETUP_LOCAL_INSTALL_NEW_COMPUTER_HELP_LINK:
    'https://www.myob.com/support/myob-business/product-account/install-accountright/setting-up-accountright-on-a-new-computer',
  ACCOUNT_AND_SUBSCRIPTION_ADD_OR_REMOVE_USER_BROWSER_HELP_LINK:
    'https://www.myob.com/support/myob-business/business-settings/manage-users/inviting-a-user-to-your-myob-business',
  ACCOUNT_AND_SUBSCRIPTION_ADD_OR_REMOVE_USER_DESKTOP_HELP_LINK:
    'https://www.myob.com/support/myob-business/get-started/setting-up/set-up-user-access/invite-a-user-to-an-online-file',
  MANAGE_MY_PRODUCTS_HELP_LINK: 'https://myaccount.myob.com/account/products',
  ACCOUNT_AND_SUBSCRIPTION_CANCEL_HELP_LINK:
    'https://www.myob.com/support/myob-business/product-account/myob-subscription-invoices-and-payment-details/cancelling-your-myob-subscription',
  ACCOUNT_DETAILS_HELP_LINK: 'https://myaccount.myob.com/account/manage-account/account-details',
  CONTACT_DETAILS_HELP_LINK: 'https://myaccount.myob.com/account/contact-details',
  ACCOUNT_AND_SUBSCRIPTION_CHANGE_ACCOUNT_OR_CONTACT_DETAIL_HELP_LINK:
    'https://www.myob.com/support/billing-and-account/managing-your-myob-subscription-in-my-account',
  ACCOUNT_AND_SUBSCRIPTION_CHANGE_HELP_LINK:
    'https://www.myob.com/support/billing-and-account/managing-your-myob-subscription-in-my-account',
  ACCOUNT_AND_SUBSCRIPTION_CLOSE_OR_SELLING_AU_HELP_LINK:
    'https://info.myob.com/hubfs/Support/change-of-details-AU-form.pdf',
  ACCOUNT_AND_SUBSCRIPTION_CLOSE_OR_SELLING_NZ_HELP_LINK:
    'https://info.myob.com/hubfs/Support/NZ%20Change%20of%20Details.pdf',
  ACCOUNT_AND_SUBSCRIPTION_CLOSE_OR_SELLING_HELP_LINK:
    'https://www.myob.com/support/myob-business/product-account/managing-your-myob-account',
  ACCOUNT_AND_SUBSCRIPTION_QUESTION_HELP_LINK:
    'https://www.myob.com/support/billing-and-account/understanding-and-paying-your-myob-bill',
  BANK_FEEDS_APPLICATION_ENQUIRY_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/bank-feeds/managing-your-bank-feeds',
  BANK_FEEDS_APPLICATION_ENQUIRY_STATUS_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/bank-feeds/setting-up-bank-feeds/tracking-the-status-of-your-bank-feed-application',
  BANK_FEEDS_BLOCK_MISSING_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/bank-feeds/managing-your-bank-feeds/missing-bank-feed-transactions',
  STATUS_HELP_LINK: 'https://status.myob.com/',
  BANK_FEEDS_DUPLICATE_FEED_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/bank-statements/deleting-imported-bank-statements',
  BANK_FEEDS_MOVING_BANK_FEEDS_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/bank-feeds/managing-your-bank-feeds',
  BANKING_BANK_RULES_HELP_LINK: 'https://www.myob.com/support/myob-business/banking/bank-feed-rules',
  BANKING_BUSINESS_SETTING_HELP_LINK: 'https://www.myob.com/support/myob-business/business-settings/business-settings',
  BANKING_CATEGORISING_TRANSACTION_UNDO_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/reconciling-your-bank-accounts/undoing-a-bank-reconciliation',
  BANKING_CATEGORISING_TRANSACTION_MORE_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/working-with-bank-transactions',
  BANKING_GENERAL_JOURNALS_HELP_LINK: 'https://www.myob.com/support/myob-business/accounting/general-journals',
  BANKING_ELECTRONIC_PAYMENTS_SETUP_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/electronic-payments/making-electronic-payments',
  BANKING_ELECTRONIC_PAYMENTS_LOOKING_HELP_LINK:
    'https://www.myob.com/support/myob-business/sales/receiving-payments/online-invoice-payments',
  BANKING_GETTING_ERROR_HELP_LINK:
    'https://www.myob.com/support/myob-business/get-help-support/myob-business-errors-and-known-issues',
  BANKING_MISSING_TRANSACTION_TRY_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/bank-feeds/managing-your-bank-feeds/missing-bank-feed-transactions',
  BANKING_MISSING_TRANSACTION_STATUS_HELP_LINK: 'https://status.myob.com/',
  BANKING_RECONCILE_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/reconciling-your-bank-accounts/fixing-out-of-balance-bank-reconciliations',
  BANKING_RECORD_SPEND_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/spending-and-receiving-money/spend-money',
  BANKING_RECORD_RECEIVE_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/spending-and-receiving-money/receive-money',
  BANKING_RECURRING_HELP_LINK:
    'https://www.myob.com/support/myob-business/accounting/transactions/recurring-transactions',
  BILLING_FINANCIAL_HARDSHIP_HELP_LINK: 'https://www.myob.com/legal/financial-hardship-policy-terms',
  BILLING_UNDERSTAND_HELP_LINK:
    'https://www.myob.com/support/billing-and-account/understanding-and-paying-your-myob-bill',
  BILLING_WRONG_BILL_CURRENT_HELP_LINK: 'https://myaccount.myob.com/account/products',
  BILLING_WRONG_BILL_EXPLANATION_HELP_LINK:
    'https://www.myob.com/support/billing-and-account/understanding-and-paying-your-myob-bill',
  BILLING_FAILED_PAYMENT_BILL_PAGE_HELP_LINK: 'https://myaccount.myob.com/account/billing/invoices',
  BILLING_FAILED_PAYMENT_DETAIL_HELP_LINK: 'https://myaccount.myob.com/account/billing/payment-details',
  BILLING_SUSPENDED_CANCELED_HELP_LINK: 'https://myaccount.myob.com/account/products',
  FINANCIAL_SERVICE_LOAN_INVOICE_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/sales/getting-business-loans-and-finance',
  FINANCIAL_SERVICE_OIP_REPORT_HELP_LINK:
    'https://www.myob.com/support/myob-business/reporting/online-invoice-payment-reports',
  FINANCIAL_SERVICE_OIP_MORE_HELP_LINK:
    'https://www.myob.com/support/myob-business/sales/receiving-payments/online-invoice-payments',
  SALE_PURCHASES_AUTOMOTIVE_INVOICING_HELP_LINK: 'https://www.myob.com/support/myob-business/sales/einvoicing',
  SALE_PURCHASES_GET_INVOICING_HELP_LINK: 'https://www.myob.com/support/myob-business/sales',
  SALE_PURCHASES_UPLOAD_WAYS_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/purchases/working-with-in-tray-documents/emailing-documents-to-your-in-tray',
  SALE_PURCHASES_UPLOAD_MORE_HELP_LINK:
    'https://www.myob.com/support/myob-business/purchases/working-with-in-tray-documents/working-with-in-tray-documents',
  SALE_PURCHASES_GETTING_ERROR_HELP_LINK:
    'https://www.myob.com/support/myob-business/get-help-support/myob-business-errors-and-known-issues',
  SALE_PURCHASES_PAYMENT_SALE_REMINDER_SETTING_HELP_LINK:
    'https://www.myob.com/support/myob-business/sales/sales-settings/set-up-your-payment-details',
  SALE_PURCHASES_PAYMENT_SALE_REMINDER_INVOICE_HELP_LINK:
    'https://www.myob.com/support/myob-business/sales/online-invoicing/setting-up-invoice-reminders',
  SALE_PURCHASES_SUPPLY_ENTERING_HELP_LINK: 'https://www.myob.com/support/myob-business/purchases/entering-purchases',
  SALE_PURCHASES_SUPPLY_EDIT_HELP_LINK:
    'https://www.myob.com/support/myob-business/purchases/changing-a-bill-quote-or-order',
  SALE_PURCHASES_SUPPLY_RETURN_INVOICE_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/purchases/supplier-returns-debits-and-refunds',
  SALE_PURCHASES_SUPPLY_MORE_HELP_LINK: 'https://www.myob.com/support/myob-business/purchases',
  SALE_PURCHASES_CUSTOMER_OVERPAID_HELP_LINK:
    'https://www.myob.com/support/myob-business/sales/receiving-payments/customer-overpayments',
  SALE_PURCHASES_CUSTOMER_RETURN_HELP_LINK:
    'https://www.myob.com/support/myob-business/sales/customer-returns-credits-and-refunds',
  SALE_PURCHASES_CUSTOMER_REFUND_HELP_LINK:
    'https://www.myob.com/support/myob-business/sales/customer-returns-credits-and-refunds/settling-customer-credits',
  SALE_PURCHASES_CUSTOMER_CREDIT_HELP_LINK:
    'https://www.myob.com/support/myob-business/sales/customer-returns-credits-and-refunds/creating-customer-returns',
  SALE_PURCHASES_CUSTOMER_MORE_HELP_LINK: 'https://www.myob.com/au/support/myob-business/sales',
  REPORTING_BAS_GST_SET_UP_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/end-of-period/month-end-procedures/prepare-your-activity-statement-online/get-ready-to-lodge-activity-statements',
  REPORTING_BAS_GST_RETURN_HELP_LINK:
    'https://www.myob.com/support/myob-business/end-of-period/end-of-period-tasks/completing-your-activity-statement-gst-return',
  REPORTING_BANKING_RECONCILIATION_HELP_LINK:
    'https://www.myob.com/support/myob-business/banking/reconciling-your-bank-accounts/fixing-out-of-balance-bank-reconciliations',
  REPORTING_BANKING_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/reporting/banking-reports',
  REPORTING_BILL_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/reporting/purchases-reports',
  REPORTING_BUSINESS_REPORT_PROFIT_LOSS_HELP_LINK:
    'https://www.myob.com/support/myob-business/reporting/business-reports/analyse-your-profit-or-loss',
  REPORTING_BUSINESS_REPORT_BUDGET_MANAGEMENT_HELP_LINK:
    'https://www.myob.com/support/myob-business/accounting/financial-control/budgets',
  REPORTING_BUSINESS_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/reporting/business-reports',
  REPORTING_CUSTOMER_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/reporting/sales-reports',
  REPORTING_END_FINANCIAL_CHANGE_YEAR_HELP_LINK:
    'https://www.myob.com/support/myob-business/business-settings/changing-the-last-month-of-your-financial-year',
  REPORTING_END_FINANCIAL_TASK_YEAR_HELP_LINK:
    'https://www.myob.com/support/myob-business/end-of-period/end-of-financial-year-tasks',
  REPORTING_EXCEPTIONS_DASHBOARD_HELP_LINK:
    'https://www.myob.com/support/myob-business/accounting/financial-control/auditing-your-records',
  REPORTING_FOREIGN_CURRENCY_HELP_LINK:
    'https://www.myob.com/support/myob-business/accounting/working-with-multiple-currencies',
  REPORTING_INVENTORY_RESTOCK_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/inventory/reordering-items',
  REPORTING_INVENTORY_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/reporting/inventory-reports',
  REPORTING_ERROR_MESSAGE_HELP_LINK: 'https://www.myob.com/au/support/myob-business/reporting',
  REPORTING_JOB_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/reporting/jobs-reports',
  REPORTING_OIP_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/reporting/online-invoice-payment-reports',
  REPORTING_PAYROLL_STP_REPORT_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/single-touch-payroll-reporting/view-single-touch-payroll-reports',
  REPORTING_PAYROLL_FIX_REPORT_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/single-touch-payroll-reporting/fix-rejected-reports-in-single-touch-payroll',
  REPORTING_PAYROLL_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/reporting/payroll-reports',
  REPORTING_SALE_REPORT_HELP_LINK: 'https://www.myob.com/support/myob-business/reporting/sales-reports',
  PAYROLL_ANNUAL_LEAVE_CALCULATIONS_HELP_LINK:
    'https://www.myob.com/support/myob-business/payroll/leave-and-entitlements/managing-your-employees-leave',
  PAYROLL_CHANGE_MY_PAY_SUPER_AUTHORISER_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/superannuation/set-up-pay-super/add-a-pay-super-payment-authoriser',
  PAYROLL_CHANGE_MY_PAY_SUPER_DETAIL_HELP_LINK:
    'https://help.myob.com.au/files/myobbusiness/forms/PaySuper+Change+of+Details+form.pdf',
  PAYROLL_CHANGE_OR_INCREASE_MY_SUPER_LIMIT_OR_AUTHORISER_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/superannuation/set-up-pay-super/changing-your-pay-super-payment-details',
  PAYROLL_CREATING_EMPLOYEES_CHECK_DETAIL_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/add-an-employee/getting-employees-to-check-their-details',
  PAYROLL_CREATING_EMPLOYEES_HELP_LINK: 'https://www.myob.com/support/myob-business/payroll/add-an-employee',
  PAYROLL_ERROR_MESSAGE_STP_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/single-touch-payroll-reporting/fix-rejected-reports-in-single-touch-payroll',
  PAYROLL_ERROR_MESSAGE_HELP_LINK:
    'https://www.myob.com/nz/support/myob-business/payroll/setting-up-payday-filing/errors-in-payday-filing',
  PAYROLL_PAYITEMS_HELP_LINK: 'https://www.myob.com/au/support/myob-business/payroll/setting-up-pay-items-categories',
  PAYROLL_REPORTING_AND_STP_STP_REPORT_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/single-touch-payroll-reporting',
  PAYROLL_REPORTING_AND_STP_PAYDAY_REPORT_HELP_LINK:
    'https://www.myob.com/nz/support/myob-business/payroll/setting-up-payday-filing',
  PAYROLL_REPORTING_AND_STP_STP_FIX_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/single-touch-payroll-reporting/fix-rejected-reports-in-single-touch-payroll',
  PAYROLL_REPORTING_AND_STP_PAYDAY_FIX_HELP_LINK:
    'https://www.myob.com/nz/support/myob-business/payroll/setting-up-payday-filing/errors-in-payday-filing',
  PAYROLL_PAYSLIPS_REPRINT_RESEND_HELP_LINK:
    'https://www.myob.com/support/myob-business/payroll/processing-your-payroll/reprinting-or-resending-pay-slips',
  PAYROLL_PAYSLIPS_ADD_LOGO_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/adding-your-business-logo-to-pay-slips',
  PAYROLL_PAYSLIPS_CUSTOMISE_HELP_LINK: 'https://www.myob.com/au/support/myob-business/payroll/personalising-pay-slips',
  PAYROLL_PROCESSING_HELP_LINK: 'https://www.myob.com/support/myob-business/payroll/processing-your-payroll',
  PAYROLL_PROCESSING_FIX_AU_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/processing-your-payroll/fixing-a-pay',
  PAYROLL_PROCESSING_FIX_NZ_HELP_LINK:
    'https://www.myob.com/nz/support/myob-business/payroll/processing-your-payroll/fixing-a-pay/deleting-a-pay',
  PAYROLL_SETTING_UP_FLARE_HELP_LINK:
    'https://www.myob.com/au/support/myob-business/payroll/add-an-employee/employee-self-onboarding',
  PAYROLL_SETTING_UP_FLARE_BENEFIT_HELP_LINK: 'https://www.myob.com/au/support/myob-business/payroll/employee-benefits',
  PAYROLL_SUPERANNUATION_HELP_LINK: 'https://www.myob.com/au/support/myob-business/payroll/superannuation',
  PAYROLL_KIWISAVER_HELP_LINK: 'https://www.myob.com/nz/support/myob-business/payroll/kiwisaver',
  PAYROLL_TERMINATION_PAYMENTS_HELP_LINK: 'https://www.myob.com/support/myob-business/payroll/processing-a-final-pay',
  PAYROLL_TIMESHEETS_HELP_LINK: 'https://www.myob.com/support/myob-business/payroll/timesheets',
  PAYROLL_TIMESHEETS_SUBMIT_HELP_LINK:
    'https://www.myob.com/support/myob-business/payroll/getting-started-with-myob-team',
  INVENTORY_INVENTORY_ADJUSTMENT_HELP_LINK:
    'https://www.myob.com/support/myob-business/inventory/making-inventory-adjustments',
  INVENTORY_ITEM_SETUP_HELP_LINK: 'https://www.myob.com/support/myob-business/inventory/creating-items',
  INVENTORY_ITEM_SETUP_UPDATE_HELP_LINK:
    'https://www.myob.com/support/myob-business/inventory/making-inventory-adjustments',
  INVENTORY_ERROR_MESSAGE_HELP_LINK:
    'https://www.myob.com/support/myob-business/import-export/importing-data/importing-items',
  INVENTORY_REORDER_LOW_STOCK_ITEMS_HELP_LINK: 'https://www.myob.com/support/myob-business/inventory/reordering-items',
  INVENTORY_REORDER_LOW_STOCK_ITEMS_TO_DO_LIST_HELP_LINK:
    'https://www.myob.com/support/myob-business/accounting/financial-control/see-whats-on-your-to-do-list',
  SERIAL_NUMBER_HELP_LINK: 'https://www.myob.com/support/myob-business/product-account/your-myob-serial-number',
};
export default config;
