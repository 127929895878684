import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SupportTicketWrapper } from './styles';
import { Alert, BaseTemplate, PageHead, Text } from '@myob/myob-widgets';
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account';
import LoadingWrapper from '../components/LoadingWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from 'stores';
import { navigateToUrl } from 'single-spa';
import { Forbidden } from 'components/Forbidden/Forbidden';
import { HttpStatus } from 'helpers/request';
import { ModalBox } from '../components/PopUpModal';
import { helper } from '@my-account/tools';
import { PremiumSupportBookingForm } from './components/PremiumSupportBookingForm';
import { AvailableSlotsState, fetchAvailableSlots } from './reducers/getAvailableSlots';
import { addBooking, BookingState } from './reducers/sendBooking';
import { BookingRequestBody, PremiumSupportBookingInfo } from './type';
import { isEmpty, omitBy } from 'lodash';
import { getScheduledTime } from '../helpers/tools';
import { SuccessfulBooking } from './components/SuccessfulBooking';
import { FailedBooking } from './components/FailedBooking';

export const PremiumSupportBooking: React.FC = () => {
  const dispatch: AppThunkDispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { selected: selectedAccount, error: accountSelectError } =
    useContext<AccountSelectContextValue>(AccountSelectContext);
  const [isPremiumUser, setIsPremiumUser] = useState<boolean>(localStorage.getItem('isPremiumCustomer') === 'true');

  const [showPremiumSupportBooking, setShowPremiumSupportBooking] = useState(true);

  const {
    fetch: { isLoading: isFetchLoading, isError: isFetchError, data: availableSlotsResponse, error },
  } = useSelector<RootState, AvailableSlotsState>((state) => state.availableSlots);

  const {
    save: { isLoading: isSaveLoading, isError: isSaveError },
  } = useSelector<RootState, BookingState>((state) => state.booking);

  useEffect(() => {
    dispatch(fetchAvailableSlots({ region: helper.getRegionFromTimezone(), clientId: selectedAccount?.clientId }));
  }, []);

  useEffect(() => {
    setIsPremiumUser(localStorage.getItem('isPremiumCustomer') === 'true');
  }, [selectedAccount]);

  const handleOnSave = async (data: PremiumSupportBookingInfo) => {
    const scheduledTime = getScheduledTime(data.date, data.availableTime, helper.getRegionFromTimezone());
    const bookingData: BookingRequestBody = {
      scheduledTime: scheduledTime,
      clientId: selectedAccount.clientId,
      fullName: data.name,
      email: data.emailAddress,
      phoneNumber: data.contactPhoneNumber,
      serialNumber: data.serialNumber,
      issueDescription: data.description,
      region: data.region,
      timeZoneId: data.timeZoneId,
    };
    await dispatch(
      addBooking({
        bookingData: omitBy(bookingData, isEmpty) as BookingRequestBody,
        region: helper.getRegionFromTimezone(),
      }),
    );
    setShowPremiumSupportBooking(false);
  };

  const goHomePage = () => {
    navigateToUrl('/account');
  };

  const handleOnDiscard = () => {
    setShowModal(false);
    goHomePage();
  };

  const handleGoBack = () => {
    setShowModal(false);
  };

  const content = useMemo(() => {
    if (!isPremiumUser) {
      return (
        <Forbidden
          pageTitle="Priority Support booking"
          title="You don’t have access to this page."
          description="Priority Support bookings are only available for customers who have an MYOB AccountRight Plus or Premier subscription."
        />
      );
    }
    if (accountSelectError?.status === HttpStatus.Forbidden) {
      return (
        <Forbidden
          pageTitle="Priority Support booking"
          title="You will need administrator access to see this page"
          description="Contact the primary contact of this account to get authorized access. If you need help, get in touch with our support team."
        />
      );
    } else if (selectedAccount) {
      if (isSaveError || isFetchError) {
        return (
          <FailedBooking
            onClick={() => {
              setShowPremiumSupportBooking(true);
              dispatch(
                fetchAvailableSlots({ region: helper.getRegionFromTimezone(), clientId: selectedAccount?.clientId }),
              );
            }}
            message="Booking has failed. Please try again later."
          />
        );
      }
      if (showPremiumSupportBooking && availableSlotsResponse) {
        return (
          <SupportTicketWrapper data-testid="supportTicketWrapper">
            <PageHead title="Book Priority Support" className="page-head" />
            {availableSlotsResponse?.datesAndTimeSlots?.length === 0 ? (
              <Alert tone="danger">
                {'There are currently no available time slots. Please check back again later.'}
              </Alert>
            ) : null}
            <Text marginBottom="xl">
              As part of your AccountRight subscription, you can book a session to speak with one of our experts at a
              time that suits you.
            </Text>
            <Text>
              Need help immediately? Call our team on{' '}
              {helper.getRegionFromTimezone() == 'NZ' ? '0508 328 283' : '1300 555 123'} - as an AccountRight customer
              you go to the front of the queue.
            </Text>
            <Text>Fill out the form below to schedule your session.</Text>
            <PremiumSupportBookingForm
              className="premium-support-booking-form"
              onCancel={() => setShowModal(true)}
              onSave={handleOnSave}
              availableSlots={availableSlotsResponse}
            />
          </SupportTicketWrapper>
        );
      } else {
        return (
          <SuccessfulBooking
            onClick={() => goHomePage()}
            title="Thanks for booking MYOB Priority Support"
            message="We'll send you updates about your Priority Support booking request to the email you used to log in to your MYOB account."
          />
        );
      }
    }
  }, [
    error,
    availableSlotsResponse,
    selectedAccount,
    accountSelectError,
    isPremiumUser,
    isSaveError,
    isFetchError,
    showPremiumSupportBooking,
  ]);

  return (
    <div>
      {showModal && (
        <ModalBox
          onDiscard={handleOnDiscard}
          onGoBack={handleGoBack}
          title="Cancel support request?"
          message="Are you sure you want to discard your support request?"
        />
      )}
      <LoadingWrapper isLoading={isFetchLoading}>
        <BaseTemplate data-testid="premium-support-booking">{content}</BaseTemplate>
      </LoadingWrapper>
    </div>
  );
};
