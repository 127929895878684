import { Label, Pagination, Table } from '@myob/myob-widgets';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { SupportRequestInfo, SupportType, Tab } from '../type';
import { chunk, orderBy } from 'lodash';
import { STATUS, tableColumns } from './constant';
import { NoData } from './NoData';
import LoadingWrapper from '../../components/LoadingWrapper';
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account';
import { HttpStatus } from 'helpers/request';
import Forbidden from '../../components/Forbidden';
import { navigateToUrl } from 'single-spa';
import ErrorComponent from '../../components/ErrorComponent';
import { GtmManager, helper } from '@my-account/tools';
import { getZoneIdFromRegion } from '../../helpers/tools';

type SupportRequestTableProps = {
  errorStatus: HttpStatus;
  supportRequestsList: SupportRequestInfo[];
  isLoading: boolean;
  selectedTab: string;
};

export const SupportRequestTable: React.FC<SupportRequestTableProps> = ({
  errorStatus,
  supportRequestsList,
  isLoading,
  selectedTab,
}) => {
  const [columns] = useState(tableColumns);
  const [activePage, setActivePage] = useState(1);
  const { selected: selectedAccount } = useContext<AccountSelectContextValue>(AccountSelectContext);

  useEffect(() => {
    setActivePage(1);
  }, [selectedAccount, selectedTab]);
  const timeTransformer = (time) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment.utc(time).tz(timezone).format('DD/MM/YYYY');
  };

  const formatPremiumSupportBookingTimeSubject = (scheduledTime: string): string => {
    return (
      'Priority Support on ' +
      moment.utc(scheduledTime).tz(getZoneIdFromRegion(helper.getRegionFromTimezone())).format('DD/MM [at] HH:mm')
    );
  };

  const statusLabel = (label) => {
    return (
      <Label type="boxed" tone={STATUS[label]}>
        {label}
      </Label>
    );
  };

  const handleOnClick = (id: string, status: string, supportType: SupportType) => {
    supportType === SupportType.BookingRequest
      ? navigateToUrl(`/account/support/priority-support-booking/${id}`)
      : navigateToUrl(`/account/support/support-requests/${id}`);
    GtmManager.dataLayer({
      dataLayer: {
        event: 'view_support_request_details',
        request_status: status,
        page_index: activePage,
      },
      dataLayerName: 'MyAccount',
    });
  };

  const renderRow = (request: SupportRequestInfo) => (
    <Table.Row key={request.id} rowData={request}>
      <Table.RowItem columnName="Subject" width="flex-2">
        {request.supportType == SupportType.BookingRequest
          ? formatPremiumSupportBookingTimeSubject(request.subject)
          : request.subject}
      </Table.RowItem>
      <Table.RowItem columnName="Number">
        {request.supportType == SupportType.BookingRequest ? '' : request.caseNumber}
      </Table.RowItem>
      <Table.RowItem columnName="Date created">{timeTransformer(request.created)}</Table.RowItem>
      <Table.RowItem columnName="Last updated">{timeTransformer(request.lastUpdated)}</Table.RowItem>
      <Table.RowItem columnName="Status">{statusLabel(request.status)}</Table.RowItem>
      <Table.RowItem columnName="Requester" width="flex-2">
        {request.supportType ? request.fullName : request.requesterFirstName + '\xa0' + request.requesterLastName}
      </Table.RowItem>
      <Table.RowItem columnName="Link">
        <a
          onClick={() => {
            handleOnClick(request.id, request.status, request.supportType);
          }}
          onKeyDown={() => {
            handleOnClick(request.id, request.status, request.supportType);
          }}
        >
          {request.supportType == SupportType.BookingRequest ? `View booking →` : `View request →`}
        </a>
      </Table.RowItem>
    </Table.Row>
  );

  const activeChunkedList = (): SupportRequestInfo[] => {
    return chunk(orderBy(supportRequestsList, 'lastUpdated', 'desc'), 10)[activePage - 1];
  };

  const content = useMemo(() => {
    if (errorStatus === HttpStatus.Forbidden) {
      return <Forbidden pageTitle="" title="You don’t have access to the requests" description="" showAction={false} />;
    }
    if (errorStatus && errorStatus !== HttpStatus.Unauthorized) {
      return <ErrorComponent pageTitle="" />;
    }
    if (supportRequestsList && supportRequestsList.length) {
      return (
        <>
          <Table.Body>{activeChunkedList().map(renderRow)}</Table.Body>
          <Pagination
            pageCount={Math.ceil(supportRequestsList.length / 10)}
            activePage={activePage}
            onSelect={(page) => setActivePage(page)}
            className="pagination"
          />
        </>
      );
    }
    return (
      <NoData
        pageTitle={selectedTab == Tab.Open ? "You don't have any open requests" : "You don't have any closed requests"}
      />
    );
  }, [errorStatus, supportRequestsList, selectedTab, activePage]);

  return (
    <Table variant="card">
      <Table.Header>
        {columns.map((column: Record<string, unknown>) => (
          <Table.HeaderItem key={column.key} width={column.width}>
            {column.description}
          </Table.HeaderItem>
        ))}
      </Table.Header>
      <LoadingWrapper isLoading={isLoading}>{content}</LoadingWrapper>
    </Table>
  );
};
