import { auth, GtmManager } from '@my-account/tools';
import config from 'config';

export interface ResponseError {
  status?: HttpStatus;
  message?: string;
}

export enum HttpStatus {
  GetSuccess = 200,
  PostSuccess = 201,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  ServerError = 500,
}

export const getRequestUrl = (requestPath: string): string => `${config.API_BASE_URL}${requestPath}`;

function pushGTMEvent(httpMethod: string, apiPath: string, status: number) {
  GtmManager.dataLayer({
    dataLayer: {
      event: 'API_Request',
      api_url: `${httpMethod} ${apiPath}`,
      http_status: status,
    },
    dataLayerName: 'MyAccount',
  });
}

function buildHttpMethodAndHeaders(customConfig: RequestInit, token: string) {
  const config: RequestInit = {
    method: 'GET',
    ...customConfig,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  return config;
}

export const request = async <T>(requestPath: string, customConfig?: RequestInit): Promise<T> => {
  const token = await auth.getToken();

  return new Promise<T>((resolve, reject) => {
    const config = buildHttpMethodAndHeaders(customConfig, token);
    const requestUrl = getRequestUrl(requestPath);
    const apiPath = requestUrl.split('?')[0];

    fetch(requestUrl, config)
      .then(async (response) => {
        const { status } = response;

        pushGTMEvent(config.method, apiPath, status);

        if (status === HttpStatus.Unauthorized) {
          return auth.login();
        }

        if (!response.ok) {
          reject({ status } as ResponseError);
        }

        const body = config.method === 'POST' ? null : await response.json();

        resolve(body);
      })
      .catch(reject);
  });
};
