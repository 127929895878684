import { BaseTemplate } from '@myob/myob-widgets';
import { useParams } from 'react-router-dom';
import {
  CommunicationHistoryWrapper,
  ContentWrapper,
  HeaderWrapper,
  NotificationWrapper,
  SupportDetailsWrapper,
  SupportRequestDetailsWrapper,
} from './styles';
import React, { useEffect, useMemo } from 'react';
import { SupportRequestDetailsTable } from './components/SupportRequestDetailsTable';
import { AppThunkDispatch, RootState } from '../stores';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSupportRequestDetailsAsync, SupportDetailsState } from './reducers/getRequestDetails';
import { HttpStatus } from 'helpers/request';
import Forbidden from 'components/Forbidden';
import ErrorComponent from 'components/ErrorComponent';
import { CommunicationHistory } from './components/CommunicationHistory';
import { mockSupportRequestDetails } from './fixture';
import { ReplyBox } from './components/ReplyBox';
import { featureFlags } from '../configs/FeatureFlags';
import { Notification } from '../components/Notification';
import { ReplyRequestState, resetIsReplyRequestError } from './reducers/replyRequest';
import { ENotificationType } from './reducers/notification/type';
import LoadingWrapper from 'components/LoadingWrapper';

export const SupportDetails: React.FC = () => {
  const dispatch: AppThunkDispatch = useDispatch();
  const { id: caseId } = useParams<{ id: string }>();
  const {
    fetch: { isLoading, data: supportRequestDetails, error },
  } = useSelector<RootState, SupportDetailsState>((state) => state.supportDetails);
  const { isError: isReplyError, isLoading: isReplyLoading } = useSelector<RootState, ReplyRequestState>(
    (state) => state.replyRequest,
  );

  useEffect(() => {
    dispatch(fetchSupportRequestDetailsAsync(caseId));
    dispatch({
      type: ENotificationType.Clear,
    });
  }, []);

  useEffect(() => {
    if (isReplyError === false) dispatch(fetchSupportRequestDetailsAsync(caseId));
  }, [isReplyError]);

  const { businessName, communicationHistory } = mockSupportRequestDetails;

  const supportDetailsContent = () => {
    return (
      <SupportRequestDetailsTable supportRequestDetails={supportRequestDetails} isLoading={isLoading} error={error} />
    );
  };

  const communicationHistoryContent = () => {
    return <CommunicationHistory businessName={businessName} communicationHistory={communicationHistory} />;
  };

  const handleOnDismiss = () => {
    dispatch(resetIsReplyRequestError());
  };

  const content = useMemo(() => {
    if (error.status === HttpStatus.Forbidden) {
      return <Forbidden pageTitle="" title="You don’t have access to the request" description="" showAction={false} />;
    }
    if (error.status && error.status !== HttpStatus.Unauthorized) {
      return <ErrorComponent pageTitle="" />;
    }
    return (
      <>
        <div className="support-sub-title">
          <h3>Support status</h3>
        </div>
        <SupportRequestDetailsWrapper>{supportDetailsContent()}</SupportRequestDetailsWrapper>
        {featureFlags.isCommunicationHistoryDisplay() && (
          <>
            <div className="support-sub-title">
              <h3>Communication history</h3>
            </div>
            <CommunicationHistoryWrapper>{communicationHistoryContent()}</CommunicationHistoryWrapper>
            <ReplyBox />
          </>
        )}
      </>
    );
  }, [error, supportRequestDetails]);

  return (
    <BaseTemplate data-testid="support-request-details">
      <SupportDetailsWrapper>
        <HeaderWrapper>
          <h2 className="support-title">Support request overview</h2>
        </HeaderWrapper>
        <NotificationWrapper>
          <Notification onDismiss={handleOnDismiss} />
        </NotificationWrapper>
        <LoadingWrapper isLoading={isReplyLoading}>
          <ContentWrapper>{content}</ContentWrapper>
        </LoadingWrapper>
      </SupportDetailsWrapper>
    </BaseTemplate>
  );
};
