import React from 'react';
import { Card } from '@myob/myob-widgets';
import { CommunicationHistoryInfo, SupportRequestDetailsInfo } from '../type';
import { orderBy } from 'lodash';
import { Conversation } from './Conversation';
import classNames from 'classnames';

type CommunicationHistoryProps = {
  businessName: SupportRequestDetailsInfo['businessName'];
  communicationHistory: CommunicationHistoryInfo[];
};

export const CommunicationHistory: React.FC<CommunicationHistoryProps> = ({ businessName, communicationHistory }) => {
  const orderByCreatedTime = () => {
    return orderBy(communicationHistory, 'createdAt', 'desc');
  };

  const renderCardBody = () => {
    const content = orderByCreatedTime().map((conversation, index) => (
      <Conversation key={index} businessName={businessName} conversation={conversation} />
    ));
    return content;
  };
  return (
    businessName &&
    communicationHistory && (
      <>
        <Card
          body={renderCardBody()}
          className={classNames('communication-card', { 'scroll-y': communicationHistory.length > 4 })}
        />
      </>
    )
  );
};
