import { BookingDetailsInfo } from '../type';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchPremiumSupportBookingDetailsById } from 'helpers/api';
import { ResponseError } from 'helpers/request';

export type BookingDetailsState = {
  fetch: {
    isLoading: boolean;
    isError: boolean;
    data: BookingDetailsInfo;
    error: ResponseError;
  };
};

export const initialState: BookingDetailsState = {
  fetch: {
    isLoading: false,
    isError: false,
    data: null,
    error: {
      status: null,
      message: null,
    },
  },
};

export const fetchBookingDetailsAsync = createAsyncThunk<BookingDetailsInfo, string, { rejectValue: ResponseError }>(
  'account/fetchBookingDetailsById',
  async (bookingId, thunkApi) => {
    try {
      const data = await fetchPremiumSupportBookingDetailsById(bookingId);
      return data;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);

const slice = createSlice({
  name: 'premium-support-booking-details',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchBookingDetailsAsync.pending, (state) => {
      state.fetch.isLoading = true;
      state.fetch.isError = false;
      state.fetch.data = null;
      state.fetch.error = { status: null, message: null };
    });
    builder.addCase(fetchBookingDetailsAsync.fulfilled, (state, action) => {
      state.fetch.isLoading = false;
      state.fetch.isError = false;
      state.fetch.data = action.payload;
      state.fetch.error = { status: null, message: null };
    });
    builder.addCase(fetchBookingDetailsAsync.rejected, (state, action) => {
      state.fetch.isLoading = false;
      state.fetch.isError = true;
      state.fetch.data = null;
      state.fetch.error = action.payload;
    });
  },
});
export const bookingDetailsReducer = slice.reducer;
