import { RequestBody } from '../type';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { saveSupportTicket } from 'helpers/api';
import { ResponseError } from 'helpers/request';

export type SupportTicketState = {
  save: {
    isLoading: boolean;
    isError: boolean;
    error: ResponseError;
  };
};

export const initialState: SupportTicketState = {
  save: {
    isLoading: false,
    isError: false,
    error: {
      status: null,
      message: null,
    },
  },
};

export const addSupportTicket = createAsyncThunk<Response, RequestBody, { rejectValue: ResponseError }>(
  'account/digital-case',
  async (data, thunkApi) => {
    try {
      await saveSupportTicket(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);

const slice = createSlice({
  name: 'digital-case',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(addSupportTicket.pending, (state) => {
      state.save.isLoading = true;
      state.save.isError = false;
      state.save.error = { status: null, message: null };
    });
    builder.addCase(addSupportTicket.fulfilled, (state) => {
      state.save.isLoading = false;
      state.save.isError = false;
      state.save.error = { status: null, message: null };
    });
    builder.addCase(addSupportTicket.rejected, (state, action) => {
      state.save.isLoading = false;
      state.save.isError = true;
      state.save.error = action.payload;
    });
  },
});
export const supportTicketReducer = slice.reducer;
