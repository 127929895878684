import { useEffect } from 'react';
import { trackPage } from 'helpers/TelemetryHelper';
import { useLocation } from 'react-router-dom';
import { isBelongToERoutePath } from 'helpers/tools';

export const usePageView = (): void => {
  const location = useLocation();
  useEffect(() => {
    if (isBelongToERoutePath(location.pathname)) {
      trackPage(location.pathname);
    }
  }, [location.pathname]);
};
