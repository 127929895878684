import React from 'react';
import { BaseTemplate, Button, PageState } from '@myob/myob-widgets';
import success from '../../assets/images/success.svg';
import { DescriptionWrapper } from '../styles';

type SuccessfulProps = {
  onClick: () => void;
  title: string;
  message?: string;
};

export const SuccessfulBooking: React.FC<SuccessfulProps> = ({ onClick, title, message }) => {
  return (
    <>
      <BaseTemplate>
        <PageState
          title={title}
          actions={[
            <Button key="return-to-my-account" type="primary" onClick={onClick}>
              Return to My Account
            </Button>,
          ]}
          description={
            <DescriptionWrapper>
              <div>{message}</div>
            </DescriptionWrapper>
          }
          image={<img src={success} alt="success" />}
        />
      </BaseTemplate>
    </>
  );
};
