import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { Root } from './root.component';
import { checkFeelixVersion } from '@my-account/tools';

process.env.APP_ENV !== 'production' && checkFeelixVersion(PROJECT_NAME, PROJECT_FEELIX_VERSION);

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
