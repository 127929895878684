import { ReplyBody } from '../type';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { replyRequestDetailsById } from 'helpers/api';
import { ResponseError } from 'helpers/request';

export type ReplyRequestState = {
  isLoading: boolean;
  isError: boolean;
  error: ResponseError;
  reply: string;
};

export const initialState: ReplyRequestState = {
  isLoading: false,
  isError: null,
  error: {
    status: null,
    message: null,
  },
  reply: null,
};

export const replyRequest = createAsyncThunk<Response, ReplyBody, { rejectValue: ResponseError }>(
  'account/digital-case',
  async (data, thunkApi) => {
    try {
      await replyRequestDetailsById(data);
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);

const slice = createSlice({
  name: 'digital-case/reply-request',
  reducers: {
    resetIsReplyRequestError(state) {
      state.isError = null;
    },
    saveReply(state, data) {
      state.reply = data.payload;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder.addCase(replyRequest.pending, (state) => {
      state.isLoading = true;
      state.isError = null;
      state.error = { status: null, message: null };
    });
    builder.addCase(replyRequest.fulfilled, (state) => {
      state.isLoading = false;
      state.isError = false;
      state.error = { status: null, message: null };
      state.reply = null;
    });
    builder.addCase(replyRequest.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    });
  },
});
export const replyRequestReducer = slice.reducer;
export const { resetIsReplyRequestError, saveReply } = slice.actions;
