import {
  AvailableSlotsInfo,
  BookingDetailsInfo,
  BookingStatus,
  SupportRequestDetailsInfo,
  SupportRequestInfo,
  SupportType,
} from '../type';

export const mockSupportRequestList: SupportRequestInfo[] = [
  {
    id: '500O000000SjRyCIAV',
    caseNumber: '01202834',
    status: 'New',
    subject: 'Account and subscription Change or cancel my subscription 01202834',
    created: '2023-01-16T15:21:09.000+0000',
    lastUpdated: '2023-01-16T15:21:47.000+0000',
    requesterFirstName: 'Archie',
    requesterLastName: 'Test',
  },
];

export const mockAvailableSlots: AvailableSlotsInfo = {
  datesAndTimeSlots: [
    {
      date: '2009-12-12',
      availableSlots: ['09:00'],
    },
  ],
};

export const mockEmptyAvailableSlots: AvailableSlotsInfo = {
  datesAndTimeSlots: [],
};

export const mockPremiumSupportBookingDetailsData: BookingDetailsInfo = {
  booking: {
    scheduledTime: '2023-06-06T09:00:00.000Z',
    clientId: '',
    fullName: 'Archietest_Yilong_First_name',
    phoneNumber: '+611234451231',
    email: 'Archietest_Test@gmail.com',
    serialNumber: '123456789123',
    issueDescription: 'I am having trouble',
    status: BookingStatus.New,
  },
};

export const mockPremiumSupportBookingDetailsClosedData: BookingDetailsInfo = {
  booking: {
    scheduledTime: '2023-06-06T09:00:00.000Z',
    clientId: '',
    fullName: 'Archietest_Yilong_First_name',
    phoneNumber: '+611234451231',
    email: 'Archietest_Test@gmail.com',
    serialNumber: '123456789123',
    issueDescription: 'I am having trouble',
    status: BookingStatus.Closed,
  },
};

export const mockSupportRequestListWithMultipleStatus: SupportRequestInfo[] = [
  {
    id: '5001m0000088GfiAAE',
    caseNumber: '01216715',
    status: 'In progress',
    subject: 'My MYOB bill 01216715',
    created: '2023-02-02T08:03:38.000+0000',
    lastUpdated: '2023-02-03T09:37:49.000+0000',
    requesterFirstName: 'firstName',
    requesterLastName: 'lastName',
  },
  {
    id: '5001m0000088GfxAAE',
    caseNumber: '01216716',
    status: 'New',
    subject: 'My MYOB bill 01216716',
    created: '2023-02-02T08:05:18.000+0000',
    lastUpdated: '2023-02-02T08:05:46.000+0000',
    requesterFirstName: 'firstName',
    requesterLastName: 'lastName',
  },
  {
    id: '5001m0000088Gg2AAE',
    caseNumber: '01216717',
    status: 'In progress',
    subject: 'My MYOB bill 01216717',
    created: '2023-02-02T08:05:22.000+0000',
    lastUpdated: '2023-02-02T08:17:40.000+0000',
    requesterFirstName: 'firstName',
    requesterLastName: 'lastName',
  },
  {
    id: '5001m0000088Gg7AAE',
    caseNumber: '01216718',
    status: 'New',
    subject: 'My MYOB bill 01216718',
    created: '2023-02-02T08:05:26.000+0000',
    lastUpdated: '2023-02-02T08:05:46.000+0000',
    requesterFirstName: 'firstName',
    requesterLastName: 'lastName',
  },
  {
    id: '5001m0000088GgXAAU',
    caseNumber: '01216720',
    status: 'New',
    subject: 'My MYOB bill 01216720',
    created: '2023-02-02T08:09:29.000+0000',
    lastUpdated: '2023-02-02T08:10:03.000+0000',
    requesterFirstName: 'firstName',
    requesterLastName: 'lastName',
  },
  {
    id: '5001m0000088IzxAAE',
    caseNumber: '01216832',
    status: 'New',
    subject: 'sub',
    created: '2023-02-03T09:35:23.000+0000',
    lastUpdated: '2023-02-03T09:36:01.000+0000',
    requesterFirstName: 'testAccount',
    requesterLastName: 'whatever',
  },
  {
    id: '5001m0000088J07AAE',
    caseNumber: '01216833',
    status: 'New',
    subject: 'sub',
    created: '2023-02-03T09:39:37.000+0000',
    lastUpdated: '2023-02-03T09:40:04.000+0000',
    requesterFirstName: 'testAccount',
    requesterLastName: 'whatever',
  },
  {
    id: '5001m0000088J0MAAU',
    caseNumber: '01216834',
    status: 'New',
    subject: 'Account and subscription Change or cancel my subscription 01202834',
    created: '2023-02-03T09:40:40.000+0000',
    lastUpdated: '2023-02-03T09:40:48.000+0000',
    requesterFirstName: 'testAccount',
    requesterLastName: 'whatever',
  },
  {
    id: '5001m0000088J0WAAU',
    caseNumber: '01216835',
    status: 'New',
    subject: 'Billing My subscription is suspended, cancelled, read-only 01202846',
    created: '2023-02-03T09:41:33.000+0000',
    lastUpdated: '2023-02-03T09:41:48.000+0000',
    requesterFirstName: 'testAccount',
    requesterLastName: 'whatever',
  },
  {
    id: '500O000000SjSeZIAV',
    caseNumber: '01202845',
    status: 'In progress',
    subject: 'Account and subscription Change or cancel my subscription 01202834',
    created: '2023-01-17T07:17:42.000+0000',
    lastUpdated: '2023-01-30T07:17:46.000+0000',
    requesterFirstName: 'Archie',
    requesterLastName: 'Test',
  },
  {
    id: '500O000000SjSeeIAF',
    caseNumber: '01202846',
    status: 'New',
    subject: 'Billing My subscription is suspended, cancelled, read-only 01202846',
    created: '2023-01-17T07:17:44.000+0000',
    lastUpdated: '2023-01-30T07:19:01.000+0000',
    requesterFirstName: 'Archietest_Yilong_First_name',
    requesterLastName: 'Archietest_Test',
  },
  {
    id: '500O000000SjSaPIAV',
    caseNumber: '01202849',
    status: 'Action required',
    subject: 'Billing My subscription is suspended, cancelled, read-only 01202849',
    created: '2023-01-17T07:21:40.000+0000',
    lastUpdated: '2023-01-10T07:22:16.000+0000',
    requesterFirstName: 'Archietest_Yilong_First_name',
    requesterLastName: 'Archietest_Test',
  },
  {
    id: '11111111-1111-1111-1111-111111111111',
    caseNumber: '11111111-1111-1111-1111-111111111111',
    status: 'New',
    subject: '2024-07-23T00:00:00Z',
    created: '2024-07-21T00:00:00Z',
    lastUpdated: '2024-07-23T00:00:00Z',
    requesterFirstName: 'Archietest_Yilong_First_name',
    requesterLastName: 'Archietest_Test',
    supportType: SupportType.BookingRequest,
    fullName: 'Archietest_Yilong_First_name Archietest_Test',
  },
];

export const mockSupportRequestDetailsData: SupportRequestDetailsInfo = {
  id: '5001m0000088GfiAAE',
  caseNumber: '01216715',
  status: 'In progress',
  subject: 'My MYOB bill 01216715',
  created: '2023-02-02T08:03:38.000+0000',
  lastUpdated: '2023-02-03T09:37:49.000+0000',
  requesterFirstName: 'firstName',
  requesterLastName: 'lastName',
  description: 'description',
};

export const mockSupportRequestDetails: SupportRequestDetailsInfo = {
  id: '5001m000008UwSVAA0',
  caseNumber: '01219808',
  created: '2023-03-01T09:28:35.000+0000',
  description:
    "Hello, \r\n\r\nI'm having trobule accessing my account on your website. Whenever I try to log in, I get an error message saying that my email or password is incorrect. However, I'm sure that I'm using the correct login details. Can you please help me figure out what's going wrong?\r\n\r\nThank you,\r\nJohn",
  lastUpdated: '2023-03-01T09:29:05.000+0000',
  requesterFirstName: 'Archietest_Yilong_First_name',
  requesterLastName: 'Archietest_Test',
  status: 'New',
  subject: 'help needed with my account',

  businessName: 'Mocked Business Name',
  communicationHistory: [
    {
      content:
        'Hi, I am having a problem with my billing I cant seem to do xys. ving a problem with my billing I cant seem to do xys.ving a problem with my billing I cant seem to do xys.ving a problem with my billing I cant seem to do xys.ving a problem with my billing I cant seem to do xys.' +
        'Thank you,\n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'John',
      origin: 'client',
      createdAt: '2023-02-25T14:13:50.168Z',
    },
    {
      content:
        'Hello, \n' +
        '\n' +
        "I'm having trobule accessing my account on your website. Whenever I try to log in, I get an error message saying that my email or password is incorrect. However, I'm sure that I'm using the correct login details. Can you please help me figure out what's going wrong?\n" +
        '\n' +
        'Thank you,\n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'John',
      origin: 'client',
      createdAt: '2023-02-26T22:37:25.599Z',
    },
    {
      content:
        'Hello, \n' +
        '\n' +
        "I'm having trobule accessing my account on your website. Whenever I try to log in, I get an error message saying that my email or password is incorrect. However, I'm sure that I'm using the correct login details. Can you please help me figure out what's going wrong?\n" +
        '\n' +
        'Thank you,\n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'Kind reagrds, \n' +
        'John',
      origin: 'client',
      createdAt: '2023-02-26T22:37:25.599Z',
    },
    {
      content:
        'Hi Thomas Johnson,\n' +
        'Thanks so much for reaching out!\n' +
        'We’ve received your [type of request] request, and we just want to let you know that we’re working on it. You’ll hear back from us within [reasonable time frame].\n' +
        'We’ve received your [type of request] request, and we just want to let you know that we’re working on it. You’ll hear back from us within [reasonable time frame].\n' +
        'We’ve received your [type of request] request, and we just want to let you know that we’re working on it. You’ll hear back from us within [reasonable time frame].\n' +
        'We’ve received your [type of request] request, and we just want to let you know that we’re working on it. You’ll hear back from us within [reasonable time frame].\n' +
        'We’ve received your [type of request] request, and we just want to let you know that we’re working on it. You’ll hear back from us within [reasonable time frame].\n' +
        'We’ve received your [type of request] request, and we just want to let you know that we’re working on it. You’ll hear back from us within [reasonable time frame].\n' +
        'We’ve received your [type of request] request, and we just want to let you know that we’re working on it. You’ll hear back from us within [reasonable time frame].\n' +
        'We’ve received your [type of request] request, and we just want to let you know that we’re working on it. You’ll hear back from us within [reasonable time frame].\n' +
        'We’ve received your [type of request] request, and we just want to let you know that we’re working on it. You’ll hear back from us within [reasonable time frame].\n' +
        'We’ve received your [type of request] request, and we just want to let you know that we’re working on it. You’ll hear back from us within [reasonable time frame].\n' +
        'We’ve received your [type of request] request, and we just want to let you know that we’re working on it. You’ll hear back from us within [reasonable time frame].\n' +
        'We’ve received your [type of request] request, and we just want to let you know that we’re working on it. You’ll hear back from us within [reasonable time frame].\n' +
        'We’ve received your [type of request] request, and we just want to let you know that we’re working on it. You’ll hear back from us within [reasonable time frame].\n' +
        'We’ve received your [type of request] request, and we just want to let you know that we’re working on it. You’ll hear back from us within [reasonable time frame].\n' +
        'We’ve received your [type of request] request, and we just want to let you know that we’re working on it. You’ll hear back from us within [reasonable time frame].\n' +
        'Please let us know if you have additional questions or concerns. We’re here to help! Thank you for being a [Company Name] customer.\n' +
        'Sincerely,\n' +
        'MYOB',
      origin: 'myob',
      createdAt: '2023-03-01T10:45:16.589Z',
    },
  ],
};
