import React from 'react';
import { Button, Card, PageState } from '@myob/myob-widgets';
import somethingWentWrong from '../../assets/images/somethingWentWrong.svg';

type FailedBookingProps = {
  onClick: () => void;
  message: string;
};

export const FailedBooking: React.FC<FailedBookingProps> = ({ onClick, message }) => {
  return (
    <>
      <Card>
        <PageState
          title="Something went wrong"
          description={[message]}
          actions={[
            <Button
              key={'try-again-button'}
              style={{ background: '#7519B5', 'border-color': '#7519B5' }}
              onClick={onClick}
            >
              Try Again
            </Button>,
          ]}
          image={<img src={somethingWentWrong} alt="Something Went Wrong" />}
        />
      </Card>
    </>
  );
};
