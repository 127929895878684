import React, { useEffect, useState } from 'react';
import { ButtonRow, Button, TextArea } from '@myob/myob-widgets';
import { replyRequest, ReplyRequestState, saveReply } from '../reducers/replyRequest';
import { AppThunkDispatch, RootState } from 'stores';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReplyBoxWrapper } from '../styles';
import { ENotificationType } from 'modules/reducers/notification/type';
import { GtmManager } from '@my-account/tools';

export const ReplyBox: React.FC = () => {
  const dispatch: AppThunkDispatch = useDispatch();
  const { id: caseId } = useParams<{ id: string }>();
  const { isError: isReplyError, reply: replySaved } = useSelector<RootState, ReplyRequestState>(
    (state) => state.replyRequest,
  );

  const [errorsMessage, setErrorsMessage] = useState(undefined);
  const [reply, setReply] = useState(replySaved);
  const [isSendReplyDisabled, setIsSendReplyDisabled] = useState(true);

  useEffect(() => {
    if (replySaved) {
      setIsSendReplyDisabled(false);
    }
  }, [replySaved]);

  useEffect(() => {
    !replySaved &&
      !isSendReplyDisabled &&
      GtmManager.dataLayer({
        dataLayer: {
          event: 'start_to_fill_in_support_request_reply',
        },
        dataLayerName: 'MyAccount',
      });
  }, [isSendReplyDisabled]);

  useEffect(() => {
    if (isReplyError === false) {
      dispatch({
        type: ENotificationType.Success,
        primaryMessage: '<b>Reply sent</b><p>To add more information, send a reply at any time.</p>',
        dismissAfter: 24 * 3600 * 1000,
      });
    } else if (isReplyError === true) {
      dispatch({
        type: ENotificationType.Danger,
        primaryMessage: '<b>We ran into an issue and your reply didn’t send</b><p>Try again.</p>',
        dismissAfter: 24 * 3600 * 1000,
      });
    } else {
      dispatch({
        type: ENotificationType.Clear,
      });
    }
  }, [isReplyError]);

  const handleReply = async () => {
    const replyBody = {
      reply: reply.trim(),
      caseId: caseId,
    };
    dispatch({
      type: ENotificationType.Clear,
    });
    dispatch(saveReply(reply));
    GtmManager.dataLayer({
      dataLayer: {
        event: 'reply_support_request',
      },
      dataLayerName: 'MyAccount',
    });
    await dispatch(replyRequest(replyBody));
  };

  const handleChange = (event) => {
    const reply = event.target.value;
    setIsSendReplyDisabled(false);
    setReply(reply);
    validateReply(reply);
  };

  const handleBlur = (event) => {
    validateReply(event.target.value);
  };

  const validateReply = (reply) => {
    if (!reply) {
      setErrorsMessage('Reply is required.');
      return;
    }
    setErrorsMessage('');
  };

  return (
    <ReplyBoxWrapper>
      <div className="text-box">
        <TextArea
          label="Still having problems with this case? *"
          name="reply"
          rows={3}
          value={reply}
          errorMessage={errorsMessage}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={3000}
        />
      </div>

      <div className="security-reminder">Don&apos;t include your password or payment details in your message.</div>

      <ButtonRow className="btn-row">
        <Button label="Send reply" disabled={isSendReplyDisabled} onClick={handleReply}>
          Send reply
        </Button>
      </ButtonRow>
    </ReplyBoxWrapper>
  );
};
