export enum SUPPORT_CATEGORY {
  BILLING = 'Billing',
  TWO_FA = '2FA',
  PAYROLL = 'Payroll',
  ACCOUNT_AND_SUBSCRIPTION = 'Account management',
  BANK_FEEDS = 'Bank feeds',
  USING_MY_SOFTWARE = 'Access or setup',
  BANKING = 'Banking',
  INVOICING_AND_BILLS = 'Invoicing & Bills',
  INVENTORY = 'Inventory',
  REPORTING = 'Reporting',
  LOANS_AND_FINANCE = 'Financial services',
  COMPLAINTS = 'Complaints',
  API_DEVELOPER_SUPPORT = 'API developer',
}

export enum SUPPORT_2FA_SUBCATEGORY {
  RESET = 'I have a new phone and need to reset 2FA',
  NOT_GET_OR_NOT_WORK = `I didn't get a code or my code doesn't work`,
  SETTING_OR_CHANGING = 'Setting up or changing 2FA',
}

export enum ACCOUNT_AND_SUBSCRIPTION_SUBCATEGORY {
  CHANGE = 'Change my subscription',
  CHANGE_ACCOUNT_OR_CONTACT_DETAIL = 'Change account or contact details',
  CANCEL = 'Cancel my subscription',
  ADD_OR_REMOVE_USER = 'Add or remove users',
  CLOSE_OR_SELLING = 'Closing or selling my business',
  Question = 'Questions about my subscription',
}

export enum PAYROLL_SUBCATEGORY {
  PROCESSING = 'Processing a pay run',
  ANNUAL_LEAVE_CALCULATIONS = 'Annual leave calculations query',
  TERMINATION_PAYMENTS = 'Termination payments',
  REPORTING_AND_STP = 'Payroll reporting and Single Touch Payroll (STP)',
  ERROR_MESSAGE = `I’m getting an error message`,
  SUPERANNUATION = 'Superannuation',
  CHANGE_MY_PAY_SUPER_AUTHORISER = 'Change my Pay Super authoriser',
  CHANGE_OR_INCREASE_MY_SUPER_LIMIT_OR_AUTHORISER = 'Change or increase my super limit',
  PAYITEMS = 'Pay items',
  SETTING_UP_FLARE = 'Setting up Flare',
  CREATING_EMPLOYEES = 'Creating or editing employee details',
  PAYSLIPS = 'Payslips',
  TIMESHEETS = 'Timesheets',
}

export enum USING_MY_SOFTWARE_SUBCATEGORY {
  ACTIVATE_FILE = 'Activate or confirm a file',
  CAN_NOT_ACCESS = `I can't access my file`,
  LOCAL_INSTALL = 'Local install',
  INVITE_ADVISOR = 'Invite advisor',
  INVITE_USER = 'Invite user',
  DATA = 'Importing or exporting data',
  LINKED_ACCOUNTS = 'Linked accounts',
  INVOICE_TEMPLATES = 'Invoice templates',
  ARL_BACKUP_REQUEST = 'ARL backup request',
}

export enum API_DEVELOPER_SUPPORT_SUBCATEGORY {
  BUSINESS_API_SUPPORT = `MYOB Business API support`,
  BUSINESS_API_DEVELOPER_APP = 'MYOB Business API developer application',
  EXO_API_SUPPORT = 'MYOB EXO API developer support',
  EXO_API_DEVELOPER_APP = 'MYOB EXO API developer application',
}

export enum BANKING_SUBCATEGORY {
  RECONCILING_MY_ACCOUNT = 'Reconciling my accounts',
  MISSING_TRANSACTION = 'Missing transactions',
  CATEGORISING_TRANSACTION = 'Categorising transactions',
  ELECTRONIC_PAYMENTS = 'Electronic payments',
  GETTING_ERROR_MESSAGE = `I’m getting an error message`,
  BANK_RULES = 'Bank rules',
  RECURRING_TRANSACTION = 'Recurring transactions',
  RECORD_INCOME_OUTCOME = 'Record income/expense',
  BUSINESS_SETTINGS = 'Business settings',
  CREATE_GENERAL_JOURNAL = 'Creating a general journal',
}

export enum BANK_FEEDS_SUBCATEGORY {
  BLOCK_MISSING_BANK_FEEDS = 'Blocked or missing bank feeds',
  DUPLICATE_FEED = 'Duplicate feeds',
  APPLICATION_ENQUIRY = 'Application enquiry',
  MOVING_BANK_FEEDS = 'Moving bank feeds to another file or account',
}

export enum LOANS_AND_FINANCE_SUBCATEGORY {
  OIP = 'Online invoice payments (OIP)',
  BUT_VARIANT = 'Butn and Valiant',
  DIRECT_ELECTRONIC_PAYMENT = 'Direct electronic payments',
  M_POWER = 'M-Powered Services',
  PAY_AGENT = 'PayAgent',
}

export enum REPORTING_SUBCATEGORY {
  BAS_EGST = 'BAS or eGST',
  END_FINANCIAL_YEAR_TASKS = 'End of financial year tasks',
  EXCEPTIONS_DASHBOARD = 'Exceptions dashboard',
  ERROR_MESSAGE = `I’m getting an error message`,
  IMPORT_EXPORT_DATA = 'Importing or exporting data',
  BUSINESS_REPORT = 'Business reports',
  PAYROLL_REPORT = 'Payroll reporting',
  SALE_REPORT = 'Sales reports',
  OIP = 'Online invoice payments (OIP)',
  BANKING_REPORT = 'Banking reports',
  BILL_REPORT = 'Bills reports',
  INVENTORY_REPORT = 'Inventory reports',
  CUSTOMER_REPORT = 'Customer reports',
  JOB_REPORT = 'Jobs reports',
  FOREIGN_CURRENCY = 'Foreign currency',
}

export enum INVOICING_AND_BILLS_SUBCATEGORY {
  QUOTE_SALE_ORDER_PAYMENTS_CUSTOMER = 'Quotes, sales orders, payments and customer returns',
  PURCHASE_ORDER_BILL_PAYMENT_SUPPLIER = 'Purchase orders, bills, payments and supplier returns',
  IN_TRAY = 'In tray',
  ERROR_MESSAGE = `I’m getting an error message`,
  PAYMENT_TERMS_EMAIL = 'Payment terms, email defaults and reminders',
  E_INVOICE = 'E invoicing',
}
export enum INVENTORY_SUBCATEGORY {
  INVENTORY_ADJUSTMENT = 'Inventory adjustment',
  ITEM_SETUP = 'Item set up',
  ERROR_MESSAGE = `I’m getting an error message`,
  REORDER_LOW_STOCK_ITEMS = 'Reorder low stock items',
}
export enum COMPLAINTS_SUBCATEGORY {
  NOT_HAPPY_WITH_BILL = 'I’m not happy with my bill',
  NOT_HAPPY_WITH_SOFTWARE = 'I’m not happy with my software',
  NOT_HAPPY_WITH_MYOB = 'I’m not happy with the service I received from MYOB',
}

export enum BILLING_SUBCATEGORY {
  MYOB_BILL = 'My MYOB bill',
  SUBSCRIPTION_ISSUE = 'My subscription is suspended, cancelled, read-only',
  WRONG_BILL = 'My MYOB bill is wrong',
  MYOB_PAYMENT_FAILED = 'My MYOB payment failed',
  FINANCIAL_HARDSHIP = 'Financial hardship',
}

export const CATEGORY_MAPPING = {
  [SUPPORT_CATEGORY.BILLING]: Object.values(BILLING_SUBCATEGORY),
  [SUPPORT_CATEGORY.TWO_FA]: Object.values(SUPPORT_2FA_SUBCATEGORY),
  [SUPPORT_CATEGORY.PAYROLL]: Object.values(PAYROLL_SUBCATEGORY),
  [SUPPORT_CATEGORY.ACCOUNT_AND_SUBSCRIPTION]: Object.values(ACCOUNT_AND_SUBSCRIPTION_SUBCATEGORY),
  [SUPPORT_CATEGORY.BANK_FEEDS]: Object.values(BANK_FEEDS_SUBCATEGORY),
  [SUPPORT_CATEGORY.USING_MY_SOFTWARE]: Object.values(USING_MY_SOFTWARE_SUBCATEGORY),
  [SUPPORT_CATEGORY.BANKING]: Object.values(BANKING_SUBCATEGORY),
  [SUPPORT_CATEGORY.INVOICING_AND_BILLS]: Object.values(INVOICING_AND_BILLS_SUBCATEGORY),
  [SUPPORT_CATEGORY.INVENTORY]: Object.values(INVENTORY_SUBCATEGORY),
  [SUPPORT_CATEGORY.REPORTING]: Object.values(REPORTING_SUBCATEGORY),
  [SUPPORT_CATEGORY.LOANS_AND_FINANCE]: Object.values(LOANS_AND_FINANCE_SUBCATEGORY),
  [SUPPORT_CATEGORY.COMPLAINTS]: Object.values(COMPLAINTS_SUBCATEGORY),
  [SUPPORT_CATEGORY.API_DEVELOPER_SUPPORT]: Object.values(API_DEVELOPER_SUPPORT_SUBCATEGORY),
};

const MISSING_FEATURE = `It's missing features or functionality I need`;
const CANNOT_INTEGRATE = 'It cannot integrate with other software I use';

export const CANCELLATION_REASON_MAPPING_ENHANCE = [
  `It was too hard to use`,
  `I'm no longer in business`,
  `It's too expensive`,
  MISSING_FEATURE,
  `I had issues with support`,
  CANNOT_INTEGRATE,
  `I have another product with MYOB`,
];

export const CANCELLATION_REASON_MAPPING_ENHANCE_DESC = [MISSING_FEATURE, CANNOT_INTEGRATE, 'Other'];

export type SupportFormProperties = {
  [key: string]: SupportFormRules;
};

export type PremiumSupportBookingFormProperties = {
  [key: string]: PremiumBookingFormRule;
};

export type SupportFormRules = {
  requiredLabel?: string;
  length?: number;
  maxLength?: number;
  errorMessage?: string;
};

export type PremiumBookingFormRule = SupportFormRules & {
  phoneNumberFormat?: string;
};

export const SUPPORT_FORM_PROPERTIES: SupportFormProperties = {
  serialNumber: {
    length: 12,
    errorMessage: 'Serial number must be 12 numbers.',
  },
  subject: {
    requiredLabel: 'This field is required',
    errorMessage: 'Enter a subject.',
    maxLength: 50,
  },
  category: {
    requiredLabel: 'This field is required',
    errorMessage: 'Choose an option.',
  },
  subCategory: {
    requiredLabel: 'This field is required',
    errorMessage: 'Choose an option.',
  },
  cancellationReason: {
    requiredLabel: '',
    errorMessage: '',
  },
  description: {
    requiredLabel: 'This field is required',
    errorMessage: 'Enter a description of your issue.',
    maxLength: 3000,
  },
};

export const PREMIUM_SUPPORT_BOOKING_FORM_PROPERTIES: PremiumSupportBookingFormProperties = {
  date: {
    requiredLabel: 'This field is required',
    errorMessage: 'Choose an option.',
  },
  availableTime: {
    requiredLabel: 'This field is required',
    errorMessage: 'Choose an option.',
  },
  // serialNumber: {
  //   length: 12,
  //   errorMessage: 'Serial number must be 12 digits.',
  // },
  contactPhoneNumber: {
    requiredLabel: 'This field is required',
    errorMessage: `Phone numbers must start with a plus sign "+" followed by the country code and have at least 10 digits.`,
    maxLength: 15,
    phoneNumberFormat: `Phone numbers must start with a plus sign "+" followed by the country code and have at least 10 digits.`,
  },
  description: {
    requiredLabel: 'This field is required',
    errorMessage: 'Enter a description of your issue.',
    maxLength: 100,
  },
};

export const NO_ACTIVE_SUBSCRIPTION_CATEGORIES = [
  SUPPORT_CATEGORY.BILLING,
  SUPPORT_CATEGORY.ACCOUNT_AND_SUBSCRIPTION,
  SUPPORT_CATEGORY.COMPLAINTS,
];
