import React, { useEffect, useState, useContext, useCallback, useRef, useMemo } from 'react';
import { Alert, Box, Button, ButtonRow, Input, Select, TextArea, Text } from '@myob/myob-widgets';
import {
  CANCELLATION_REASON_MAPPING_ENHANCE,
  CANCELLATION_REASON_MAPPING_ENHANCE_DESC,
  SUPPORT_FORM_PROPERTIES,
  CATEGORY_MAPPING,
  SUPPORT_CATEGORY,
  ACCOUNT_AND_SUBSCRIPTION_SUBCATEGORY,
  PAYROLL_SUBCATEGORY,
  SUPPORT_2FA_SUBCATEGORY,
  API_DEVELOPER_SUPPORT_SUBCATEGORY,
  USING_MY_SOFTWARE_SUBCATEGORY,
  BANK_FEEDS_SUBCATEGORY,
  BILLING_SUBCATEGORY,
  INVOICING_AND_BILLS_SUBCATEGORY,
  LOANS_AND_FINANCE_SUBCATEGORY,
  BANKING_SUBCATEGORY,
  REPORTING_SUBCATEGORY,
  INVENTORY_SUBCATEGORY,
  NO_ACTIVE_SUBSCRIPTION_CATEGORIES,
} from '../constants/constants';
import { SUPPORT_CATEGORY_DISPLAY } from '../constants/CategoriesDisplay';
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account';
import { keys, forIn, values, isEmpty, shuffle } from 'lodash';
import { Region, SupportTicketInfo, UserProfileInfo } from '../type';
import { GtmManager } from '@my-account/tools';
import { useLocation } from 'react-router-dom';
import { navigateToUrl } from 'single-spa';
import { trackSelectedOption } from 'helpers/TelemetryHelper';
import useRegion from 'hooks/useRegion';
import { auConfig, nzConfig } from 'config';
import { AppThunkDispatch, RootState } from '../../stores';
import { useDispatch, useSelector } from 'react-redux';
import { getIsNoActiveSubscriptionAsync, NoActiveSubscriptionState } from '../reducers/getIsNoActiveSubscription';
import { featureFlags } from '../../configs/FeatureFlags';

export const getDisplayValue = (mainCategory: string, category: string): string => {
  const display = SUPPORT_CATEGORY_DISPLAY.find(
    (item) => item.apiValue === category && item.mainCategory === mainCategory,
  );
  return display ? display.displayValue : category;
};
const categoryOptions = () => {
  const options = keys(CATEGORY_MAPPING).map((item) => (
    <Select.Option key={item} value={item} label={getDisplayValue(item, item)} />
  ));
  return (
    <>
      <Select.Option key="placeholder" value="" label="" hidden />
      {options}
    </>
  );
};

const specificCategoryOptions = (specificCategories: SUPPORT_CATEGORY[]) => {
  const options = keys(CATEGORY_MAPPING)
    .filter((item) => specificCategories.includes(item as SUPPORT_CATEGORY))
    .map((item) => <Select.Option key={item} value={item} label={getDisplayValue(item, item)} />);
  return (
    <>
      <Select.Option key="placeholder" value="" label="" hidden />
      {options}
    </>
  );
};

const subCategoryOptions = (category: string) => {
  const options =
    category &&
    CATEGORY_MAPPING[category].map((item) => (
      <Select.Option key={item} value={item} label={getDisplayValue(category, item)} />
    ));
  return (
    <>
      <Select.Option key="placeholder" value="" label="" hidden />
      {options}
    </>
  );
};

const cancellationReasonOptions = () => {
  const options = shuffle(CANCELLATION_REASON_MAPPING_ENHANCE).map((item) => (
    <Select.Option key={item} value={item} label={item} />
  ));
  return (
    <>
      <Select.Option key="placeholder" value="" label="" hidden />
      {options}
      <Select.Option key="other" value="Other" label="Other" />
    </>
  );
};

type SupportFormProps = {
  onSave?: (supportFormData: SupportTicketInfo) => void;
  onCancel?: () => void;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>;

export const SupportForm: React.FC<SupportFormProps> = ({ onSave, onCancel, className }) => {
  const location = useLocation();

  const formDataPreFill = {
    serialNumber: '',
    subject: 'Cancel my subscription',
    category: SUPPORT_CATEGORY.ACCOUNT_AND_SUBSCRIPTION,
    subCategory: ACCOUNT_AND_SUBSCRIPTION_SUBCATEGORY.CANCEL,
    description: '',
    cancellationReason: '',
  };

  const initState = useMemo(() => {
    return {
      formData: {
        serialNumber: '',
        subject: '',
        category: location.state?.category ?? '',
        subCategory: location.state?.subCategory ?? '',
        description: '',
        cancellationReason: '',
      },
      formDataPreFill: formDataPreFill,
      errors: {
        serialNumber: {
          isValid: false,
          value: '',
        },
        subject: {
          isValid: false,
          value: '',
        },
        category: {
          isValid: false,
          value: '',
        },
        subCategory: {
          isValid: false,
          value: '',
        },
        cancellationReason: {
          isValid: false,
          value: '',
        },
        description: {
          isValid: false,
          value: '',
        },
      },
    };
  }, []);

  const {
    selected: selectedAccount,
    error: accountSelectError,
    reason,
  } = useContext<AccountSelectContextValue>(AccountSelectContext);
  const region = useRegion(Region.AU);
  const [supportFormData, setSupportFormData] = useState<SupportTicketInfo>(initState.formData);
  const [errors, setErrors] = useState({ ...initState.errors });
  const [rules, setRules] = useState(SUPPORT_FORM_PROPERTIES);
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [isSendMessageDisabled, setIsSendMessageDisabled] = useState(true);
  const [userProfile] = useState<UserProfileInfo>(JSON.parse(localStorage.getItem('USER_PROFILE')) ?? {});

  const { firstName = '', lastName = '' } = userProfile;
  const fullName = `${firstName} ${lastName}`.trim();

  const isCancel = location.pathname.includes('/cancel');
  const { category, subCategory } = supportFormData;
  const config = region === Region.AU ? auConfig : nzConfig;

  const didMount = useRef(false);
  const dispatch: AppThunkDispatch = useDispatch();
  const [allCategoryOptions, setAllCategoryOptionsState] = useState(categoryOptions());

  const {
    fetch: { data: noActiveSubscriptionData },
  } = useSelector<RootState, NoActiveSubscriptionState>((state) => state.noActiveSubscription);

  useEffect(() => {
    if (featureFlags.isRestrictingNotificationDisplay()) {
      dispatch(getIsNoActiveSubscriptionAsync());
    }
  }, [dispatch]);

  useEffect(() => {
    if (featureFlags.isRestrictingNotificationDisplay() && noActiveSubscriptionData) {
      setAllCategoryOptionsState(specificCategoryOptions(NO_ACTIVE_SUBSCRIPTION_CATEGORIES));
    } else {
      setAllCategoryOptionsState(categoryOptions());
    }
  }, [noActiveSubscriptionData]);

  useEffect(() => {
    /* istanbul ignore next */
    if (didMount.current) {
      if (reason === '') {
        setSupportFormData({ ...initState.formData });
        setErrors({ ...initState.errors });
        setIsSendMessageDisabled(true);
        isCancel && navigateToUrl('/account/support/contact-support');
      }
    } else didMount.current = true;
  }, [selectedAccount, accountSelectError, isCancel, reason]);

  useEffect(() => {
    if (isCancel) {
      const serialNumber = location.state?.serialNumber;
      setSupportFormData({ ...initState.formDataPreFill, serialNumber: serialNumber || '' });
    } else {
      setSupportFormData({ ...initState.formData });
      setErrors({ ...initState.errors });
      setIsSendMessageDisabled(true);
    }
  }, [isCancel]);

  useEffect(() => {
    setDescriptionLength(supportFormData.description.length);
  }, [supportFormData.description]);

  useEffect(() => {
    if (supportFormData.subCategory === 'Cancel my subscription') {
      setObjectState(setRules, {
        ...rules,
        cancellationReason: {
          requiredLabel: 'This field is required',
          errorMessage: 'Cancellation reason is required.',
        },
      });
    } else {
      setObjectState(setSupportFormData, { cancellationReason: '' });
      setObjectState(setRules, {
        ...rules,
        cancellationReason: {
          requiredLabel: '',
          errorMessage: '',
        },
      });
    }
  }, [supportFormData.subCategory]);

  useEffect(() => {
    !isSendMessageDisabled &&
      GtmManager.dataLayer({
        dataLayer: {
          event: 'start_to_fill_in_support_form',
        },
        dataLayerName: 'MyAccount',
      });
  }, [isSendMessageDisabled]);

  const renderNoActiveSubscriptionAlert = () => {
    if (noActiveSubscriptionData) {
      return (
        <div className="category-alert">
          <span>
            <b>
              Your support options are limited to account enquiries. An active subscription is needed to access full
              support.
            </b>
          </span>
        </div>
      );
    }
  };

  const renderBillingHelpArticle = () => {
    if (category === SUPPORT_CATEGORY.BILLING) {
      if (subCategory === BILLING_SUBCATEGORY.FINANCIAL_HARDSHIP) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Having trouble paying? See our&nbsp;
                <Text
                  as="a"
                  href={config.BILLING_FINANCIAL_HARDSHIP_HELP_LINK}
                  marginBottom="clear"
                  target="_blank"
                  rel="noreferrer"
                >
                  financial hardship policy
                </Text>
                &nbsp;to find out how we can help.
              </Text>
            </Box>
          </Alert>
        );
      }

      if (subCategory === BILLING_SUBCATEGORY.MYOB_BILL) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Your MYOB bill includes subscription fees and usage charges for premium features, like payroll.
              </Text>
              <Text
                as="a"
                href={config.BILLING_UNDERSTAND_HELP_LINK}
                marginBottom="clear"
                target="_blank"
                rel="noreferrer"
              >
                See an explanation of the types of things that might appear on your bill
              </Text>
              .
            </Box>
          </Alert>
        );
      }

      if (subCategory === BILLING_SUBCATEGORY.WRONG_BILL) {
        return (
          <Alert tone="info">
            <Box>
              <Text
                as="a"
                href={config.BILLING_WRONG_BILL_CURRENT_HELP_LINK}
                marginBottom="clear"
                target="_blank"
                rel="noreferrer"
              >
                Check what's included in your current plan
              </Text>
              &nbsp;and&nbsp;
              <Text
                as="a"
                href={config.BILLING_WRONG_BILL_EXPLANATION_HELP_LINK}
                marginBottom="clear"
                target="_blank"
                rel="noreferrer"
              >
                see an explanation of what's on your bill
              </Text>
              .
            </Box>
          </Alert>
        );
      }

      if (subCategory === BILLING_SUBCATEGORY.MYOB_PAYMENT_FAILED) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Reattempt a failed payment on your latest bill by clicking&nbsp;
                <Text as="strong">Pay now</Text> on the&nbsp;
                <Text
                  as="a"
                  href={config.BILLING_FAILED_PAYMENT_BILL_PAGE_HELP_LINK}
                  marginBottom="clear"
                  target="_blank"
                  rel="noreferrer"
                >
                  Bills page in My Account
                </Text>
                .
              </Text>
              <Text marginBottom="clear">
                If you're the primary contact, you can update an expired credit card by going to&nbsp;
                <Text
                  as="a"
                  href={config.BILLING_FAILED_PAYMENT_DETAIL_HELP_LINK}
                  marginBottom="clear"
                  target="_blank"
                  rel="noreferrer"
                >
                  Billing &gt; Payment details in My Account
                </Text>
                .
              </Text>
            </Box>
          </Alert>
        );
      }

      if (subCategory === BILLING_SUBCATEGORY.SUBSCRIPTION_ISSUE) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                If you cancelled your subscription within the last 60 days,&nbsp;
                <Text
                  as="a"
                  href={config.BILLING_SUSPENDED_CANCELED_HELP_LINK}
                  marginBottom="clear"
                  target="_blank"
                  rel="noreferrer"
                >
                  go here to resubscribe
                </Text>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
    }
  };

  const render2FAHelpArticle = () => {
    if (category === SUPPORT_CATEGORY.TWO_FA) {
      if (subCategory === SUPPORT_2FA_SUBCATEGORY.NOT_GET_OR_NOT_WORK) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Try these{' '}
                <a href={config.TWOFA_NOT_GET_OR_NOT_WORK_HELP_LINK} target="_blank" rel="noreferrer">
                  troubleshooting tips
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === SUPPORT_2FA_SUBCATEGORY.RESET) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Go to
                <a href={config.ACCOUNT_SECURITY_LINK} target="_blank" rel="noreferrer">
                  {' '}
                  Account security
                </a>{' '}
                and reset the 2FA methods you need to change.{' '}
                <a href={config.TWOFA_RESET_HELP_LINK} target="_blank" rel="noreferrer">
                  Here's all the details
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === SUPPORT_2FA_SUBCATEGORY.SETTING_OR_CHANGING) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Go to{' '}
                <a href={config.ACCOUNT_SECURITY_LINK} target="_blank" rel="noreferrer">
                  Account security
                </a>{' '}
                and add the 2FA method you want to set up or reset the 2FA methods you need to change.{' '}
                <a href={config.TWOFA_SETTING_OR_CHANGING_HELP_LINK} target="_blank" rel="noreferrer">
                  Here's all the details
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
    }
  };
  const renderAPIDeveloperHelpArticle = () => {
    if (category === SUPPORT_CATEGORY.API_DEVELOPER_SUPPORT) {
      if (subCategory === API_DEVELOPER_SUPPORT_SUBCATEGORY.BUSINESS_API_DEVELOPER_APP) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Complete and submit{' '}
                <a href={config.API_DEVELOPER_BUSINESS_API_DEVELOPER_APP_HELP_LINK} target="_blank" rel="noreferrer">
                  this application form
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === API_DEVELOPER_SUPPORT_SUBCATEGORY.BUSINESS_API_SUPPORT) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Complete and submit{' '}
                <a href={config.API_DEVELOPER_BUSINESS_API_SUPPORT_HELP_LINK} target="_blank" rel="noreferrer">
                  this support request
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === API_DEVELOPER_SUPPORT_SUBCATEGORY.EXO_API_DEVELOPER_APP) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Complete and submit{' '}
                <a href={config.API_DEVELOPER_EXO_API_DEVELOPER_APP_HELP_LINK} target="_blank" rel="noreferrer">
                  this application form
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === API_DEVELOPER_SUPPORT_SUBCATEGORY.EXO_API_SUPPORT) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                {' '}
                <a href={config.API_DEVELOPER_EXO_API_SUPPORT_HELP_LINK} target="_blank" rel="noreferrer">
                  {' '}
                  Search the knowledge base for your issue
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
    }
  };
  const renderAccessOrSetupHelpArticle = () => {
    if (category === SUPPORT_CATEGORY.USING_MY_SOFTWARE) {
      if (subCategory === USING_MY_SOFTWARE_SUBCATEGORY.ARL_BACKUP_REQUEST) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                In some cases, we can retrieve a backup copy of your company file (fees may apply). You'll need to call
                us.{' '}
                <a href={config.ACCESS_OR_SETUP_ARL_BACKUP_REQUEST_HELP_LINK} target="_blank" rel="noreferrer">
                  See the details here
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === USING_MY_SOFTWARE_SUBCATEGORY.ACTIVATE_FILE) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Getting an error when activating or confirming?{' '}
                <a href={config.ACCESS_OR_SETUP_ACTIVATE_FILE_HELP_LINK} target="_blank" rel="noreferrer">
                  Here's some things you can try
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === USING_MY_SOFTWARE_SUBCATEGORY.CAN_NOT_ACCESS) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Can't sign in?{' '}
                <a href={config.ACCESS_OR_SETUP_CAN_NOT_ACCESS_HELP_LINK} target="_blank" rel="noreferrer">
                  Here's some things you can try
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === USING_MY_SOFTWARE_SUBCATEGORY.DATA) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">Use import templates to make sure your import goes smoothly. </Text>
              <Text marginBottom="clear">
                <a href={config.ACCESS_OR_SETUP_DATA_HELP_LINK} target="_blank" rel="noreferrer">
                  More about importing and exporting
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === USING_MY_SOFTWARE_SUBCATEGORY.INVITE_ADVISOR) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">Only business Owners with the Admin role can invite an advisor. </Text>
              <Text marginBottom="clear">
                Advisors must be MYOB Partners. If they're not, invite them as a regular user. See how:{' '}
                <a href={config.ACCESS_OR_SETUP_INVITE_ADVISOR_BROWSER_HELP_LINK} target="_blank" rel="noreferrer">
                  Browser
                </a>{' '}
                |{' '}
                <a href={config.ACCESS_OR_SETUP_INVITE_ADVISOR_DESKTOP_HELP_LINK} target="_blank" rel="noreferrer">
                  Desktop
                </a>
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === USING_MY_SOFTWARE_SUBCATEGORY.INVITE_USER) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Only Administrators can invite users. See how:{' '}
                <a href={config.ACCESS_OR_SETUP_INVITE_USER_BROWSER_HELP_LINK} target="_blank" rel="noreferrer">
                  Browser
                </a>{' '}
                |{' '}
                <a href={config.ACCESS_OR_SETUP_INVITE_USER_DESKTOP_HELP_LINK} target="_blank" rel="noreferrer">
                  Desktop{' '}
                </a>
              </Text>
              <Text marginBottom="clear">
                Need to change a user's access?{' '}
                <a href={config.MANAGE_USER_HELP_LINK} target="_blank" rel="noreferrer">
                  More about managing users
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === USING_MY_SOFTWARE_SUBCATEGORY.INVOICE_TEMPLATES) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Personalise your invoice templates to show your business logo and details.{' '}
                <a href={config.ACCESS_OR_SETUP_INVOICE_TEMPLATES_HELP_LINK} target="_blank" rel="noreferrer">
                  See how
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === USING_MY_SOFTWARE_SUBCATEGORY.LINKED_ACCOUNTS) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                <a href={config.ACCESS_OR_SETUP_LINKED_ACCOUNTS_HELP_LINK} target="_blank" rel="noreferrer">
                  See how linked accounts (called 'linked categories' in MYOB Business) work
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }

      if (subCategory === USING_MY_SOFTWARE_SUBCATEGORY.LOCAL_INSTALL) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                See how to{' '}
                <a href={config.ACCESS_OR_SETUP_LOCAL_INSTALL_HELP_LINK} target="_blank" rel="noreferrer">
                  install AccountRight
                </a>{' '}
                or{' '}
                <a href={config.ACCESS_OR_SETUP_LOCAL_INSTALL_NEW_COMPUTER_HELP_LINK} target="_blank" rel="noreferrer">
                  move AccountRight to a new computer
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
    }
  };
  const renderAccountManagementHelpArticle = () => {
    if (category === SUPPORT_CATEGORY.ACCOUNT_AND_SUBSCRIPTION) {
      if (subCategory === ACCOUNT_AND_SUBSCRIPTION_SUBCATEGORY.ADD_OR_REMOVE_USER) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Only Administrators can invite users. See how:{' '}
                <a
                  href={config.ACCOUNT_AND_SUBSCRIPTION_ADD_OR_REMOVE_USER_BROWSER_HELP_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  Browser
                </a>{' '}
                |{' '}
                <a
                  href={config.ACCOUNT_AND_SUBSCRIPTION_ADD_OR_REMOVE_USER_DESKTOP_HELP_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  Desktop
                </a>{' '}
              </Text>
              <Text marginBottom="clear">
                Need to remove a user's access?{' '}
                <a href={config.MANAGE_USER_HELP_LINK} target="_blank" rel="noreferrer">
                  More about managing users
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === ACCOUNT_AND_SUBSCRIPTION_SUBCATEGORY.CANCEL) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Go to{' '}
                <a href={config.MANAGE_MY_PRODUCTS_HELP_LINK} target="_blank" rel="noreferrer">
                  Manage my product
                </a>
                , click the product you want to cancel and click <b>Cancel my subscription</b>.{' '}
                <a href={config.ACCOUNT_AND_SUBSCRIPTION_CANCEL_HELP_LINK} target="_blank" rel="noreferrer">
                  Here's the details
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === ACCOUNT_AND_SUBSCRIPTION_SUBCATEGORY.CHANGE_ACCOUNT_OR_CONTACT_DETAIL) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Go to{' '}
                <a href={config.ACCOUNT_DETAILS_HELP_LINK} target="_blank" rel="noreferrer">
                  Account details
                </a>{' '}
                or{' '}
                <a href={config.CONTACT_DETAILS_HELP_LINK} target="_blank" rel="noreferrer">
                  Contact details
                </a>{' '}
                to make your changes.{' '}
                <a
                  href={config.ACCOUNT_AND_SUBSCRIPTION_CHANGE_ACCOUNT_OR_CONTACT_DETAIL_HELP_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  Want to change something else or need help?
                </a>
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === ACCOUNT_AND_SUBSCRIPTION_SUBCATEGORY.CHANGE) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Go to{' '}
                <a href={config.MANAGE_MY_PRODUCTS_HELP_LINK} target="_blank" rel="noreferrer">
                  Manage my product
                </a>
                , click the product you want to change and change your plan.{' '}
                <a href={config.ACCOUNT_AND_SUBSCRIPTION_CHANGE_HELP_LINK} target="_blank" rel="noreferrer">
                  Here's the details
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === ACCOUNT_AND_SUBSCRIPTION_SUBCATEGORY.CLOSE_OR_SELLING) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Update or transfer the registered licence for your MYOB software by filling in a change of details form
                (
                <a
                  href={config.ACCOUNT_AND_SUBSCRIPTION_CLOSE_OR_SELLING_AU_HELP_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  Australia
                </a>{' '}
                |{' '}
                <a
                  href={config.ACCOUNT_AND_SUBSCRIPTION_CLOSE_OR_SELLING_NZ_HELP_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  New Zealand
                </a>
                ).{' '}
                <Text marginBottom="clear">
                  <a href={config.ACCOUNT_AND_SUBSCRIPTION_CLOSE_OR_SELLING_HELP_LINK} target="_blank" rel="noreferrer">
                    Here's the details
                  </a>
                  .
                </Text>
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === ACCOUNT_AND_SUBSCRIPTION_SUBCATEGORY.Question) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Check{' '}
                <a href={config.MANAGE_MY_PRODUCTS_HELP_LINK} target="_blank" rel="noreferrer">
                  what's included in your current plan
                </a>{' '}
                and{' '}
                <a href={config.ACCOUNT_AND_SUBSCRIPTION_QUESTION_HELP_LINK} target="_blank" rel="noreferrer">
                  see an explanation of what's on your bill
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
    }
  };
  const renderBankfeedsHelpArticle = () => {
    if (category === SUPPORT_CATEGORY.BANK_FEEDS) {
      if (subCategory === BANK_FEEDS_SUBCATEGORY.APPLICATION_ENQUIRY) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                You can add and remove bank feeds from within your software.{' '}
                <a href={config.BANK_FEEDS_APPLICATION_ENQUIRY_HELP_LINK} target="_blank" rel="noreferrer">
                  See how
                </a>
                .{' '}
              </Text>
              <Text marginBottom="clear">
                Check bank feed statuses on the <b>Manage bank accounts page</b>.{' '}
                <a href={config.BANK_FEEDS_APPLICATION_ENQUIRY_STATUS_HELP_LINK} target="_blank" rel="noreferrer">
                  Here's what each status means
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === BANK_FEEDS_SUBCATEGORY.BLOCK_MISSING_BANK_FEEDS) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Missing transactions in your bank feed?{' '}
                <a href={config.BANK_FEEDS_BLOCK_MISSING_HELP_LINK} target="_blank" rel="noreferrer">
                  Here's some things to try
                </a>
                .
              </Text>
              <Text marginBottom="clear">
                Bank feed stopped working? There might be an outage.{' '}
                <a href={config.STATUS_HELP_LINK} target="_blank" rel="noreferrer">
                  See our status page
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === BANK_FEEDS_SUBCATEGORY.DUPLICATE_FEED) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Imported duplicate transactions from a bank statement? You'll need to{' '}
                <a href={config.BANK_FEEDS_DUPLICATE_FEED_HELP_LINK} target="_blank" rel="noreferrer">
                  delete the imported statement
                </a>
                . Getting duplicate transactions from your bank? Give us the details below and we'll look into it.
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === BANK_FEEDS_SUBCATEGORY.MOVING_BANK_FEEDS) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                To change the bank or credit card account you're receiving bank feeds from,{' '}
                <a href={config.BANK_FEEDS_MOVING_BANK_FEEDS_HELP_LINK} target="_blank" rel="noreferrer">
                  cancel the old bank feed and set up a new one
                </a>
                .
              </Text>
              <Text marginBottom="clear">
                To move a bank feed to a different subscription, let us know which bank feed to move, and the serial
                number of the file you want to move it to.{' '}
              </Text>
            </Box>
          </Alert>
        );
      }
    }
  };

  const renderPayrollHelpArticle = () => {
    if (category === SUPPORT_CATEGORY.PAYROLL) {
      if (subCategory === PAYROLL_SUBCATEGORY.ANNUAL_LEAVE_CALCULATIONS) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Need to set up or adjust an employee's leave?{' '}
                <a href={config.PAYROLL_ANNUAL_LEAVE_CALCULATIONS_HELP_LINK} target="_blank" rel="noreferrer">
                  Here's how to do it
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === PAYROLL_SUBCATEGORY.CHANGE_MY_PAY_SUPER_AUTHORISER) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                If you're the Pay Super Authoriser, you can set up or change Authorisers in MYOB.{' '}
                <a href={config.PAYROLL_CHANGE_MY_PAY_SUPER_AUTHORISER_HELP_LINK} target="_blank" rel="noreferrer">
                  Find out how
                </a>
                .
              </Text>
              <Text marginBottom="clear">
                If you don't have a Pay Super Authoriser in your business,{' '}
                <a href={config.PAYROLL_CHANGE_MY_PAY_SUPER_DETAIL_HELP_LINK} target="_blank" rel="noreferrer">
                  submit a request form to create one
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === PAYROLL_SUBCATEGORY.CHANGE_OR_INCREASE_MY_SUPER_LIMIT_OR_AUTHORISER) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                A Pay Super Authoriser can increase the limit by up to $15,000 over a quarter in your Pay Super settings
                in MYOB.{' '}
                <a
                  href={config.PAYROLL_CHANGE_OR_INCREASE_MY_SUPER_LIMIT_OR_AUTHORISER_HELP_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  See how
                </a>
                . Otherwise, you'll need to{' '}
                <a href={config.PAYROLL_CHANGE_MY_PAY_SUPER_DETAIL_HELP_LINK} target="_blank" rel="noreferrer">
                  submit a request form
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === PAYROLL_SUBCATEGORY.CREATING_EMPLOYEES) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                <a href={config.PAYROLL_CREATING_EMPLOYEES_CHECK_DETAIL_HELP_LINK} target="_blank" rel="noreferrer">
                  Send a request
                </a>{' '}
                to the employee to check or update their own personal or contact details directly (AU only).
              </Text>
              <Text marginBottom="clear">
                Change other details and pay setup{' '}
                <a href={config.PAYROLL_CREATING_EMPLOYEES_HELP_LINK} target="_blank" rel="noreferrer">
                  in the employee's record
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === PAYROLL_SUBCATEGORY.ERROR_MESSAGE) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                See how to fix common{' '}
                <a href={config.PAYROLL_ERROR_MESSAGE_STP_HELP_LINK} target="_blank" rel="noreferrer">
                  STP errors
                </a>{' '}
                and{' '}
                <a href={config.PAYROLL_ERROR_MESSAGE_HELP_LINK} target="_blank" rel="noreferrer">
                  Payday filing errors
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === PAYROLL_SUBCATEGORY.PAYITEMS) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Use pay items or payroll categories to set up the different parts of an employee's pay, like wages,
                leave and deductions.{' '}
                <a href={config.PAYROLL_PAYITEMS_HELP_LINK} target="_blank" rel="noreferrer">
                  See how
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === PAYROLL_SUBCATEGORY.REPORTING_AND_STP) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Find out how to report your payroll info via{' '}
                <a href={config.PAYROLL_REPORTING_AND_STP_STP_REPORT_HELP_LINK} target="_blank" rel="noreferrer">
                  Single Touch Payroll
                </a>{' '}
                or{' '}
                <a href={config.PAYROLL_REPORTING_AND_STP_PAYDAY_REPORT_HELP_LINK} target="_blank" rel="noreferrer">
                  payday filing
                </a>
                .
              </Text>
              <Text marginBottom="clear">
                See how to fix{' '}
                <a href={config.PAYROLL_REPORTING_AND_STP_STP_FIX_HELP_LINK} target="_blank" rel="noreferrer">
                  common STP errors
                </a>{' '}
                or{' '}
                <a href={config.PAYROLL_REPORTING_AND_STP_PAYDAY_FIX_HELP_LINK} target="_blank" rel="noreferrer">
                  Payday filing errors
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === PAYROLL_SUBCATEGORY.PAYSLIPS) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Need to reprint or resend a pay slip?{' '}
                <a href={config.PAYROLL_PAYSLIPS_REPRINT_RESEND_HELP_LINK} target="_blank" rel="noreferrer">
                  Here's how
                </a>
                .
              </Text>
              <Text marginBottom="clear">
                (AU only){' '}
                <a href={config.PAYROLL_PAYSLIPS_ADD_LOGO_HELP_LINK} target="_blank" rel="noreferrer">
                  Add a logo
                </a>{' '}
                to pay slips using the browser or{' '}
                <a href={config.PAYROLL_PAYSLIPS_CUSTOMISE_HELP_LINK} target="_blank" rel="noreferrer">
                  customise them fully
                </a>{' '}
                using AccountRight.
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === PAYROLL_SUBCATEGORY.PROCESSING) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                See{' '}
                <a href={config.PAYROLL_PROCESSING_HELP_LINK} target="_blank" rel="noreferrer">
                  how to do a pay run
                </a>
                .
              </Text>
              <Text marginBottom="clear">
                Learn how to fix a pay{' '}
                <a href={config.PAYROLL_PROCESSING_FIX_AU_HELP_LINK} target="_blank" rel="noreferrer">
                  Australia
                </a>{' '}
                |{' '}
                <a href={config.PAYROLL_PROCESSING_FIX_NZ_HELP_LINK} target="_blank" rel="noreferrer">
                  New Zealand
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === PAYROLL_SUBCATEGORY.SETTING_UP_FLARE) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Get your employees to submit their personal, banking, tax and super details straight into MYOB.{' '}
                <a href={config.PAYROLL_SETTING_UP_FLARE_HELP_LINK} target="_blank" rel="noreferrer">
                  See how
                </a>
                .
              </Text>
              <Text marginBottom="clear">
                <a href={config.PAYROLL_SETTING_UP_FLARE_BENEFIT_HELP_LINK} target="_blank" rel="noreferrer">
                  Set up employee benefits
                </a>{' '}
                to reward your employees and keep them engaged.
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === PAYROLL_SUBCATEGORY.SUPERANNUATION) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                See how to set up{' '}
                <a href={config.PAYROLL_SUPERANNUATION_HELP_LINK} target="_blank" rel="noreferrer">
                  Superannuation
                </a>{' '}
                or{' '}
                <a href={config.PAYROLL_KIWISAVER_HELP_LINK} target="_blank" rel="noreferrer">
                  KiwiSaver
                </a>{' '}
                for your employees.
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === PAYROLL_SUBCATEGORY.TERMINATION_PAYMENTS) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                <a href={config.PAYROLL_TERMINATION_PAYMENTS_HELP_LINK} target="_blank" rel="noreferrer">
                  Learn what to pay a departing employee and how to do it
                </a>
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === PAYROLL_SUBCATEGORY.TIMESHEETS) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                See{' '}
                <a href={config.PAYROLL_TIMESHEETS_HELP_LINK} target="_blank" rel="noreferrer">
                  how you can set up and use employee timesheets
                </a>
                . (Australia only)
              </Text>
              <Text marginBottom="clear">
                <a href={config.PAYROLL_TIMESHEETS_SUBMIT_HELP_LINK} target="_blank" rel="noreferrer">
                  Get employees to submit their own timesheets using MYOB Team
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
    }
  };

  const renderProductsServicesAndInventoryHelpArticle = () => {
    if (category === SUPPORT_CATEGORY.INVENTORY) {
      if (subCategory === INVENTORY_SUBCATEGORY.INVENTORY_ADJUSTMENT) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Need to write off stock or update inventory after a stocktake?{' '}
                <a href={config.INVENTORY_INVENTORY_ADJUSTMENT_HELP_LINK} target="_blank" rel="noreferrer">
                  See how
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === INVENTORY_SUBCATEGORY.ITEM_SETUP) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Add the products and services you buy and sell.{' '}
                <a href={config.INVENTORY_ITEM_SETUP_HELP_LINK} target="_blank" rel="noreferrer">
                  Learn how
                </a>
                .
              </Text>
              <Text marginBottom="clear">
                Need to write off stock or update inventory after a stocktake?{' '}
                <a href={config.INVENTORY_ITEM_SETUP_UPDATE_HELP_LINK} target="_blank" rel="noreferrer">
                  See how
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === INVENTORY_SUBCATEGORY.ERROR_MESSAGE) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Getting an error when importing items?{' '}
                <a href={config.INVENTORY_ERROR_MESSAGE_HELP_LINK} target="_blank" rel="noreferrer">
                  Import again using the browser
                </a>
                . The import file will be checked, and you'll see details of any issues.
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === INVENTORY_SUBCATEGORY.REORDER_LOW_STOCK_ITEMS) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Set up your inventory items in the browser to make reordering easier.{' '}
                <a href={config.INVENTORY_REORDER_LOW_STOCK_ITEMS_HELP_LINK} target="_blank" rel="noreferrer">
                  See how
                </a>
                . The import file will be checked, and you'll see details of any issues.
              </Text>
              <Text marginBottom="clear">
                In AccountRight desktop,{' '}
                <a
                  href={config.INVENTORY_REORDER_LOW_STOCK_ITEMS_TO_DO_LIST_HELP_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  use the To Do List
                </a>{' '}
                to be alerted when you need to reorder.
              </Text>
            </Box>
          </Alert>
        );
      }
    }
  };

  const renderBankingHelpArticle = () => {
    if (category === SUPPORT_CATEGORY.BANKING) {
      if (subCategory === BANKING_SUBCATEGORY.BANK_RULES) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Learn about&nbsp;
                <Text as="a" href={config.BANKING_BANK_RULES_HELP_LINK} target="_blank" rel="noreferrer">
                  setting up and using bank rules
                </Text>
                .
              </Text>
            </Box>
          </Alert>
        );
      }

      if (subCategory === BANKING_SUBCATEGORY.BUSINESS_SETTINGS) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Need to change your financial year? In your browser, go to the settings menu and choose&nbsp;
                <Text as="strong">Business settings</Text>, then click&nbsp;
                <Text as="strong">Start a new financial year.</Text>&nbsp;
                <Text as="a" href={config.BANKING_BUSINESS_SETTING_HELP_LINK} target="_blank" rel="noreferrer">
                  More about updating your business settings
                </Text>
                .
              </Text>
            </Box>
          </Alert>
        );
      }

      if (subCategory === BANKING_SUBCATEGORY.CATEGORISING_TRANSACTION) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                If you've reconciled a transaction, you can't change how it's matched or categorised. You'll need
                to&nbsp;
                <Text
                  as="a"
                  href={config.BANKING_CATEGORISING_TRANSACTION_UNDO_HELP_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  undo the reconciliation
                </Text>
                &nbsp;first.
              </Text>
              <Text>
                More about&nbsp;
                <Text
                  as="a"
                  href={config.BANKING_CATEGORISING_TRANSACTION_MORE_HELP_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  working with bank transactions
                </Text>
                .
              </Text>
            </Box>
          </Alert>
        );
      }

      if (subCategory === BANKING_SUBCATEGORY.CREATE_GENERAL_JOURNAL) {
        return (
          <Alert tone="info">
            <Box>
              <Text
                marginBottom="clear"
                as="a"
                href={config.BANKING_GENERAL_JOURNALS_HELP_LINK}
                target="_blank"
                rel="noreferrer"
              >
                Learn about general journals
              </Text>
            </Box>
          </Alert>
        );
      }

      if (subCategory === BANKING_SUBCATEGORY.ELECTRONIC_PAYMENTS) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                After entering an employee or supplier payment in MYOB, you'll make the payment using a bank file (ABA).
                See how to&nbsp;
                <Text as="a" href={config.BANKING_ELECTRONIC_PAYMENTS_SETUP_HELP_LINK} target="_blank" rel="noreferrer">
                  set up electronic payments and create bank files
                </Text>
                .
              </Text>
              <Text>
                <Text
                  as="a"
                  href={config.BANKING_ELECTRONIC_PAYMENTS_LOOKING_HELP_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  Looking for info about receiving online invoice payments
                </Text>
                ?
              </Text>
            </Box>
          </Alert>
        );
      }

      if (subCategory === BANKING_SUBCATEGORY.GETTING_ERROR_MESSAGE) {
        return (
          <Alert tone="info">
            <Box>
              <Text>
                <Text
                  marginBottom="clear"
                  as="a"
                  href={config.BANKING_GETTING_ERROR_HELP_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  Check if your error is listed and what to do next
                </Text>
                .
              </Text>
            </Box>
          </Alert>
        );
      }

      if (subCategory === BANKING_SUBCATEGORY.MISSING_TRANSACTION) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Missing transactions in your bank feed?&nbsp;
                <Text as="a" href={config.BANKING_MISSING_TRANSACTION_TRY_HELP_LINK} target="_blank" rel="noreferrer">
                  Here's some things to try
                </Text>
                . Bank feed stopped working? There might be an outage.&nbsp;
                <Text
                  as="a"
                  href={config.BANKING_MISSING_TRANSACTION_STATUS_HELP_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  See our status page
                </Text>
                .
              </Text>
            </Box>
          </Alert>
        );
      }

      if (subCategory === BANKING_SUBCATEGORY.RECONCILING_MY_ACCOUNT) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                To&nbsp;
                <Text as="a" href={config.BANKING_RECONCILE_HELP_LINK} target="_blank" rel="noreferrer">
                  fix an out-of-balance reconciliation
                </Text>
                , check for:
                <Text marginBottom="clear">* Data entry errors</Text>
                <Text marginBottom="clear">* Transactions entered twice or not at all</Text>
                <Text marginBottom="clear">* Changes to reconciled transactions</Text>
                <Text marginBottom="clear">* MYOB transactions dated before bank transactions</Text>
              </Text>
            </Box>
          </Alert>
        );
      }

      if (subCategory === BANKING_SUBCATEGORY.RECORD_INCOME_OUTCOME) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Manually record&nbsp;
                <Text as="a" href={config.BANKING_RECORD_SPEND_HELP_LINK} target="_blank" rel="noreferrer">
                  money you spend
                </Text>
                &nbsp;on everyday expenses, like bank charges and office supplies and&nbsp;
                <Text as="a" href={config.BANKING_RECORD_RECEIVE_HELP_LINK} target="_blank" rel="noreferrer">
                  money you receive
                </Text>
                , like bank interest and tax refunds.
              </Text>
            </Box>
          </Alert>
        );
      }

      if (subCategory === BANKING_SUBCATEGORY.RECURRING_TRANSACTION) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                Use recurring transactions to automate any sales, purchases and payments you record regularly, like rent
                invoices or subscription fees.&nbsp;
              </Text>
              <Text as="a" href={config.BANKING_RECURRING_HELP_LINK} target="_blank" rel="noreferrer">
                More about recurring transactions
              </Text>
              .
            </Box>
          </Alert>
        );
      }
    }
  };

  const renderFinancialServicesHelpArticle = () => {
    if (category === SUPPORT_CATEGORY.LOANS_AND_FINANCE) {
      if (subCategory === LOANS_AND_FINANCE_SUBCATEGORY.BUT_VARIANT) {
        return (
          <Alert tone="info">
            <Box>
              <Text
                as="a"
                href={config.FINANCIAL_SERVICE_LOAN_INVOICE_HELP_LINK}
                marginBottom="clear"
                target="_blank"
                rel="noreferrer"
              >
                See how to get funding against your outstanding invoices or get a business loan
              </Text>
              .
            </Box>
          </Alert>
        );
      }

      if (subCategory === LOANS_AND_FINANCE_SUBCATEGORY.OIP) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">Just applied? We'll email you when you've been approved.</Text>
              <Text marginBottom="clear">
                Use the&nbsp;
                <Text
                  as="a"
                  href={config.FINANCIAL_SERVICE_OIP_REPORT_HELP_LINK}
                  marginBottom="clear"
                  target="_blank"
                  rel="noreferrer"
                >
                  Online invoice payments report
                </Text>
                &nbsp;to see details of a payment. Use the transaction description number to identify the payment on
                your bank feed or statement.&nbsp;
                <Text
                  as="a"
                  href={config.FINANCIAL_SERVICE_OIP_MORE_HELP_LINK}
                  marginBottom="clear"
                  target="_blank"
                  rel="noreferrer"
                >
                  More about online invoice payments
                </Text>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
    }
  };

  const renderSaleAndPurchaseHelpArticle = () => {
    if (category === SUPPORT_CATEGORY.INVOICING_AND_BILLS) {
      if (subCategory === INVOICING_AND_BILLS_SUBCATEGORY.E_INVOICE) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                eInvoicing is a&nbsp;
                <Text
                  as="a"
                  href={config.SALE_PURCHASES_AUTOMOTIVE_INVOICING_HELP_LINK}
                  marginBottom="clear"
                  target="_blank"
                  rel="noreferrer"
                >
                  government initiative to automate invoices between businesses
                </Text>
                .
              </Text>
              <Text marginBottom="clear">
                <Text
                  as="a"
                  href={config.SALE_PURCHASES_GET_INVOICING_HELP_LINK}
                  marginBottom="clear"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get invoicing help
                </Text>
                , including emailing invoices, online invoice payments and personalising invoices.
              </Text>
            </Box>
          </Alert>
        );
      }

      if (subCategory === INVOICING_AND_BILLS_SUBCATEGORY.IN_TRAY) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Find your unique email address to upload documents by going to&nbsp;
                <Text as="strong">Uploads &gt;More ways to upload</Text>, or set up Gmail to forward documents
                automatically.&nbsp;
                <Text
                  as="a"
                  href={config.SALE_PURCHASES_UPLOAD_WAYS_HELP_LINK}
                  marginBottom="clear"
                  target="_blank"
                  rel="noreferrer"
                >
                  Here's how.
                </Text>
              </Text>
              <Text
                as="a"
                href={config.SALE_PURCHASES_UPLOAD_MORE_HELP_LINK}
                marginBottom="clear"
                target="_blank"
                rel="noreferrer"
              >
                More about working with uploaded documents
              </Text>
              .
            </Box>
          </Alert>
        );
      }

      if (subCategory === INVOICING_AND_BILLS_SUBCATEGORY.ERROR_MESSAGE) {
        return (
          <Alert tone="info">
            <Box>
              <Text
                as="a"
                href={config.SALE_PURCHASES_GETTING_ERROR_HELP_LINK}
                marginBottom="clear"
                target="_blank"
                rel="noreferrer"
              >
                Check if your error is listed and if there is a way to resolve it
              </Text>
              .
            </Box>
          </Alert>
        );
      }

      if (subCategory === INVOICING_AND_BILLS_SUBCATEGORY.PAYMENT_TERMS_EMAIL) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                If your&nbsp;
                <Text as="strong">Invoice Reminders</Text>
                &nbsp;page is blank in AccountRight, open it in the browser via settings menu &gt;&nbsp;
                <Text as="strong">Sales</Text>
                &nbsp;settings &gt;&nbsp;
                <Text as="strong">Reminders</Text>
                &nbsp;tab &gt;&nbsp;
                <Text as="strong">Reminders settings.</Text>
              </Text>
              <Text marginBottom="clear">
                See how to&nbsp;
                <Text
                  as="a"
                  href={config.SALE_PURCHASES_PAYMENT_SALE_REMINDER_SETTING_HELP_LINK}
                  marginBottom="clear"
                  target="_blank"
                  rel="noreferrer"
                >
                  set up your payment terms
                </Text>
                &nbsp;and&nbsp;
                <Text
                  as="a"
                  href={config.SALE_PURCHASES_PAYMENT_SALE_REMINDER_INVOICE_HELP_LINK}
                  marginBottom="clear"
                  target="_blank"
                  rel="noreferrer"
                >
                  invoice reminders
                </Text>
                .
              </Text>
            </Box>
          </Alert>
        );
      }

      if (subCategory === INVOICING_AND_BILLS_SUBCATEGORY.PURCHASE_ORDER_BILL_PAYMENT_SUPPLIER) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Get help with&nbsp;
                <Text
                  as="a"
                  href={config.SALE_PURCHASES_SUPPLY_ENTERING_HELP_LINK}
                  marginBottom="clear"
                  target="_blank"
                  rel="noreferrer"
                >
                  entering
                </Text>
                &nbsp;and&nbsp;
                <Text
                  as="a"
                  href={config.SALE_PURCHASES_SUPPLY_EDIT_HELP_LINK}
                  marginBottom="clear"
                  target="_blank"
                  rel="noreferrer"
                >
                  editing supplier purchases
                </Text>
                &nbsp;and&nbsp;
                <Text
                  as="a"
                  href={config.SALE_PURCHASES_SUPPLY_RETURN_INVOICE_HELP_LINK}
                  marginBottom="clear"
                  target="_blank"
                  rel="noreferrer"
                >
                  handling returns and supplier debits (credit notes)
                </Text>
                .
              </Text>
              <Text
                as="a"
                href={config.SALE_PURCHASES_SUPPLY_MORE_HELP_LINK}
                marginBottom="clear"
                target="_blank"
                rel="noreferrer"
              >
                More about purchases
              </Text>
              .
            </Box>
          </Alert>
        );
      }

      if (subCategory === INVOICING_AND_BILLS_SUBCATEGORY.QUOTE_SALE_ORDER_PAYMENTS_CUSTOMER) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Customer&nbsp;
                <Text
                  as="a"
                  href={config.SALE_PURCHASES_CUSTOMER_OVERPAID_HELP_LINK}
                  marginBottom="clear"
                  target="_blank"
                  rel="noreferrer"
                >
                  overpaid you
                </Text>
                &nbsp;or&nbsp;
                <Text
                  as="a"
                  href={config.SALE_PURCHASES_CUSTOMER_RETURN_HELP_LINK}
                  marginBottom="clear"
                  target="_blank"
                  rel="noreferrer"
                >
                  making a return
                </Text>
                &nbsp;? Here's how to handle&nbsp;
                <Text
                  as="a"
                  href={config.SALE_PURCHASES_CUSTOMER_REFUND_HELP_LINK}
                  marginBottom="clear"
                  target="_blank"
                  rel="noreferrer"
                >
                  refunds
                </Text>
                &nbsp;and&nbsp;
                <Text
                  as="a"
                  href={config.SALE_PURCHASES_CUSTOMER_CREDIT_HELP_LINK}
                  marginBottom="clear"
                  target="_blank"
                  rel="noreferrer"
                >
                  customer credit notes
                </Text>
                .
              </Text>
              <Text
                as="a"
                href={config.SALE_PURCHASES_CUSTOMER_MORE_HELP_LINK}
                marginBottom="clear"
                target="_blank"
                rel="noreferrer"
              >
                More about sales
              </Text>
              .
            </Box>
          </Alert>
        );
      }
    }
  };
  const renderReportingHelpArticle = () => {
    if (category === SUPPORT_CATEGORY.REPORTING) {
      if (subCategory === REPORTING_SUBCATEGORY.BAS_EGST) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Before you can lodge your BAS, you need to{' '}
                <a href={config.REPORTING_BAS_GST_SET_UP_HELP_LINK} target="_blank" rel="noreferrer">
                  set it up{' '}
                </a>
                (Australia).
              </Text>
              <Text
                as="a"
                href={config.REPORTING_BAS_GST_RETURN_HELP_LINK}
                marginBottom="clear"
                target="_blank"
                rel="noreferrer"
              >
                More about completing and lodging your activity statement/GST return.
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === REPORTING_SUBCATEGORY.BANKING_REPORT) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Need to fix an out-of-balance bank reconciliation?
                <a href={config.REPORTING_BANKING_RECONCILIATION_HELP_LINK} target="_blank" rel="noreferrer">
                  Here's some things to try
                </a>
                .
              </Text>
              <Text marginBottom="clear">
                More info about using, customising, and exporting{' '}
                <a href={config.REPORTING_BANKING_REPORT_HELP_LINK} target="_blank" rel="noreferrer">
                  banking reports
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === REPORTING_SUBCATEGORY.BILL_REPORT) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Use the <b>Unpaid bills</b> report in your browser to see what you owe your suppliers.
              </Text>
              <Text marginBottom="clear">
                More info about{' '}
                <a href={config.REPORTING_BILL_REPORT_HELP_LINK} target="_blank" rel="noreferrer">
                  using purchases reports
                </a>{' '}
                to get a better picture of your suppliers and their bills.
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === REPORTING_SUBCATEGORY.BUSINESS_REPORT) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Use business reports, like{' '}
                <a href={config.REPORTING_BUSINESS_REPORT_PROFIT_LOSS_HELP_LINK} target="_blank" rel="noreferrer">
                  Profit & loss
                </a>{' '}
                and{' '}
                <a href={config.REPORTING_BUSINESS_REPORT_BUDGET_MANAGEMENT_HELP_LINK} target="_blank" rel="noreferrer">
                  Budget management
                </a>{' '}
                to stay on top of your business's performance.
              </Text>
              <Text marginBottom="clear">
                More info about using, customising, and exporting{' '}
                <a href={config.REPORTING_BUSINESS_REPORT_HELP_LINK} target="_blank" rel="noreferrer">
                  business reports
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === REPORTING_SUBCATEGORY.CUSTOMER_REPORT) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Use the <b>Unpaid invoices</b> report in your browser to see which customers owe you money.
              </Text>
              <Text marginBottom="clear">
                More info about{' '}
                <a href={config.REPORTING_CUSTOMER_REPORT_HELP_LINK} target="_blank" rel="noreferrer">
                  using sales reports
                </a>{' '}
                to get a better picture of your customers and their invoices.
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === REPORTING_SUBCATEGORY.END_FINANCIAL_YEAR_TASKS) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                You can only{' '}
                <a href={config.REPORTING_END_FINANCIAL_CHANGE_YEAR_HELP_LINK} target="_blank" rel="noreferrer">
                  change the last month of your financial year
                </a>{' '}
                when you roll over the financial year.
              </Text>
              <Text marginBottom="clear">
                See{' '}
                <a href={config.REPORTING_END_FINANCIAL_TASK_YEAR_HELP_LINK} target="_blank" rel="noreferrer">
                  what you need to do at EOFY
                </a>{' '}
                and where to get more help.
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === REPORTING_SUBCATEGORY.EXCEPTIONS_DASHBOARD) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                <a href={config.REPORTING_EXCEPTIONS_DASHBOARD_HELP_LINK} target="_blank" rel="noreferrer">
                  Use the exceptions dashboard
                </a>{' '}
                to check that your books are in order.
              </Text>
              <Text marginBottom="clear">
                Need help finding and fixing issues?{' '}
                <a href={config.ACCESS_OR_SETUP_INVITE_ADVISOR_BROWSER_HELP_LINK} target="_blank" rel="noreferrer">
                  Invite your advisor into your file
                </a>
                . They can access your file directly and help resolve problems.
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === REPORTING_SUBCATEGORY.FOREIGN_CURRENCY) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Multi-currency is only available on the desktop for AccountRight Premier subscriptions.{' '}
                <a href={config.MANAGE_MY_PRODUCTS_HELP_LINK} target="_blank" rel="noreferrer">
                  Click here
                </a>{' '}
                to view or upgrade your subscription.
              </Text>
              <Text
                as="a"
                href={config.REPORTING_FOREIGN_CURRENCY_HELP_LINK}
                marginBottom="clear"
                target="_blank"
                rel="noreferrer"
              >
                More about setting up and using foreign currencies
              </Text>
              .
            </Box>
          </Alert>
        );
      }
      if (subCategory === REPORTING_SUBCATEGORY.IMPORT_EXPORT_DATA) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Exporting data for your accountant? Give them direct access to your data by{' '}
                <a href={config.ACCESS_OR_SETUP_INVITE_ADVISOR_BROWSER_HELP_LINK} target="_blank" rel="noreferrer">
                  inviting them to your business
                </a>{' '}
                instead.
              </Text>
              <Text marginBottom="clear">
                Use import templates to make sure your import goes smoothly.{' '}
                <a href={config.ACCESS_OR_SETUP_DATA_HELP_LINK} target="_blank" rel="noreferrer">
                  More about importing and exporting
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === REPORTING_SUBCATEGORY.INVENTORY_REPORT) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Set up your{' '}
                <a href={config.REPORTING_INVENTORY_RESTOCK_REPORT_HELP_LINK} target="_blank" rel="noreferrer">
                  restocking information
                </a>{' '}
                and use the <b>Reorder report</b> to see what items you need to reorder.
              </Text>
              <Text marginBottom="clear">
                <a href={config.REPORTING_INVENTORY_REPORT_HELP_LINK} target="_blank" rel="noreferrer">
                  More info about managing your inventory with inventory reports
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === REPORTING_SUBCATEGORY.ERROR_MESSAGE) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="clear">
                If you're having trouble running reports on the desktop, try the improved reporting experience in the
                browser.{' '}
                <a href={config.REPORTING_ERROR_MESSAGE_HELP_LINK} target="_blank" rel="noreferrer">
                  More about reports
                </a>
                .
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === REPORTING_SUBCATEGORY.JOB_REPORT) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Use jobs reports like <b>Jobs profit & loss</b> for insights into how projects, departments, divisions,
                or locations are performing.{' '}
              </Text>
              <Text
                as="a"
                href={config.REPORTING_JOB_REPORT_HELP_LINK}
                marginBottom="clear"
                target="_blank"
                rel="noreferrer"
              >
                More info about using, customising and exporting jobs reports
              </Text>
              .
            </Box>
          </Alert>
        );
      }
      if (subCategory === REPORTING_SUBCATEGORY.OIP) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Use online invoice payments reports to help reconcile the payments you've received and fees you've paid.
              </Text>
              <Text
                as="a"
                href={config.REPORTING_OIP_REPORT_HELP_LINK}
                marginBottom="clear"
                target="_blank"
                rel="noreferrer"
              >
                More info about running and exporting online invoice payments reports
              </Text>
              .
            </Box>
          </Alert>
        );
      }
      if (subCategory === REPORTING_SUBCATEGORY.PAYROLL_REPORT) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                <a href={config.REPORTING_PAYROLL_STP_REPORT_HELP_LINK} target="_blank" rel="noreferrer">
                  View STP reports
                </a>{' '}
                to see what you've sent to the ATO and{' '}
                <a href={config.REPORTING_PAYROLL_FIX_REPORT_HELP_LINK} target="_blank" rel="noreferrer">
                  fix any rejections
                </a>{' '}
                (Australia only).
              </Text>
              <Text marginBottom="clear">
                <a href={config.REPORTING_PAYROLL_REPORT_HELP_LINK} target="_blank" rel="noreferrer">
                  Run payroll reports
                </a>{' '}
                to keep track of which employees you've paid and how much you've paid them. Check leave balances and see
                what they've used.
              </Text>
            </Box>
          </Alert>
        );
      }
      if (subCategory === REPORTING_SUBCATEGORY.SALE_REPORT) {
        return (
          <Alert tone="info">
            <Box>
              <Text marginBottom="xs">
                Use the <b>Unpaid invoices</b> report in your browser to see which customers owe you money.
              </Text>
              <Text marginBottom="clear">
                More info about{' '}
                <a href={config.REPORTING_SALE_REPORT_HELP_LINK} target="_blank" rel="noreferrer">
                  using sales reports
                </a>{' '}
                to get a better picture of your customers and their invoices.
              </Text>
            </Box>
          </Alert>
        );
      }
    }
  };

  const validateForm = (name: string, value?: string) => {
    const rule = rules[name];
    if ((rule.requiredLabel && !value) || (rule.length && value.length !== 0 && value.length !== rule.length)) {
      setErrors((prevState) => ({ ...prevState, [name]: { isValid: false, value: rule.errorMessage } }));
    } else {
      setErrors((prevState) => ({ ...prevState, [name]: { isValid: true, value: '' } }));
    }
  };

  const setObjectState = (setState: React.Dispatch<React.SetStateAction<any>>, data: any) => {
    setState((preState) => ({
      ...preState,
      ...data,
    }));
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    validateForm(name, value);
    setObjectState(setSupportFormData, { [name]: value });
    setIsSendMessageDisabled(false);
  };

  const handleOnBlur = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    validateForm(name, value);
  };

  const handleOnCategoryChange = (e) => {
    setSupportFormData((prevState) => ({
      ...prevState,
      subCategory: '',
    }));

    trackSelectedOption(e.target.id, e.target.value);

    handleOnChange(e);
  };

  const handleOnSubCategoryChange = (e) => {
    trackSelectedOption(e.target.id, supportFormData.category, e.target.value);

    handleOnChange(e);
  };

  const handleOnSerialNumberChange = (e) => {
    e.target.value = e.target.value.replace(/[^\d]/g, '');
    handleOnChange(e);
  };

  const handleOnCancellationReasonChange = (e) => {
    const value = e.target.value;
    const description = CANCELLATION_REASON_MAPPING_ENHANCE_DESC.includes(value) ? '' : value;

    if (value === 'Other') {
      setObjectState(setRules, {
        ...rules,
        description: SUPPORT_FORM_PROPERTIES.description,
      });
    } else {
      setObjectState(setRules, {
        ...rules,
        description: {
          ...rules.description,
          requiredLabel: '',
          errorMessage: '',
        },
      });
    }

    setSupportFormData((prevState) => ({
      ...prevState,
      description,
    }));

    trackSelectedOption(e.target.id, supportFormData.category, supportFormData.subCategory, e.target.value);

    handleOnChange(e);
  };

  const handleOnSave = () => {
    forIn(supportFormData, (value, key) => {
      setObjectState(setSupportFormData, { [key]: value.trim() });
      validateForm(key, value.trim());
    });
    saveData();
  };

  const useSyncCallback = (callback) => {
    const [proxyState, setProxyState] = useState({ current: false });
    const [params, setParams] = useState([]);

    const Func = useCallback(
      (...args) => {
        setParams(args);
        setProxyState({ current: true });
      },
      [proxyState],
    );

    useEffect(() => {
      if (proxyState.current === true) setProxyState({ current: false });
    }, [proxyState]);

    useEffect(() => {
      proxyState.current && callback(...params);
    });

    return Func;
  };

  const compatibleSupportTicket = useCallback((data: SupportTicketInfo) => {
    const supportTicket = { ...data };

    if (data.cancellationReason && isEmpty(data.description)) {
      supportTicket.description = data.cancellationReason;
    }

    return supportTicket;
  }, []);

  const saveData = useSyncCallback(() => {
    const isValid = values(errors).filter((item) => !item.isValid).length === 0;
    if (isValid) {
      onSave(compatibleSupportTicket(supportFormData));
    }
  });

  const renderLabel = () => {
    if (supportFormData.category === SUPPORT_CATEGORY.BANK_FEEDS) {
      if (supportFormData.subCategory === BANK_FEEDS_SUBCATEGORY.BLOCK_MISSING_BANK_FEEDS) {
        return (
          <>
            Tried the things above, or there’s a transaction in your bank statement, but not in MYOB? Provide these
            details and we’ll look into it:
            <br />
            <br />
            <ul>
              <li>
                Serial number and (if using AccountRight) company file ID of your file –{' '}
                <a href={config.SERIAL_NUMBER_HELP_LINK} target="_blank" rel="noreferrer">
                  need help finding these?
                </a>
              </li>
              <li>Bank account number or last 4 digits of your credit card</li>
              <li>Date range of the missing feeds or the date of the last feed received</li>
            </ul>
            <br />
            We also need a bank statement showing the missing transactions. You can send this when you receive a case
            confirmation email.
          </>
        );
      }

      if (supportFormData.subCategory === BANK_FEEDS_SUBCATEGORY.DUPLICATE_FEED) {
        return (
          <>
            Deleted a bank statement, and still getting duplicated transactions? Provide these details and we’ll look
            into it:
            <br />
            <br />
            <ul>
              <li>
                Serial number and (if using AccountRight) company file ID of your file –{' '}
                <a href={config.SERIAL_NUMBER_HELP_LINK} target="_blank" rel="noreferrer">
                  need help finding these?
                </a>
              </li>
              <li>Bank account number or last 4 digits of your credit card </li>
              <li>Date range of the missing feeds or the date of the last feed received </li>
            </ul>
            <br />
            We also need a bank statement showing the missing transactions. You can send this when you receive a case
            confirmation email.
          </>
        );
      }

      if (supportFormData.subCategory === BANK_FEEDS_SUBCATEGORY.MOVING_BANK_FEEDS) {
        return (
          <>
            To move your feed to a different subscription, provide these details:
            <br />
            <br />
            <ul>
              <li>
                Serial number and (if using AccountRight) company file ID of the file you’re moving the feed from{' '}
                <b>and</b> of the file you’re moving it to –{' '}
                <a href={config.SERIAL_NUMBER_HELP_LINK} target="_blank" rel="noreferrer">
                  need help finding these?
                </a>
              </li>
              <li>Date for the feed to start in the new file</li>
              <li>Bank account number or last 4 digits of your credit card</li>
            </ul>
          </>
        );
      }
    }

    return 'Tell us more';
  };

  return (
    <div className={className}>
      <Input label="Name" name="contactName" value={fullName} disabled />
      <Input
        label="Serial number"
        name="serialNumber"
        value={supportFormData.serialNumber}
        maxLength={rules.serialNumber.length}
        errorMessage={errors.serialNumber.value}
        onChange={handleOnSerialNumberChange}
        onBlur={handleOnBlur}
      />
      <div className="hint-serial-number">
        <span>
          Your serial number lets us help you faster. Find it by clicking your business name in MYOB Business.
        </span>
      </div>

      <Input
        label="Subject"
        name="subject"
        requiredLabel={rules.subject.requiredLabel}
        placeholder="Example: monthly pay run"
        value={supportFormData.subject}
        maxLength={rules.subject.maxLength}
        errorMessage={errors.subject.value}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        data-pii="false"
      />

      <Select
        id="support-form-category"
        label="What can we help you with?"
        name="category"
        requiredLabel={rules.category.requiredLabel}
        value={supportFormData.category}
        errorMessage={errors.category.value}
        onChange={handleOnCategoryChange}
        onBlur={handleOnBlur}
        data-pii="false"
      >
        {allCategoryOptions}
      </Select>
      {featureFlags.isRestrictingNotificationDisplay() && renderNoActiveSubscriptionAlert()}
      <Select
        id="support-form-subcategory"
        label="What's the problem?"
        name="subCategory"
        requiredLabel={rules.subCategory.requiredLabel}
        value={supportFormData.subCategory}
        errorMessage={errors.subCategory.value}
        onChange={handleOnSubCategoryChange}
        onBlur={handleOnBlur}
        data-pii="false"
      >
        {subCategoryOptions(supportFormData.category || '')}
      </Select>

      {render2FAHelpArticle()}
      {renderAPIDeveloperHelpArticle()}
      {renderAccessOrSetupHelpArticle()}
      {renderAccountManagementHelpArticle()}
      {renderBankfeedsHelpArticle()}
      {renderBankingHelpArticle()}
      {renderBillingHelpArticle()}
      {renderFinancialServicesHelpArticle()}
      {renderSaleAndPurchaseHelpArticle()}
      {renderReportingHelpArticle()}
      {renderPayrollHelpArticle()}
      {renderProductsServicesAndInventoryHelpArticle()}

      {supportFormData.subCategory === 'Cancel my subscription' ? (
        <Select
          id="support-form-cancellation-reason"
          label="What’s your reason for cancelling?"
          name="cancellationReason"
          requiredLabel={rules.cancellationReason.requiredLabel}
          value={supportFormData.cancellationReason}
          errorMessage={errors.cancellationReason.value}
          onChange={handleOnCancellationReasonChange}
          onBlur={handleOnBlur}
          data-pii="false"
        >
          {cancellationReasonOptions()}
        </Select>
      ) : (
        <>
          <div className="text-box">
            <TextArea
              label={renderLabel()}
              name="description"
              rows={4}
              requiredLabel={rules.description.requiredLabel}
              value={supportFormData.description}
              maxLength={rules.description.maxLength}
              errorMessage={errors.description.value}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              data-pii="false"
            />
            <span className="word-counter" style={{ bottom: errors.description.value ? '5px' : '0px' }}>
              {descriptionLength}/{rules.description.maxLength}
            </span>
          </div>
          <div className="security-reminder">
            Don&apos;t include your password or payment details. We&apos;ll never ask for this information in a message
            or email.
          </div>
        </>
      )}
      {CANCELLATION_REASON_MAPPING_ENHANCE_DESC.includes(supportFormData.cancellationReason) && (
        <>
          <div className="text-box" data-testId="cancellation-reason-enhancement-desc">
            <TextArea
              label={supportFormData.cancellationReason === 'Other' ? 'Please give a reason' : 'Add a comment'}
              name="description"
              rows={4}
              requiredLabel={rules.description.requiredLabel}
              value={supportFormData.description}
              maxLength={rules.description.maxLength}
              errorMessage={errors.description.value}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              data-pii="false"
            />
            <span className="word-counter">
              {descriptionLength}/{rules.description.maxLength}
            </span>
          </div>
          <div className="security-reminder">
            Don&apos;t include your password or payment details. We&apos;ll never ask for this information in a message
            or email.
          </div>
        </>
      )}

      <>
        <span className="view-private-policy">
          View our{' '}
          <a href={`https://www.myob.com/${region.toLowerCase()}/privacy-policy`} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>{' '}
          to see how we look after your information.
        </span>
        <ButtonRow className="btn-row">
          <Button type="secondary" label="Cancel" onClick={onCancel}>
            Cancel
          </Button>
          <Button label="Send message" disabled={isSendMessageDisabled} onClick={handleOnSave}>
            Send message
          </Button>
        </ButtonRow>
      </>
    </div>
  );
};
