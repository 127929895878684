import { SaveBookingArgs } from '../type';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { saveBookings } from 'helpers/api';
import { ResponseError } from 'helpers/request';

export type BookingState = {
  save: {
    isLoading: boolean;
    isError: boolean;
    error: ResponseError;
  };
};

export const initialState: BookingState = {
  save: {
    isLoading: false,
    isError: false,
    error: {
      status: null,
      message: null,
    },
  },
};

export const addBooking = createAsyncThunk<Response, SaveBookingArgs, { rejectValue: ResponseError }>(
  'account/premium-support-booking',
  async ({ bookingData, region }, thunkApi) => {
    try {
      await saveBookings(bookingData, region);
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);

const slice = createSlice({
  name: 'premium-support-booking',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(addBooking.pending, (state) => {
      state.save.isLoading = true;
      state.save.isError = false;
      state.save.error = { status: null, message: null };
    });
    builder.addCase(addBooking.fulfilled, (state) => {
      state.save.isLoading = false;
      state.save.isError = false;
      state.save.error = { status: null, message: null };
    });
    builder.addCase(addBooking.rejected, (state, action) => {
      state.save.isLoading = false;
      state.save.isError = true;
      state.save.error = action.payload;
    });
  },
});
export const bookingReducer = slice.reducer;
