import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getIsNoActiveSubscription } from 'helpers/api';
import { ResponseError } from 'helpers/request';

export type NoActiveSubscriptionState = {
  fetch: {
    isLoading: boolean;
    isError: boolean;
    data: boolean;
    error: ResponseError;
  };
};

export const initialState: NoActiveSubscriptionState = {
  fetch: {
    isLoading: false,
    isError: false,
    data: null,
    error: {
      status: null,
      message: null,
    },
  },
};

export const getIsNoActiveSubscriptionAsync = createAsyncThunk<boolean, void, { rejectValue: ResponseError }>(
  'getIsNoActiveSubscription',
  async (_, thunkApi) => {
    try {
      return await getIsNoActiveSubscription();
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);

const slice = createSlice({
  name: 'unpaid-bill',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getIsNoActiveSubscriptionAsync.pending, (state) => {
      state.fetch.isLoading = true;
      state.fetch.isError = false;
      state.fetch.data = null;
      state.fetch.error = { status: null, message: null };
    });
    builder.addCase(getIsNoActiveSubscriptionAsync.fulfilled, (state, action) => {
      state.fetch.isLoading = false;
      state.fetch.isError = false;
      state.fetch.data = action.payload;
      state.fetch.error = { status: null, message: null };
    });
    builder.addCase(getIsNoActiveSubscriptionAsync.rejected, (state, action) => {
      state.fetch.isLoading = false;
      state.fetch.isError = true;
      state.fetch.data = null;
      state.fetch.error = action.payload;
    });
  },
});
export const noActiveSubscriptionReducer = slice.reducer;
