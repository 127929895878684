import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import React from 'react';
import { Provider } from 'react-redux';
import store from './stores';
import Routes from './router';
import { BrowserRouter } from 'react-router-dom';

export const Root: React.FC = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <CacheProvider
          value={createCache({
            key: 'my-account-digital-case',
          })}
        >
          <Routes />
        </CacheProvider>
      </BrowserRouter>
    </Provider>
  );
};
