import { Reducer } from 'redux';
import { ENotificationType, INotificationAction, NotificationState } from './type';

export const notificationInitialState: NotificationState = {
  type: ENotificationType.Clear,
  primaryMessage: '',
  secondaryMessage: '',
  dismissAfter: 0,
};

export const notificationReducer: Reducer<NotificationState, INotificationAction> = (
  state = notificationInitialState,
  action,
) => {
  const { type, primaryMessage, secondaryMessage, dismissAfter } = action;
  switch (type) {
    case ENotificationType.Clear:
      return notificationInitialState;
    case ENotificationType.Danger:
      return {
        type: ENotificationType.Danger,
        primaryMessage,
        secondaryMessage,
      };
    case ENotificationType.Success:
      return {
        type: ENotificationType.Success,
        primaryMessage,
        secondaryMessage,
        dismissAfter,
      };
    default:
      return state;
  }
};

export default notificationReducer;
