import {
  ACCOUNT_AND_SUBSCRIPTION_SUBCATEGORY,
  API_DEVELOPER_SUPPORT_SUBCATEGORY,
  BANK_FEEDS_SUBCATEGORY,
  BANKING_SUBCATEGORY,
  BILLING_SUBCATEGORY,
  COMPLAINTS_SUBCATEGORY,
  INVENTORY_SUBCATEGORY,
  INVOICING_AND_BILLS_SUBCATEGORY,
  LOANS_AND_FINANCE_SUBCATEGORY,
  PAYROLL_SUBCATEGORY,
  REPORTING_SUBCATEGORY,
  SUPPORT_2FA_SUBCATEGORY,
  SUPPORT_CATEGORY,
  USING_MY_SOFTWARE_SUBCATEGORY,
} from './constants';

interface SupportCategoryDisplay {
  mainCategory: string;
  apiValue: string;
  displayValue: string;
}
export const SUPPORT_CATEGORY_DISPLAY: SupportCategoryDisplay[] = [
  {
    mainCategory: SUPPORT_CATEGORY.TWO_FA,
    apiValue: SUPPORT_CATEGORY.TWO_FA,
    displayValue: 'Two-factor authentication (2FA)',
  },
  {
    mainCategory: SUPPORT_CATEGORY.ACCOUNT_AND_SUBSCRIPTION,
    apiValue: SUPPORT_CATEGORY.ACCOUNT_AND_SUBSCRIPTION,
    displayValue: 'MYOB subscription and account',
  },
  {
    mainCategory: SUPPORT_CATEGORY.API_DEVELOPER_SUPPORT,
    apiValue: SUPPORT_CATEGORY.API_DEVELOPER_SUPPORT,
    displayValue: 'API developer',
  },
  {
    mainCategory: SUPPORT_CATEGORY.BANKING,
    apiValue: SUPPORT_CATEGORY.BANKING,
    displayValue: 'Banking and transactions',
  },
  { mainCategory: SUPPORT_CATEGORY.PAYROLL, apiValue: SUPPORT_CATEGORY.PAYROLL, displayValue: 'Payroll' },
  { mainCategory: SUPPORT_CATEGORY.BANK_FEEDS, apiValue: SUPPORT_CATEGORY.BANK_FEEDS, displayValue: 'Bank feeds' },
  {
    mainCategory: SUPPORT_CATEGORY.USING_MY_SOFTWARE,
    apiValue: SUPPORT_CATEGORY.USING_MY_SOFTWARE,
    displayValue: 'User access, setup and data management',
  },
  {
    mainCategory: SUPPORT_CATEGORY.LOANS_AND_FINANCE,
    apiValue: SUPPORT_CATEGORY.LOANS_AND_FINANCE,
    displayValue: 'Financial services',
  },
  { mainCategory: SUPPORT_CATEGORY.REPORTING, apiValue: SUPPORT_CATEGORY.REPORTING, displayValue: 'Reporting' },
  {
    mainCategory: SUPPORT_CATEGORY.INVOICING_AND_BILLS,
    apiValue: SUPPORT_CATEGORY.INVOICING_AND_BILLS,
    displayValue: 'Sales and purchases',
  },
  {
    mainCategory: SUPPORT_CATEGORY.INVENTORY,
    apiValue: SUPPORT_CATEGORY.INVENTORY,
    displayValue: 'Products, services and inventory',
  },
  { mainCategory: SUPPORT_CATEGORY.COMPLAINTS, apiValue: SUPPORT_CATEGORY.COMPLAINTS, displayValue: 'Complaints' },
  {
    mainCategory: SUPPORT_CATEGORY.BILLING,
    apiValue: SUPPORT_CATEGORY.BILLING,
    displayValue: 'My MYOB bill and payments',
  },
  {
    mainCategory: SUPPORT_CATEGORY.TWO_FA,
    apiValue: SUPPORT_2FA_SUBCATEGORY.SETTING_OR_CHANGING,
    displayValue: 'Set up and change 2FA methods',
  },
  {
    mainCategory: SUPPORT_CATEGORY.TWO_FA,
    apiValue: SUPPORT_2FA_SUBCATEGORY.NOT_GET_OR_NOT_WORK,
    displayValue: `I didn't get a code or my code doesn't work`,
  },
  {
    mainCategory: SUPPORT_CATEGORY.TWO_FA,
    apiValue: SUPPORT_2FA_SUBCATEGORY.RESET,
    displayValue: 'I have a new phone and want to use it for 2FA',
  },
  {
    mainCategory: SUPPORT_CATEGORY.API_DEVELOPER_SUPPORT,
    apiValue: API_DEVELOPER_SUPPORT_SUBCATEGORY.BUSINESS_API_DEVELOPER_APP,
    displayValue: 'MYOB Business API developer application',
  },
  {
    mainCategory: SUPPORT_CATEGORY.API_DEVELOPER_SUPPORT,
    apiValue: API_DEVELOPER_SUPPORT_SUBCATEGORY.EXO_API_SUPPORT,
    displayValue: 'MYOB EXO API developer support',
  },
  {
    mainCategory: SUPPORT_CATEGORY.API_DEVELOPER_SUPPORT,
    apiValue: API_DEVELOPER_SUPPORT_SUBCATEGORY.EXO_API_DEVELOPER_APP,
    displayValue: 'MYOB EXO API developer application',
  },
  {
    mainCategory: SUPPORT_CATEGORY.API_DEVELOPER_SUPPORT,
    apiValue: API_DEVELOPER_SUPPORT_SUBCATEGORY.BUSINESS_API_SUPPORT,
    displayValue: 'MYOB Business API support',
  },
  {
    mainCategory: SUPPORT_CATEGORY.USING_MY_SOFTWARE,
    apiValue: USING_MY_SOFTWARE_SUBCATEGORY.CAN_NOT_ACCESS,
    displayValue: `I can't access my AccountRight file`,
  },
  {
    mainCategory: SUPPORT_CATEGORY.USING_MY_SOFTWARE,
    apiValue: USING_MY_SOFTWARE_SUBCATEGORY.INVITE_USER,
    displayValue: 'Invite a user',
  },
  {
    mainCategory: SUPPORT_CATEGORY.USING_MY_SOFTWARE,
    apiValue: USING_MY_SOFTWARE_SUBCATEGORY.ACTIVATE_FILE,
    displayValue: 'Activate or confirm an AccountRight file',
  },
  {
    mainCategory: SUPPORT_CATEGORY.USING_MY_SOFTWARE,
    apiValue: USING_MY_SOFTWARE_SUBCATEGORY.INVOICE_TEMPLATES,
    displayValue: 'Invoice templates',
  },
  {
    mainCategory: SUPPORT_CATEGORY.USING_MY_SOFTWARE,
    apiValue: USING_MY_SOFTWARE_SUBCATEGORY.LINKED_ACCOUNTS,
    displayValue: 'Linked accounts',
  },
  {
    mainCategory: SUPPORT_CATEGORY.USING_MY_SOFTWARE,
    apiValue: USING_MY_SOFTWARE_SUBCATEGORY.INVITE_ADVISOR,
    displayValue: 'Invite an advisor',
  },
  {
    mainCategory: SUPPORT_CATEGORY.USING_MY_SOFTWARE,
    apiValue: USING_MY_SOFTWARE_SUBCATEGORY.LOCAL_INSTALL,
    displayValue: 'Install AccountRight',
  },
  {
    mainCategory: SUPPORT_CATEGORY.USING_MY_SOFTWARE,
    apiValue: USING_MY_SOFTWARE_SUBCATEGORY.ARL_BACKUP_REQUEST,
    displayValue: 'Restore an AccountRight online backup',
  },
  {
    mainCategory: SUPPORT_CATEGORY.USING_MY_SOFTWARE,
    apiValue: USING_MY_SOFTWARE_SUBCATEGORY.DATA,
    displayValue: 'Import or export data',
  },
  {
    mainCategory: SUPPORT_CATEGORY.ACCOUNT_AND_SUBSCRIPTION,
    apiValue: ACCOUNT_AND_SUBSCRIPTION_SUBCATEGORY.CANCEL,
    displayValue: 'Cancel my subscription',
  },
  {
    mainCategory: SUPPORT_CATEGORY.ACCOUNT_AND_SUBSCRIPTION,
    apiValue: ACCOUNT_AND_SUBSCRIPTION_SUBCATEGORY.CHANGE,
    displayValue: 'Change my subscription',
  },
  {
    mainCategory: SUPPORT_CATEGORY.ACCOUNT_AND_SUBSCRIPTION,
    apiValue: ACCOUNT_AND_SUBSCRIPTION_SUBCATEGORY.Question,
    displayValue: `I've got a question about my subscription`,
  },
  {
    mainCategory: SUPPORT_CATEGORY.ACCOUNT_AND_SUBSCRIPTION,
    apiValue: ACCOUNT_AND_SUBSCRIPTION_SUBCATEGORY.CHANGE_ACCOUNT_OR_CONTACT_DETAIL,
    displayValue: 'Change account or contact details',
  },
  {
    mainCategory: SUPPORT_CATEGORY.ACCOUNT_AND_SUBSCRIPTION,
    apiValue: ACCOUNT_AND_SUBSCRIPTION_SUBCATEGORY.ADD_OR_REMOVE_USER,
    displayValue: 'Add or remove users',
  },
  {
    mainCategory: SUPPORT_CATEGORY.ACCOUNT_AND_SUBSCRIPTION,
    apiValue: ACCOUNT_AND_SUBSCRIPTION_SUBCATEGORY.CLOSE_OR_SELLING,
    displayValue: `I'm closing or selling my business`,
  },
  {
    mainCategory: SUPPORT_CATEGORY.BANK_FEEDS,
    apiValue: BANK_FEEDS_SUBCATEGORY.BLOCK_MISSING_BANK_FEEDS,
    displayValue: 'Transactions missing or bank feed stopped',
  },
  {
    mainCategory: SUPPORT_CATEGORY.BANK_FEEDS,
    apiValue: BANK_FEEDS_SUBCATEGORY.MOVING_BANK_FEEDS,
    displayValue: 'Move a bank feed',
  },
  {
    mainCategory: SUPPORT_CATEGORY.BANK_FEEDS,
    apiValue: BANK_FEEDS_SUBCATEGORY.DUPLICATE_FEED,
    displayValue: 'Duplicated feeds or transactions',
  },
  {
    mainCategory: SUPPORT_CATEGORY.BANK_FEEDS,
    apiValue: BANK_FEEDS_SUBCATEGORY.APPLICATION_ENQUIRY,
    displayValue: 'Application enquiry or check status',
  },
  {
    mainCategory: SUPPORT_CATEGORY.BANKING,
    apiValue: BANKING_SUBCATEGORY.ELECTRONIC_PAYMENTS,
    displayValue: 'Electronic payments',
  },
  {
    mainCategory: SUPPORT_CATEGORY.BANKING,
    apiValue: BANKING_SUBCATEGORY.CREATE_GENERAL_JOURNAL,
    displayValue: 'General journals',
  },
  {
    mainCategory: SUPPORT_CATEGORY.BANKING,
    apiValue: BANKING_SUBCATEGORY.BUSINESS_SETTINGS,
    displayValue: 'Business settings',
  },
  {
    mainCategory: SUPPORT_CATEGORY.BANKING,
    apiValue: BANKING_SUBCATEGORY.RECURRING_TRANSACTION,
    displayValue: 'Recurring transactions',
  },
  {
    mainCategory: SUPPORT_CATEGORY.BANKING,
    apiValue: BANKING_SUBCATEGORY.RECORD_INCOME_OUTCOME,
    displayValue: 'Record income or expense',
  },
  {
    mainCategory: SUPPORT_CATEGORY.BANKING,
    apiValue: BANKING_SUBCATEGORY.MISSING_TRANSACTION,
    displayValue: 'Missing transactions',
  },
  {
    mainCategory: SUPPORT_CATEGORY.BANKING,
    apiValue: BANKING_SUBCATEGORY.CATEGORISING_TRANSACTION,
    displayValue: 'Categorising transactions',
  },
  { mainCategory: SUPPORT_CATEGORY.BANKING, apiValue: BANKING_SUBCATEGORY.BANK_RULES, displayValue: 'Bank rules' },
  {
    mainCategory: SUPPORT_CATEGORY.BANKING,
    apiValue: BANKING_SUBCATEGORY.GETTING_ERROR_MESSAGE,
    displayValue: `I'm getting an error`,
  },
  {
    mainCategory: SUPPORT_CATEGORY.BANKING,
    apiValue: BANKING_SUBCATEGORY.RECONCILING_MY_ACCOUNT,
    displayValue: 'Reconcile a bank account',
  },
  {
    mainCategory: SUPPORT_CATEGORY.BILLING,
    apiValue: BILLING_SUBCATEGORY.WRONG_BILL,
    displayValue: 'My bill is wrong',
  },
  {
    mainCategory: SUPPORT_CATEGORY.BILLING,
    apiValue: BILLING_SUBCATEGORY.SUBSCRIPTION_ISSUE,
    displayValue: 'Subscription is suspended, cancelled or read-only',
  },
  {
    mainCategory: SUPPORT_CATEGORY.BILLING,
    apiValue: BILLING_SUBCATEGORY.MYOB_BILL,
    displayValue: 'Understanding my bill',
  },
  {
    mainCategory: SUPPORT_CATEGORY.BILLING,
    apiValue: BILLING_SUBCATEGORY.MYOB_PAYMENT_FAILED,
    displayValue: 'Failed payment',
  },
  {
    mainCategory: SUPPORT_CATEGORY.BILLING,
    apiValue: BILLING_SUBCATEGORY.FINANCIAL_HARDSHIP,
    displayValue: 'Financial hardship',
  },
  {
    mainCategory: SUPPORT_CATEGORY.COMPLAINTS,
    apiValue: COMPLAINTS_SUBCATEGORY.NOT_HAPPY_WITH_MYOB,
    displayValue: `I'm unhappy with the service I received`,
  },
  {
    mainCategory: SUPPORT_CATEGORY.COMPLAINTS,
    apiValue: COMPLAINTS_SUBCATEGORY.NOT_HAPPY_WITH_SOFTWARE,
    displayValue: `I'm unhappy with my software`,
  },
  {
    mainCategory: SUPPORT_CATEGORY.COMPLAINTS,
    apiValue: COMPLAINTS_SUBCATEGORY.NOT_HAPPY_WITH_BILL,
    displayValue: `I'm unhappy with my bill`,
  },
  {
    mainCategory: SUPPORT_CATEGORY.LOANS_AND_FINANCE,
    apiValue: LOANS_AND_FINANCE_SUBCATEGORY.OIP,
    displayValue: 'Online Invoice Payments',
  },
  {
    mainCategory: SUPPORT_CATEGORY.LOANS_AND_FINANCE,
    apiValue: LOANS_AND_FINANCE_SUBCATEGORY.DIRECT_ELECTRONIC_PAYMENT,
    displayValue: 'Direct electronic payments',
  },
  {
    mainCategory: SUPPORT_CATEGORY.LOANS_AND_FINANCE,
    apiValue: LOANS_AND_FINANCE_SUBCATEGORY.BUT_VARIANT,
    displayValue: 'Business loans and invoice funding (Valiant and Butn)',
  },
  {
    mainCategory: SUPPORT_CATEGORY.LOANS_AND_FINANCE,
    apiValue: LOANS_AND_FINANCE_SUBCATEGORY.PAY_AGENT,
    displayValue: 'PayAgent',
  },
  {
    mainCategory: SUPPORT_CATEGORY.LOANS_AND_FINANCE,
    apiValue: LOANS_AND_FINANCE_SUBCATEGORY.M_POWER,
    displayValue: 'M-Powered Services',
  },
  {
    mainCategory: SUPPORT_CATEGORY.INVENTORY,
    apiValue: INVENTORY_SUBCATEGORY.ITEM_SETUP,
    displayValue: 'Set up items',
  },
  {
    mainCategory: SUPPORT_CATEGORY.INVENTORY,
    apiValue: INVENTORY_SUBCATEGORY.INVENTORY_ADJUSTMENT,
    displayValue: 'Adjust inventory',
  },
  {
    mainCategory: SUPPORT_CATEGORY.INVENTORY,
    apiValue: INVENTORY_SUBCATEGORY.REORDER_LOW_STOCK_ITEMS,
    displayValue: 'Reorder stock items',
  },
  {
    mainCategory: SUPPORT_CATEGORY.INVENTORY,
    apiValue: INVENTORY_SUBCATEGORY.ERROR_MESSAGE,
    displayValue: `I'm getting an error`,
  },
  {
    mainCategory: SUPPORT_CATEGORY.INVOICING_AND_BILLS,
    apiValue: INVOICING_AND_BILLS_SUBCATEGORY.QUOTE_SALE_ORDER_PAYMENTS_CUSTOMER,
    displayValue: 'Customer sales, payments and returns',
  },
  {
    mainCategory: SUPPORT_CATEGORY.INVOICING_AND_BILLS,
    apiValue: INVOICING_AND_BILLS_SUBCATEGORY.E_INVOICE,
    displayValue: 'eInvoicing',
  },
  {
    mainCategory: SUPPORT_CATEGORY.INVOICING_AND_BILLS,
    apiValue: INVOICING_AND_BILLS_SUBCATEGORY.PURCHASE_ORDER_BILL_PAYMENT_SUPPLIER,
    displayValue: 'Supplier purchases, payments and returns',
  },
  {
    mainCategory: SUPPORT_CATEGORY.INVOICING_AND_BILLS,
    apiValue: INVOICING_AND_BILLS_SUBCATEGORY.ERROR_MESSAGE,
    displayValue: `I'm getting an error`,
  },
  {
    mainCategory: SUPPORT_CATEGORY.INVOICING_AND_BILLS,
    apiValue: INVOICING_AND_BILLS_SUBCATEGORY.IN_TRAY,
    displayValue: 'Uploading documents and In Tray',
  },
  {
    mainCategory: SUPPORT_CATEGORY.INVOICING_AND_BILLS,
    apiValue: INVOICING_AND_BILLS_SUBCATEGORY.PAYMENT_TERMS_EMAIL,
    displayValue: 'Payment terms and sales settings',
  },
  {
    mainCategory: SUPPORT_CATEGORY.PAYROLL,
    apiValue: PAYROLL_SUBCATEGORY.PROCESSING,
    displayValue: 'Process or fix a pay',
  },
  {
    mainCategory: SUPPORT_CATEGORY.PAYROLL,
    apiValue: PAYROLL_SUBCATEGORY.REPORTING_AND_STP,
    displayValue: 'Single Touch Payroll (STP) and Payday filing',
  },
  {
    mainCategory: SUPPORT_CATEGORY.PAYROLL,
    apiValue: PAYROLL_SUBCATEGORY.SUPERANNUATION,
    displayValue: 'Superannuation and KiwiSaver',
  },
  {
    mainCategory: SUPPORT_CATEGORY.PAYROLL,
    apiValue: PAYROLL_SUBCATEGORY.TIMESHEETS,
    displayValue: 'Timesheets and MYOB Team',
  },
  { mainCategory: SUPPORT_CATEGORY.PAYROLL, apiValue: PAYROLL_SUBCATEGORY.PAYSLIPS, displayValue: 'Pay slips' },
  {
    mainCategory: SUPPORT_CATEGORY.PAYROLL,
    apiValue: PAYROLL_SUBCATEGORY.PAYITEMS,
    displayValue: 'Pay items and payroll categories',
  },
  {
    mainCategory: SUPPORT_CATEGORY.PAYROLL,
    apiValue: PAYROLL_SUBCATEGORY.CREATING_EMPLOYEES,
    displayValue: 'Add or change employee details',
  },
  {
    mainCategory: SUPPORT_CATEGORY.PAYROLL,
    apiValue: PAYROLL_SUBCATEGORY.CHANGE_OR_INCREASE_MY_SUPER_LIMIT_OR_AUTHORISER,
    displayValue: 'Change my Pay Super limit',
  },
  {
    mainCategory: SUPPORT_CATEGORY.PAYROLL,
    apiValue: PAYROLL_SUBCATEGORY.TERMINATION_PAYMENTS,
    displayValue: 'Final pays',
  },
  {
    mainCategory: SUPPORT_CATEGORY.PAYROLL,
    apiValue: PAYROLL_SUBCATEGORY.CHANGE_MY_PAY_SUPER_AUTHORISER,
    displayValue: 'Add or change a Pay Super authoriser',
  },
  {
    mainCategory: SUPPORT_CATEGORY.PAYROLL,
    apiValue: PAYROLL_SUBCATEGORY.ERROR_MESSAGE,
    displayValue: `I'm getting an error`,
  },
  {
    mainCategory: SUPPORT_CATEGORY.PAYROLL,
    apiValue: PAYROLL_SUBCATEGORY.ANNUAL_LEAVE_CALCULATIONS,
    displayValue: 'Leave and entitlements',
  },
  {
    mainCategory: SUPPORT_CATEGORY.PAYROLL,
    apiValue: PAYROLL_SUBCATEGORY.SETTING_UP_FLARE,
    displayValue: 'Employee self-onboarding and benefits (Flare)',
  },
  {
    mainCategory: SUPPORT_CATEGORY.REPORTING,
    apiValue: REPORTING_SUBCATEGORY.BAS_EGST,
    displayValue: 'BAS and GST returns',
  },
  {
    mainCategory: SUPPORT_CATEGORY.REPORTING,
    apiValue: REPORTING_SUBCATEGORY.BUSINESS_REPORT,
    displayValue: 'Business reports',
  },
  {
    mainCategory: SUPPORT_CATEGORY.REPORTING,
    apiValue: REPORTING_SUBCATEGORY.BANKING_REPORT,
    displayValue: 'Banking reports',
  },
  {
    mainCategory: SUPPORT_CATEGORY.REPORTING,
    apiValue: REPORTING_SUBCATEGORY.PAYROLL_REPORT,
    displayValue: 'Payroll reports',
  },
  {
    mainCategory: SUPPORT_CATEGORY.REPORTING,
    apiValue: REPORTING_SUBCATEGORY.SALE_REPORT,
    displayValue: 'Sales reports',
  },
  {
    mainCategory: SUPPORT_CATEGORY.REPORTING,
    apiValue: REPORTING_SUBCATEGORY.BILL_REPORT,
    displayValue: 'Purchases reports',
  },
  {
    mainCategory: SUPPORT_CATEGORY.REPORTING,
    apiValue: REPORTING_SUBCATEGORY.INVENTORY_REPORT,
    displayValue: 'Inventory reports',
  },
  {
    mainCategory: SUPPORT_CATEGORY.REPORTING,
    apiValue: REPORTING_SUBCATEGORY.JOB_REPORT,
    displayValue: 'Jobs reports',
  },
  {
    mainCategory: SUPPORT_CATEGORY.REPORTING,
    apiValue: REPORTING_SUBCATEGORY.OIP,
    displayValue: 'Online Invoice Payments reports',
  },
  {
    mainCategory: SUPPORT_CATEGORY.REPORTING,
    apiValue: REPORTING_SUBCATEGORY.EXCEPTIONS_DASHBOARD,
    displayValue: 'Exceptions dashboard and audit reporting',
  },
  {
    mainCategory: SUPPORT_CATEGORY.REPORTING,
    apiValue: REPORTING_SUBCATEGORY.CUSTOMER_REPORT,
    displayValue: 'Customer reports',
  },
  {
    mainCategory: SUPPORT_CATEGORY.REPORTING,
    apiValue: REPORTING_SUBCATEGORY.ERROR_MESSAGE,
    displayValue: `I'm getting an error`,
  },
  {
    mainCategory: SUPPORT_CATEGORY.REPORTING,
    apiValue: REPORTING_SUBCATEGORY.END_FINANCIAL_YEAR_TASKS,
    displayValue: 'End of financial year reporting',
  },
  {
    mainCategory: SUPPORT_CATEGORY.REPORTING,
    apiValue: REPORTING_SUBCATEGORY.IMPORT_EXPORT_DATA,
    displayValue: 'Importing or exporting',
  },
  {
    mainCategory: SUPPORT_CATEGORY.REPORTING,
    apiValue: REPORTING_SUBCATEGORY.FOREIGN_CURRENCY,
    displayValue: 'Foreign currency',
  },
];
